import { getRegisterRemoteContent } from '@services/singlePages/registerRemote';
import { registerRemoteAction } from './registerRemote-slice';

export const setRegisterRemoteContent = async (dispatch, SMToken) => {
  try {
    const response = await getRegisterRemoteContent(SMToken);
    dispatch(registerRemoteAction.setRegisterRemoteContent(response));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
