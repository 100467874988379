export const getArea = (units, area) => {
  // special case for filters
  if (area === 'Min' || area === 'Max') {
    return area;
  }
  // when no plot is selected
  // on save and exit
  // return null
  if (area === undefined || area === null) {
    return null;
  }
  // if area is a string, convert it to a number
  if (typeof area !== 'number') {
    area = parseFloat(area);
  }
  if (units === 'Metric System') {
    return `${area.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} m²`;
  }
  if (units === 'Imperial System') {
    return `${Math.round(area * 10.76391).toLocaleString('en-EN')} ft²`;
  }
};

export const getLayoutDetails = (modules, units) => {
  const finale = {
    layout: [],
    intimate: [],
    intimate_others: 0,
    social: [],
    social_others: 0,
    area: 0
  };

  modules?.forEach(module => {
    module.divisions?.forEach(division => {
      finale.area = finale.area + division.area;
      if (division.category === 'Area Social' && division.type !== 'Outros') finale.social.push(division);
      if (division.category === 'Area Social' && division.type === 'Outros')
        finale.social_others = finale.social_others + division.area;
      if (division.category === 'Area Intima' && division.type !== 'Outros') finale.intimate.push(division);
      if (division.category === 'Area Intima' && division.type === 'Outros')
        finale.intimate_others = finale.intimate_others + division.area;
    });
  });
  finale.area = getArea(units, finale.area);

  // This layout is needed for the accordion in Review step
  finale.layout.push({ title: 'Social Area' });
  finale.social.map(area => finale.layout.push({ label: area.label, value: getArea(units, area.area) }));
  if (finale.social_others > 0) finale.layout.push({ label: 'Others', value: getArea(units, finale.social_others) });
  finale.layout.push({ title: 'Intimate Area' });
  finale.intimate.map(area => finale.layout.push({ label: area.label, value: getArea(units, area.area) }));
  if (finale.intimate_others > 0)
    finale.layout.push({ label: 'Others', value: getArea(units, finale.intimate_others) });

  // For the Layout step, we parse the areas in the LayoutDetails component
  if (finale.social_others > 0) finale.social.push({ area: finale.social_others, label: 'Others' });
  if (finale.intimate_others > 0) finale.intimate.push({ area: finale.intimate_others, label: 'Others' });

  return finale;
};

export const getMaxTypology = (plotHouses, houses) => {
  const typologies = houses?.filter(house => plotHouses.includes(house.id))?.map(house => house.typology) || [];
  const orderedTypologies = typologies.sort((a, b) => b.localeCompare(a));
  return orderedTypologies?.length > 0 ? orderedTypologies[0] : '';
};

export const getSizeLabel = (x, y, units, labelNone) => {
  if (x === 0 && y === 0) {
    return labelNone;
  }
  return units === 'Metric System' ? `${x}m x ${y}m` : `${Math.round(x * 3.28084)}ft x ${Math.round(y * 3.28084)}ft`;
};
