// styles
import { SVG } from '@assets/svg';
import './ZoomControls.scss';

const ZoomControls = ({ zoom, setZoom }) => {
  return (
    <div className='zoom-controls'>
      <button
        className='zoom-controls-button-plus'
        onClick={() => {
          setZoom('zoomIn');
        }}
      >
        <SVG icon='semantic/plus' />
      </button>
      <span className='zoom-controls-divider' />
      <button
        className='zoom-controls-button-minus'
        onClick={() => {
          setZoom('zoomOut');
        }}
      >
        <SVG icon='semantic/minus' />
      </button>
    </div>
  );
};

export default ZoomControls;
