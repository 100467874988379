import React, { useEffect, useRef, useState, useCallback } from 'react';
// hooks
import useRedux from '@hooks/useRedux';
import useFilters from '@hooks/useFilters';
// components
import { Popper } from '@mui/base/Popper';
import Tooltip from '@components/ui/Tooltip/Tooltip';
// utils
import OpenSeadragon from 'openseadragon';
import * as d3 from 'd3';
import { MODAL_TYPE, PLOT_STATUS } from '@utils/enums';
import { useFormContext } from 'react-hook-form';
// styles
import './Map.scss'; // Import the CSS styles separately

const Map = ({ plots, macroplots, zoom, hoveredPlot, hoveredMacroplot, disabled }) => {
  const { getPlots, getMacroplots, getFilters, dispatchUpdateTracking, dispatchSetRecommendation, dispatchSetDialog } =
    useRedux();
  const { getPlotsFiltered, getPlotsByMacroplotId } = useFilters();
  const viewerRef = useRef(null);
  const tooltipRef = useRef(null);
  const viewerInstance = useRef(null); // Use ref to store the OpenSeadragon instance
  const [viewerInstanceSetup, setViewerInstanceSetup] = useState(false); // Flag to check if the viewer instance is set up
  const [plotsData] = useState(getPlots()); // Load plots data from Redux
  const [macroplotsData] = useState(getMacroplots()); // Load macroplots data from Redux
  const [anchorEl, setAnchorEl] = useState(null);
  const visible = Boolean(anchorEl);
  const popperId = visible ? 'simple-popper' : undefined;
  const [tooltipLabel, setTooltipLabel] = useState(null);
  const [tooltipStatus, setTooltipStatus] = useState(null);
  const [tooltipPremium, setTooltipPremium] = useState(false);
  const filters = getFilters();
  const { setValue } = useFormContext();
  const { watch } = useFormContext();
  const watcher = watch();

  // Utility for debouncing
  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  }

  // Resize window handler
  const handleResize = useCallback(
    debounce(() => {
      if (viewerInstance.current) {
        viewerInstance.current.viewport.goHome(true);
        viewerInstance.current.viewport.applyConstraints();
      }
    }, 100),
    []
  );

  // Get plots by macroplot hovered or selected
  const getPlotsByMacroplot = (selectedMacroplot, hoveredMacroplot) => {
    if (selectedMacroplot !== '' && hoveredMacroplot !== null) {
      return [...getPlotsByMacroplotId(selectedMacroplot), ...getPlotsByMacroplotId(hoveredMacroplot)];
    } else if (selectedMacroplot !== '') {
      return getPlotsByMacroplotId(selectedMacroplot);
    } else if (hoveredMacroplot !== null) {
      return getPlotsByMacroplotId(hoveredMacroplot);
    }
    return [];
  };

  // Get plot fill color based on status
  const getPlotFill = (data, selectedPlot, plotsFiltered, plotsByMacroplot) => {
    if (data && data.id === selectedPlot && plotsFiltered.find(plot => plot.id === data?.id)) {
      return data.isPremium ? 'rgba(244, 226, 208, 1)' : 'rgba(255, 255, 255, 0.8)';
    } else if (data && data.id !== selectedPlot && data.id === hoveredPlot) {
      return data.isPremium ? 'rgba(244, 226, 208, 1)' : 'rgba(255, 255, 255, 0.8)';
    } else if (
      data &&
      plotsByMacroplot.find(plot => plot.id === data?.id) &&
      plotsFiltered.find(plot => plot.id === data?.id)
    )
      return 'rgba(255, 255, 255, 0.4)';
    else if (
      data &&
      plotsFiltered.find(plot => plot.id === data?.id) &&
      !plotsByMacroplot.find(plot => plot.id === data?.id) &&
      (data.status === PLOT_STATUS.Available || data.status === PLOT_STATUS.InNegotiation)
    )
      return 'rgba(255, 255, 255, 0.15)';
    return 'rgba(25, 30, 22, 1)';
  };

  // Get plot stroke color based on status
  const getPlotStroke = (data, selectedPlot, hoveredPlot, plotsByMacroplot, plotsFiltered) => {
    if (data && data.id === selectedPlot && plotsFiltered.find(plot => plot.id === data?.id)) {
      return data.isPremium ? 'rgba(217, 136, 43, 1)' : 'rgba(216, 212, 204, 1)';
    } else if (data && data.id !== selectedPlot && data.id === hoveredPlot) {
      return data.isPremium ? 'rgba(217, 136, 43, 1)' : 'rgba(216, 212, 204, 1)';
    } else if (
      data &&
      plotsByMacroplot.find(plot => plot.id === data?.id) &&
      plotsFiltered.find(plot => plot.id === data?.id)
    )
      return 'rgba(216, 212, 204, 1)';
    else if (
      data &&
      plotsFiltered.find(plot => plot.id === data?.id) &&
      !plotsByMacroplot.find(plot => plot.id === data?.id) &&
      (data.status === PLOT_STATUS.Available || data.status === PLOT_STATUS.InNegotiation)
    )
      return 'rgba(132, 129, 123, 1)';
    return 'rgba(25, 30, 22, 1)';
  };

  // Update plot styles based on selected and hovered plots
  const updatePlotStyles = (plotsElement, plotsData, selectedPlot, hoveredPlot) => {
    const plotsSelection = d3.select(plotsElement);
    const plotsFiltered = getPlotsFiltered(filters, plotsData);
    // if a macroplot is hovered or selected, all available plots in that macroplot should be highlighted
    const plotsByMacroplot = getPlotsByMacroplot(watcher?.plot?.macroplot, hoveredMacroplot);

    plotsSelection
      .selectAll('path')
      .style('fill', function () {
        const id = d3.select(this).attr('id');
        const data = plotsData.find(plot => plot.id === id);
        return getPlotFill(data, selectedPlot, plotsFiltered, plotsByMacroplot);
      })
      .style('stroke', function () {
        const id = d3.select(this).attr('id');
        const data = plotsData.find(plot => plot.id === id);
        return getPlotStroke(data, selectedPlot, hoveredPlot, plotsByMacroplot, plotsFiltered);
      })
      .style('stroke-width', function () {
        const id = d3.select(this).attr('id');
        const data = plotsData.find(plot => plot.id === id);
        return data &&
          data.id !== selectedPlot &&
          plotsFiltered.find(plot => plot.id === data?.id) &&
          (data.status === PLOT_STATUS.Available || data.status === PLOT_STATUS.InNegotiation)
          ? 1
          : 0;
      })
      .style('z-index', -1)
      .style('cursor', function () {
        const id = d3.select(this).attr('id');
        const data = plotsData.find(plot => plot.id === id);
        return data &&
          plotsFiltered.find(plot => plot.id === data?.id) &&
          !disabled &&
          (data.status === PLOT_STATUS.Available || data.status === PLOT_STATUS.InNegotiation)
          ? 'pointer'
          : 'default';
      })
      .on('mouseover', function (event) {
        const id = d3.select(this).attr('id');
        const data = plotsData.filter(plot => plot.id === id)[0];
        if (data) {
          if (
            data.id !== selectedPlot &&
            !disabled &&
            plotsFiltered.find(plot => plot.id === data?.id) &&
            (data.status === PLOT_STATUS.Available || data.status === PLOT_STATUS.InNegotiation)
          ) {
            d3.select(this).style('fill', data.isPremium ? 'rgba(244, 226, 208, 1)' : 'rgba(255, 255, 255, 0.8)');
            d3.select(this).style('stroke', data.isPremium ? 'rgba(217, 136, 43, 1)' : 'rgba(216, 212, 204, 1)');
          }

          // Show tooltip if not disabled or if the plot is selected
          if (!disabled || selectedPlot === data?.id) {
            setTooltipPremium(data.isPremium);
            setTooltipLabel(data?.id);
            // setTooltipStatus(data.status);
            setTooltipStatus(
              plotsFiltered.find(plot => plot.id === data?.id) ? data.status : PLOT_STATUS.NotMatchFilters
            );
            setAnchorEl(event.target);
          }
        }
      })
      .on('mouseout', function () {
        const id = d3.select(this).attr('id');
        const data = plotsData.filter(plot => plot.id === id)[0];
        if (data) {
          if (data.id === selectedPlot) {
            d3.select(this).style('fill', data.isPremium ? 'rgba(244, 226, 208, 1)' : 'rgba(255, 255, 255, 0.8)');
            d3.select(this).style('stroke-width', 0);
          } else if (
            plotsByMacroplot.find(plot => plot.id === data?.id) &&
            plotsFiltered.find(plot => plot.id === data?.id)
          ) {
            d3.select(this).style('fill', 'rgba(255, 255, 255, 0.4)');
            d3.select(this).style('stroke', 'rgba(216, 212, 204, 1)');
          } else if (
            data.id !== selectedPlot &&
            plotsFiltered.find(plot => plot.id === data?.id) &&
            (data.status === PLOT_STATUS.Available || data.status === PLOT_STATUS.InNegotiation)
          ) {
            d3.select(this).style('fill', 'rgba(255, 255, 255, 0.15)');
            d3.select(this).style('stroke', 'rgba(132, 129, 123, 1)');
          }

          // Hide tooltip
          setTooltipLabel(null);
          setTooltipStatus(null);
          setTooltipPremium(false);
          setAnchorEl(null);
        }
      })
      .on('click', function (event) {
        const id = d3.select(this).attr('id');
        const data = plotsData.filter(plot => plot.id === id)[0];
        if (data && plotsFiltered.find(plot => plot.id === data?.id)) {
          if (data.status === PLOT_STATUS.Available || data.status === PLOT_STATUS.InNegotiation) {
            const match = id.match(/(\d+)\./);
            const macroPlot = `M${parseInt(match?.[1], 10)}`;
            setValue('plot.macroplot', macroPlot);
            setValue('plot.plot', id);
            dispatchSetRecommendation(plotsData.find(plot => plot.id === id)?.recommendation);
            if (data.isPremium) {
              dispatchSetDialog({
                open: true,
                type: 'premium',
                id: MODAL_TYPE.PremiumModal,
                plot: id,
                allPlots: plotsData,
                getPlotsFiltered: getPlotsFiltered
              });
            }
          }
        }
      });
  };

  // Update macroplot styles
  const updateMacroplotStyles = (macroplotsElement, selectedMacroplot, hoveredMacroplot) => {
    const macroplotSelection = d3.select(macroplotsElement);
    macroplotSelection
      .selectAll('path')
      .style('z-index', -2)
      .style('fill', function () {
        const id = d3.select(this).attr('id');
        const data = macroplotsData.find(macroplot => macroplot.id === id);
        return data && (data.id === selectedMacroplot || data.id === hoveredMacroplot)
          ? 'rgba(101, 100, 98, 1)'
          : 'rgba(65, 65, 65, 1)';
      });
  };

  // Checks if a instance exists, if so, apply styles on hover plots and selected plot
  useEffect(() => {
    if (!viewerInstance.current && !viewerInstanceSetup) return;
    const macroplotsElement = document.querySelector(
      '#viewerContainer > div > div.openseadragon-canvas > div.overlay-svg-container > svg'
    );
    const plotsElement = document.querySelector(
      '#viewerContainer > div > div.openseadragon-canvas > div.overlay-svg-container > div.overlay-svg-container > svg'
    );
    updateMacroplotStyles(macroplotsElement, watcher?.plot?.macroplot, hoveredMacroplot);

    updatePlotStyles(plotsElement, plotsData, watcher?.plot?.plot, hoveredPlot);
  }, [viewerInstanceSetup, hoveredPlot, hoveredMacroplot, watcher?.plot?.plot, watcher?.plot?.macroplot, filters]);

  // Create the viewer instance and set macroplot and plot SVGs overlays
  useEffect(() => {
    const initializeViewer = () => {
      try {
        const plotsText = plots;
        const macroplotsText = macroplots;

        // Initialize OpenSeadragon viewer
        viewerInstance.current = OpenSeadragon({
          id: viewerRef.current.id,
          prefixUrl: 'openseadragon-bin-5.0.0/images/',
          tileSources: {
            type: 'image',
            url: '/img/baselayerWIP.png', // Replace with your image path
            // url: 'img/baselayer_big.dzi', // Replace with your image path
            buildPyramid: false,
            width: 960, // Set the actual image width (5578)
            height: 945 // Set the actual image height (4228)
          },
          // tileSources: '/img/baselayer_big.dzi', // Path to DZI file
          showNavigator: false,
          showNavigationControl: false,
          constrainDuringPan: true,
          homeFillsViewer: true, // Force the image to always fill the viewer container
          minZoomLevel: 1, // This will be set dynamically later
          maxZoomLevel: 10, // Maximum zoom level
          visibilityRatio: 1, // Ensure entire image is always visible
          // Disable zoom on single click
          zoomPerClick: 1,

          // Disable zoom on double-click
          dblClickZoom: false
        });

        // Ensure viewer instance is created
        // console.log('OpenSeadragon viewer instance:', viewerInstance.current);

        viewerInstance.current.addHandler('open', () => {
          handleResize();

          // Get image dimensions
          const tiledImage = viewerInstance.current.world.getItemAt(0);
          const imageSize = tiledImage.getContentSize();
          // console.log('Image dimensions:', imageSize.x, 'x', imageSize.y);

          // Create SVG overlay
          const svgNamespace = 'http://www.w3.org/2000/svg';
          const parser = new DOMParser();

          const macroplotsDoc = parser.parseFromString(macroplotsText, 'image/svg+xml');
          const macroplotsElement = macroplotsDoc.documentElement;

          macroplotsElement.setAttribute('width', imageSize.x);
          macroplotsElement.setAttribute('height', imageSize.y);

          const plotsDoc = parser.parseFromString(plotsText, 'image/svg+xml');
          const plotsElement = plotsDoc.documentElement;

          // Set SVG dimensions
          plotsElement.setAttribute('width', imageSize.x);
          plotsElement.setAttribute('height', imageSize.y);

          // Create container for SVG overlay
          const overlayPlotsDiv = document.createElement('div');
          overlayPlotsDiv.className = 'overlay-svg-container';
          overlayPlotsDiv.appendChild(plotsElement);

          const overlayMacroplotDiv = document.createElement('div');
          overlayMacroplotDiv.className = 'overlay-svg-container';
          overlayMacroplotDiv.appendChild(macroplotsElement);

          // Add overlay to viewer's canvas
          viewerInstance.current.canvas.appendChild(overlayMacroplotDiv).appendChild(overlayPlotsDiv);

          // style macroplots
          updateMacroplotStyles(macroplotsElement, watcher?.plot?.macroplot, hoveredMacroplot);
          // style plots
          updatePlotStyles(plotsElement, plotsData, watcher?.plot?.plot, hoveredPlot);

          // D3 selection for easier manipulation
          const plotsSelection = d3.select(plotsElement);

          if (!disabled) {
            viewerInstance.current.addHandler('canvas-click', e => {
              const plotRegex = /^L\d{2}\.\d{2}$/;
              if (e?.originalTarget?.id && plotRegex.test(e?.originalTarget?.id)) {
                dispatchUpdateTracking({
                  idToUpdate: 'plotSelectedOnImageContainer',
                  updatedObject: { wasSelectedOnImageContainer: true }
                });
                document
                  .querySelector(`#${e?.originalTarget?.id.replace('.', '\\.')}`)
                  .dispatchEvent(new MouseEvent('click'));
              }
            });
          }

          // Synchronize overlay with viewer's viewport
          viewerInstance.current.addHandler('animation', updateOverlay);
          viewerInstance.current.addHandler('resize', updateOverlay);
          updateOverlay();

          // Function to update the overlay's viewBox based on the current viewport
          function updateOverlay() {
            const bounds = viewerInstance.current.viewport.getBounds(true);
            const imageRectangle = viewerInstance.current.viewport.viewportToImageRectangle(bounds);
            plotsElement.setAttribute(
              'viewBox',
              `${imageRectangle.x} ${imageRectangle.y} ${imageRectangle.width} ${imageRectangle.height}`
            );
            macroplotsElement.setAttribute(
              'viewBox',
              `${imageRectangle.x} ${imageRectangle.y} ${imageRectangle.width} ${imageRectangle.height}`
            );
          }

          // Set minimum zoom level to 1
          // viewerInstance.current.viewport.setMinZoomLevel(1);
          viewerInstance.current.viewport.zoomTo(1); // Change '2' to your desired initial zoom level
        });

        viewerInstance.current.addHandler('open-failed', event => {
          console.error('OpenSeadragon failed to open the image:', event);
        });
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    };

    if (!viewerInstance.current) {
      initializeViewer();
      setViewerInstanceSetup(true);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [hoveredPlot, hoveredMacroplot, plots, macroplots, handleResize]);

  // Custom zoom control functions
  const zoomIn = () => {
    if (viewerInstance.current) {
      viewerInstance.current.viewport.zoomBy(1.2);
      viewerInstance.current.viewport.applyConstraints();
    }
  };

  const zoomOut = () => {
    if (viewerInstance.current) {
      viewerInstance.current.viewport.zoomBy(1 / 1.2);
      viewerInstance.current.viewport.applyConstraints();
    }
  };

  // ZoomControllers Component is on the ImageContainer Header
  useEffect(() => {
    if (viewerInstance.current && zoom) {
      if (zoom === 'zoomIn') zoomIn();
      if (zoom === 'zoomOut') zoomOut();
    }
  }, [zoom]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div id='viewerContainer' ref={viewerRef} style={{ width: '100%', height: '100%' }} />
      <Popper id={popperId} open={visible} anchorEl={anchorEl} placement='top-end' style={{ zIndex: 9999 }}>
        <div className='tooltip' ref={tooltipRef}>
          <Tooltip label={tooltipLabel} status={tooltipStatus} premium={tooltipPremium}></Tooltip>
        </div>
      </Popper>
    </div>
  );
};

export default Map;
