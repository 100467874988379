import { createSlice } from '@reduxjs/toolkit';

const submissionRemoteSlice = createSlice({
  name: 'submissionRemote',
  initialState: {},
  reducers: {
    setSubmissionRemoteContent: (state, action) => {
      return { ...action.payload };
    }
  }
});

const submissionRemoteAction = submissionRemoteSlice.actions;
export { submissionRemoteSlice, submissionRemoteAction };
