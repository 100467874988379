import { getServicesContent } from '@services/servicesFromBo';
import { servicesAction } from './services-slice';

export const setServicesContent = async (dispatch, SMToken) => {
  try {
    const response = await getServicesContent(SMToken);
    dispatch(servicesAction.setServicesContent(response));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
