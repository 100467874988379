import { useEffect, useState } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
// styles
import './Slideshow.scss';
import VideoPlayer from '../video/VideoPlayer';
import { isArray } from '@utils/utils';
import useRedux from '@hooks/useRedux';

/**
 * Slideshow component to display a series of images with optional auto-sliding functionality.
 *
 * @param {string[]} images - Array of image URLs to display in the slideshow.
 * @param {boolean} [autoSlide=false] - Flag to enable or disable automatic sliding of images.
 *
 * @returns {JSX.Element} The rendered Slideshow component.
 */
const Slideshow = ({ images, autoSlide = false, video, slideshowIndex }) => {
  const { getTracking, dispatchSetSlideshowIndex } = useRedux();
  // slide show component
  const interval = 5000;
  // the slideshow can be an array of images with or without a video
  // or it can be one image plus one video
  const length = isArray(images) && video ? images.length + 1 : isArray(images) && !video ? images.length : 2;

  const [currentIndex, setCurrentIndex] = useState(slideshowIndex || 0);
  const [intervalId, setIntervalId] = useState(null);

  // const indexSlideshow = getTracking().slideshowIndex;
  // const forceUpdate = useState(0)[1]; // Trigger a re-render
  // console.log(indexSlideshow);
  useEffect(() => {
    if (slideshowIndex !== null && slideshowIndex !== undefined) {
      setCurrentIndex(() => slideshowIndex);
      // forceUpdate(v => v + 1);
    }
  }, [slideshowIndex]);

  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (autoSlide) {
      const newIntervalId = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % length);
      }, interval);
      setIntervalId(newIntervalId);
    }
  };

  useEffect(() => {
    dispatchSetSlideshowIndex(currentIndex);
  }, [currentIndex]);

  const prevImage = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + length) % length);
    resetInterval();
  };

  const nextImage = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % length);
    resetInterval();
  };

  useEffect(() => {
    resetInterval();

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [length, autoSlide]); // Listen to autoSlide

  const toRender = () => {
    if (isArray(images) && !video) {
      return <img src={images?.[currentIndex]} alt='' />;
    } else if (isArray(images) && video && currentIndex < length - 1) {
      return <img src={images?.[currentIndex]} alt='' />;
    } else if (isArray(images) && video && currentIndex === length - 1) {
      return <VideoPlayer vimeoID={video} />;
    } else if (!isArray(images) && video && currentIndex === 0) {
      return <img src={images} alt='' />;
    } else if (!isArray(images) && video && currentIndex === 1) {
      return <VideoPlayer vimeoID={video} />;
    }
  };

  return (
    <div className='slideshow'>
      <div className='left-button'>
        <IconButton size={'default'} onImageBg={true} icon={'navigation/arrow-left'} onClickFn={prevImage} />
      </div>
      {toRender()}
      <div className='right-button'>
        <IconButton size={'default'} onImageBg={true} icon={'navigation/arrow-right'} onClickFn={nextImage} />
      </div>
    </div>
  );
};

export default Slideshow;
