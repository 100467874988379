export const PLOT_STATUS = Object.freeze({
  Sold: 'CPCV' || 'Property Deed',
  Available: 'Available',
  InNegotiation: 'Negotiation',
  Reserved: 'Reserved',
  NotAvailable: 'Not Available',
  NotMatchFilters: "Doesn't match filters"
});

export const DIALOG_TYPE = Object.freeze({
  Exit: 'exit',
  SaveProject: 'save-project',
  SaveProjectClient: 'save-child-project',
  ConsultPrice: 'consult-price',
  SaveExit: 'save-exit',
  SubmitProject: 'submit-project',
  StatusChange: 'status-change'
});

export const MODAL_TYPE = Object.freeze({
  CuratedSelections: 'curated-selections',
  DesignExperts: 'design-experts',
  HouseTypes: 'house-types',
  ThemeDetails: 'theme-details',
  PremiumModal: 'premium-plot'
});

export const ACTIONS = Object.freeze({
  NewDesign: 'NEW-DESIGN',
  Exit: 'EXIT',
  Back: 'BACK',
  Confirm: 'CONFIRM',
  ConfirmInterest: 'CONFIRM-INTEREST',
  SendEmail: 'SEND-EMAIL'
});

export const CONFIG_STATUS = Object.freeze({
  Draft: 'draft',
  Active: 'active',
  Inactive: 'inactive',
  Submitted: 'submitted'
});

export const CONFIG_FLOW = Object.freeze({
  Guided: 'guided',
  Remote: 'remote'
});
