import React, { useEffect, useState } from 'react';
//components
import Header from '@components/ui/Header/Header';
import OTPInput from '@components/ui/OTPInput/OTPInput';
//styles
import './otp.scss';
//hooks
import useRedux from '@hooks/useRedux';
import { useNavigate } from 'react-router-dom';
//translations
import { useTranslation } from 'react-i18next';
//utils
import { checkVerificationCode, sendVerificationCode } from '@services/otp';
import { isEmpty } from '@utils/functions';
//services
import { registerClient, createRequest, getClientDetails } from '@services/client';
import Cookies from 'js-cookie';

const VerifyCodePage = ({ setRegistrationStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(30);
  const [errorMessage, setErrorMessage] = useState(null);
  const { dispatchNotification, getClient, getForms, dispatchProjectInfo, getEnterpriseContent, PREFERENCES } =
    useRedux();
  const lang = PREFERENCES.get()?.language?.code;
  const pageContent = getForms()?.otp?.[lang]?.type;
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_id = Cookies.get('salesManagerId');
  const sales_manager_token = Cookies.get('sessionId');
  const language = PREFERENCES.get()?.language?.code;
  const enterpriseContent = getEnterpriseContent()?.[language];

  const clientData = getClient();

  const createRequestFunction = async () => {
    const clientSFInfo = await getClientDetails(getClient().email, sales_manager_token, sales_manager_id);

    await createRequest(
      {
        clientEmail: getClient().email,
        salesManagerEmail: sales_manager,
        salesManagerId: sales_manager_id,
        clientDetailsFromSF: clientSFInfo.data,
        projectDefaultLanguage: PREFERENCES?.get()?.language?.code,
        projectPrefix: 'TCT'
      },
      sales_manager_token
    )
      .then(async response => {
        if (response) {
          dispatchProjectInfo(response.projectInfo);
          navigate(`/journey/${response.projectInfo.id}`);
        }
      })
      .catch(error => {
        console.log('Error saving project to DB', error);
      });
  };

  const resendCode = async () => {
    try {
      setTimer(30);
      startTimer();
      await sendVerificationCode(
        clientData?.otpContactService.contact,
        clientData?.otpContactService.method,
        sales_manager_token
      );
    } catch (error) {
      console.log('Erro', error);
      dispatchNotification({
        timer: true,
        type: 'banner',
        status: 'error',
        title: t('otp.verifyPage.error_resend_code')
      });
    }
  };

  const startTimer = () => {
    const count = setInterval(() => {
      setTimer(prevCount => {
        if (prevCount <= 0) {
          clearInterval(count);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    return () => clearInterval(count);
  };

  useEffect(() => {
    startTimer();
  }, []);

  const onCompleteFunction = async numbers => {
    try {
      const verificationCodeResponse = await checkVerificationCode(
        clientData?.otpContactService.contact,
        numbers,
        sales_manager_token
      );

      if (verificationCodeResponse.response.valid) {
        const data = {
          clientType: 'Customer',
          firstName: clientData.name,
          lastName: clientData.surname,
          email: clientData.email,
          phone: clientData.phone,
          nationality: clientData.nationality,
          language: clientData.language,
          RGDP_Marketing: clientData.marketing,
          RGPD_PedidosDeInformacao: clientData.terms,
          newsletter: clientData.newsletter,
          hasOptedOutOfEmail: false,
          do_Not_Email: false,
          sales_manager_email: sales_manager,
          sales_manager_id,
          projectLanguage: PREFERENCES?.get()?.language?.code,
          brokerage: clientData.brokerage,
          agentName: clientData.agentName,
          partnerName: clientData.partner !== 'Other' ? clientData.partner : '',
          partnerNameOther: clientData.partnerName,
          partnerEmail: isEmpty(clientData.agentEmail) ? undefined : clientData.agentEmail,
          partnerPhone:
            isEmpty(clientData.agentPhone) || clientData.agentPhone === '+' ? undefined : clientData.agentPhone
        };

        await registerClient(data, sales_manager_token);
        if (enterpriseContent.showPage) {
          dispatchNotification({
            status: 'success',
            title: t('otp.verifyPage.registrationSuccess')
          });
          setRegistrationStep(3);
        } else {
          await createRequestFunction();
          dispatchNotification({
            status: 'success',
            title: t('otp.verifyPage.registrationSuccess')
          });
        }
      } else {
        setErrorMessage(t('otp.verifyPage.errorMessage'));
      }
    } catch (error) {
      console.log('Erro', error);
      setErrorMessage(t('otp.verifyPage.errorMessage'));
    }
  };

  return (
    pageContent && (
      <>
        <div className='otp-verify-container'>
          <Header shouldDisplay isDark />
          <div className='otp-verify-wrapper'>
            <img src={pageContent.image} className='background-image' />
            <div className='content-wrapper'>
              <div className='content-section'>
                <div className='title-container'>
                  <span className='main-title'>{pageContent.title}</span>
                  <span className='sub-title'>{pageContent.subtitle}</span>
                </div>
                <OTPInput
                  containerStyle={'input-section'}
                  inputNormalStyle={'individual-input'}
                  inputErrorStyle={'individual-input-error'}
                  length={6}
                  onComplete={numbers => onCompleteFunction(numbers)}
                  onChange={() => setErrorMessage(null)}
                  errorMessage={errorMessage}
                />
                <div className='cta-container'>
                  <span className='didnt_get_code'>{pageContent.getCodeLabel}</span>
                  {timer > 0 ? (
                    <span className='new-code'>
                      {t('otp.verifyPage.ask_new')} {timer}s
                    </span>
                  ) : (
                    <span onClick={resendCode} className='resend'>
                      {pageContent.resendLabel}
                    </span>
                  )}
                  <span
                    onClick={() => {
                      setRegistrationStep(1);
                    }}
                    className='change-service'
                  >
                    {pageContent.changeServiceLabel}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default VerifyCodePage;
