import { generateToken } from '@utils/routesAuth/routesAuth';

export const getHeadersToRequest = async ({ SMToken }) => {
  let HEADERS = {};
  if (SMToken) {
    HEADERS = {
      accept: '*/*',
      guidedtoken: SMToken
    };
  } else {
    const token = await generateToken();
    HEADERS = {
      accept: '*/*',
      remotetoken: token
    };
  }
  return HEADERS;
};
