import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
//styles
import './homepage.scss';
//components
import VideoComponent from './VideoComponent';
import Header from '@components/ui/Header/Header';
//utils
import Button from '@components/ui/Buttons/Button/Button';
import imagem from './first_frame.jpg';

const Homepage = ({ homepageContent }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoMuted, toggleVideoSound] = useState(true);
  const { dispatchResetTracking, dispatchProjectInfo, dispatchClient, PREFERENCES, getIsAuthenticated } = useRedux();
  const [content, setContent] = useState(null);
  const buttonType = content?.actionButton?.type?.split('-')?.[0];
  const buttonMode = content?.actionButton?.type?.split('-')?.[1];
  const preferences = PREFERENCES.get();

  const navigate = useNavigate();

  const goToClientPage = () => {
    if (getIsAuthenticated() === null) sessionStorage.setItem('redirectState', '/client');
    navigate('/client');
  };

  useEffect(() => {
    if (preferences.languageOptions && preferences.language === null)
      PREFERENCES.updateLanguage(preferences.languageOptions.find(option => option.isDefault));
    if (preferences.language !== null) setContent(homepageContent?.[0]?.[preferences?.language?.code]);
  }, [preferences.languageOptions, preferences.language]);

  /* useEffect(() => {
    if (isVideoLoaded) {
      toggleVideoSound(!content?.backgroundVideo?.enableSound);
    }
  }, [isVideoLoaded]); */

  useEffect(() => {
    dispatchResetTracking();
    PREFERENCES.reset();
    dispatchProjectInfo(null);
    dispatchClient(null);
  }, []);

  //TODO i18n

  return homepageContent && homepageContent.length > 0 ? (
    <div className='homepage-container'>
      <Header
        shouldDisplay
        appearDashboard
        audioBtn={content?.backgroundVideo?.enableSound ? [videoMuted, toggleVideoSound] : null}
        disableClickableLogo
      />
      <div className='content-section'>
        <div className='title-wrapper'>
          <span className='homepage-title1'>{content?.title1}</span>
          <span className='homepage-title2'>{content?.title2}</span>
        </div>
        <div>
          <Button
            primary={buttonType === 'PRIMARY'}
            secondary={buttonType === 'SECONDARY'}
            ghost={buttonType === 'GHOST'}
            isDark={buttonMode === 'DARK'}
            text={content?.actionButton?.label}
            onClickFn={goToClientPage}
          />
        </div>
      </div>
      {!isVideoLoaded && <img className='background-image' src={imagem} />}
      {content?.backgroundVideo?.url && (
        <VideoComponent
          muted={videoMuted}
          videoUrl={content?.backgroundVideo?.url}
          setVideoIsReady={setIsVideoLoaded}
          metaData={content?.backgroundVideo}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default Homepage;
