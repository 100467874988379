import '../../shared.scss';
import { MaterialController } from '@components/answers/controllers/MaterialController';
import SectionMaterial from '@components/answers/SectionMaterial';
import useRedux from '@hooks/useRedux';

export const Pool = ({ control, packs, question, onClickFn, disabled }) => {
  const { getCurrentStepId } = useRedux();
  return (
    <div className='materialContainer'>
      <div className='materialWrapper'>
        <span className='title'>{question.title}</span>
        <MaterialController
          control={control}
          currentStepId={getCurrentStepId()}
          division={{ controllerId: 'poolCovering', packs }}
          question={{ id: 'pool', type: 'poolCovering' }}
          onClickFn={onClickFn}
          Component={SectionMaterial}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
