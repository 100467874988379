import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
// components
import Header from '@components/ui/Header/Header';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './SubmissionRegisterRemote.scss';

const SubmissionRegisterRemote = ({}) => {
  const { getCurrentProject, getRegisterRemoteContent, getSubmissionRemoteContent, getTracking, PREFERENCES } =
    useRedux();
  const pageContent =
    getTracking().remoteInformationPage.status === 'registerRemote'
      ? getRegisterRemoteContent()
      : getSubmissionRemoteContent();
  const [content, setContent] = useState(pageContent[PREFERENCES?.get()?.language?.code]);
  const salesManager = getCurrentProject()?.sales_manager;
  const client = getCurrentProject()?.client;
  const projectId = getTracking()?.remoteInformationPage?.pageInformation?.id;
  const masterId = getTracking()?.remoteInformationPage?.pageInformation?.parent;
  const projectName = getTracking()?.remoteInformationPage?.pageInformation?.configurationName;
  const remainingCount = getTracking()?.remoteInformationPage?.pageInformation?.remainingCount;
  const [contentIsReady, setContentIsReady] = useState(false);

  useEffect(() => {
    if (PREFERENCES.get().language !== null) {
      setContent(pageContent[PREFERENCES?.get()?.language?.code]);
    }
  }, [PREFERENCES.get().language]);

  const paragraphsBodycopy = content?.bodycopy.split(/\n\n/);
  const paragraphsAssistance = content?.assistance.split(/\n\n/);

  return (
    <div className='remote-container'>
      {contentIsReady && <Header shouldDisplay noLogin disableClickableLogo />}
      <div className='content-wrapper'>
        <div className='background-image-container'>
          <div className='overlay'></div>
          <img
            src={content.background}
            onLoad={() => {
              setContentIsReady(true);
            }}
            className='background-image'
          />
        </div>
        {contentIsReady && (
          <div className='content-section'>
            <span className='id'>{`Bespoke id ${projectId}`}</span>
            <span className='title'>{content.title.replace('{{projectName}}', projectName)}</span>
            <div className='bodycopy'>
              {paragraphsBodycopy.map((paragraph, index) => {
                return (
                  <div key={index}>
                    <ReactMarkdown>
                      {paragraph
                        .replace(/\n/g, '  \n')
                        .replace('{{clientEmail}}', client)
                        .replace('{{remainingCount}}', remainingCount)
                        .replace('{{projectId}}', masterId)}
                    </ReactMarkdown>
                    {index < paragraphsBodycopy.length - 1 && <br />}
                  </div>
                );
              })}
            </div>
            <div className='card'>
              {paragraphsAssistance.map((paragraph, index) => {
                return (
                  <div key={index}>
                    <ReactMarkdown>
                      {paragraph
                        .replace(/\n/g, '  \n')
                        .replace('{{salesManagerName}}', salesManager.name)
                        .replace('{{salesManagerEmail}}', salesManager.email)
                        .replace('{{salesManagerPhone}}', salesManager.phoneNumber)}
                    </ReactMarkdown>
                    {index < paragraphsAssistance.length - 1 && <br />}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubmissionRegisterRemote;
