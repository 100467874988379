// components
import { SVG } from '@assets/svg';
// styles
import './VideoControl.scss';

const VideoControl = ({ onClickFn, icon }) => {
  return (
    <div className='video-control' onClick={onClickFn}>
      {<SVG icon={icon} />}
    </div>
  );
};

export default VideoControl;
