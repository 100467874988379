import { useState, useCallback } from 'react';
import Button from '@components/ui/Buttons/Button/Button';
import './admin.scss';
import image from '@assets/img/review1.png';
import { updateCollections } from '@services/strapi';
import { useDropzone } from 'react-dropzone';
import { SVG } from '@assets/svg';
import Cookies from 'js-cookie';

const Admin = ({}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    // Filter for Excel files only
    const excelFile = acceptedFiles.find(file =>
      ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
        file.type
      )
    );

    if (!excelFile) return;

    setSelectedFile(excelFile);
  }, []);

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      await updateCollections(formData, Cookies.get('sessionId'));
      setSelectedFile(null); // Clear selected file after upload
    } catch (error) {
      console.log(error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.xls, .xlsx', // Accept only Excel files
    maxFiles: 1 // Limit the number of files
  });

  return (
    <div className='admin-container'>
      <div className='background'>
        <img src={image} alt='background' />
      </div>

      <div className='uploader'>
        <span className='title'>Quickly manage your backoffice</span>
        <span className='text'>
          Simplify your backoffice updates in seconds—upload an Excel file and take control with ease.
        </span>

        <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''}`}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className='textshort'>Drop the file here...</p>
          ) : (
            <div className='choose'>
              <SVG icon='action/upload' />
              <span className='label'>Browse through your files, or drag your excel here.</span>
            </div>
          )}
        </div>

        {/* Selected File */}
        {selectedFile && (
          <div className='selected-file'>
            <div className='name'>
              <SVG icon='action/file' />
              <span className='fileName'>{selectedFile.name}</span>
            </div>

            <div className='upload-button'>
              <Button primary text='Upload' onClick={handleUpload} />
            </div>
          </div>
        )}
        <span className='textshort' style={{ marginTop: '10px' }}>
          Important Notice: Uploading a file will overwrite all existing data. Please ensure that your file contains the
          correct and up-to-date information before proceeding.
        </span>
      </div>
    </div>
  );
};

export default Admin;
