import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// hooks
import useRedux from '@hooks/useRedux';
import useWindowSize from '@hooks/useWindowSize';
import { useAuthCookieWatcher } from '@hooks/useAuthCookieWatcher';
// utils
import { isEmpty } from '@utils/functions';
import { Navigate } from 'react-router-dom';
// pages
import Homepage from '@pages/Homepage/Homepage';
import ProtectedRoute from '@components/auth/ProtectedRoute';
import ProtectedRouteRemote from '@components/auth/ProtectedRouteRemote';
import SalesMangerLogin from '@pages/SalesManager/login/login';
import ClientIdPage from '@pages/Client/ClientIdPage';
import InfoPageComponent from '@pages/InfoPages/InfoPageComponent';
import ClientRegistration from '@pages/ClientRegistration/ClientRegistration';
import Admin from '@pages/admin/Admin';
// components
import JourneyBuilder from '@components/forms/JourneyBuilder';
import Notification from '@components/ui/Notification/Notification';
import Dashboard from '@pages/Dashboard/Dashboard';
//services
import { GetInfoPagesContent } from '@services/singlePages/infoPages';
import { getFiltersValuesMinAndMax } from '@services/pricing';
// translations
import { useTranslation } from 'react-i18next';
import { getSalesmanagerInfoFromBO } from '@services/sales-manager';
import Cookies from 'js-cookie';
import UnsupportedDevice from '@pages/UnsupportedDevice/UnsupportedDevice';

function App() {
  const {
    dispatchForms,
    dispatchMacroplots,
    dispatchHouses,
    dispatchModules,
    dispatchHouseTypes,
    dispatchPacks,
    dispatchModals,
    dispatchPartners,
    dispatchProjectSubmittedContent,
    getForms,
    getMacroplots,
    getPlots,
    getNotification,
    dispatchSVG,
    HOMEPAGE,
    DASHBOARD,
    PREFERENCES,
    UNSUPPORTEDDEVICE,
    PRICING,
    getIsAuthenticated,
    dispatchSubmissionRemoteContent,
    dispatchRegisterRemoteContent,
    dispatchEnterpriseContent,
    dispatchIsAuthenticated,
    dispatchServicesContent,
    getEnterpriseContent
  } = useRedux();

  const authenticatedUser = getIsAuthenticated();
  const { i18n } = useTranslation();

  const [infoPagesContent, setInfoPagesContent] = useState(null);

  const forms = getForms();
  const macroplots = getMacroplots();
  const plots = getPlots();
  const notification = getNotification();
  const preferences = PREFERENCES.get();
  const homepageContent = HOMEPAGE.get();

  const { width, height } = useWindowSize();

  // Check if the device size is smaller than 1180px by 820px or 430px
  const isSmallScreen = width < 1180 || height < 820;
  const isExtraSmallScreen = width <= 430;

  // useEffect to set homepage content + locales
  useEffect(() => {
    HOMEPAGE.set();
  }, []);

  // useEffect after SM login to get SM info from BO
  useEffect(() => {
    const fetchSalesManagerInfo = async () => {
      if (!!Cookies.get('sessionId') && !!Cookies.get('salesManager')) {
        const SMInfoOnBo = await getSalesmanagerInfoFromBO(
          Cookies.get('salesManager'),
          SMInfoOnBo.role === 'ADMIN',
          Cookies.get('sessionId')
        );
        if (!isEmpty(SMInfoOnBo)) {
          dispatchIsAuthenticated({ type: 'SM', isAdmin: SMInfoOnBo.role === 'ADMIN' });
        }
      }
    };
    fetchSalesManagerInfo();
  }, [Cookies.get('sessionId')]);

  // useEffect after homepage content and preferences are setted
  useEffect(() => {
    if (!isEmpty(homepageContent) && preferences.languageOptions) {
      const lang = localStorage.getItem('lang');
      // persist last language on page refresh
      if (lang) {
        const defaultLang = JSON.parse(lang);
        i18n.changeLanguage(defaultLang?.code);
        PREFERENCES.updateLanguage(defaultLang);
      } else {
        const defaultLang = preferences.languageOptions.find(option => option.isDefault);
        i18n.changeLanguage(defaultLang?.code);
        PREFERENCES.updateLanguage(preferences.languageOptions.find(option => option.isDefault));
        localStorage.setItem('lang', JSON.stringify(defaultLang));
      }

      const fetchPrivacyPagesInfo = async SMToken => {
        try {
          const infoPages = await GetInfoPagesContent(SMToken);
          if (!isEmpty(infoPages)) setInfoPagesContent(infoPages);
        } catch (error) {
          console.error('Error fetching info pages:', error);
        }
      };

      if (authenticatedUser?.type === 'SM' || authenticatedUser?.type === 'Client') {
        const SMToken = authenticatedUser?.type === 'SM' ? Cookies.get('sessionId') : null;
        if (authenticatedUser?.type === 'SM') {
          fetchPrivacyPagesInfo(SMToken);
          PRICING.setFilterValues(SMToken);
          DASHBOARD.set(SMToken);
        }
        dispatchMacroplots(SMToken);
        // dispatchPlots() - loaded on JourneyBuilder
        dispatchHouses(SMToken);
        dispatchModules(SMToken);
        dispatchHouseTypes(SMToken);
        dispatchPacks(SMToken);
        dispatchModals(SMToken);
        dispatchPartners(SMToken);
        dispatchProjectSubmittedContent(SMToken);
        dispatchSubmissionRemoteContent(SMToken);
        dispatchRegisterRemoteContent(SMToken);
        dispatchServicesContent(SMToken);
      }
      if (isEmpty(getEnterpriseContent())) dispatchEnterpriseContent();
      if (isEmpty(UNSUPPORTEDDEVICE.get())) UNSUPPORTEDDEVICE.set();
      // dispatchSVG();
      PREFERENCES.set();
    }
  }, [preferences.languageOptions, authenticatedUser]);

  // useEffect to fetch forms
  useEffect(() => {
    if (isEmpty(macroplots) && isEmpty(plots) && forms.journey === undefined) {
      dispatchForms({ additionalData: { macroplots, plots }, renderWithoutJourney: true });
    }

    if (!isEmpty(macroplots) && !isEmpty(plots) && (!forms.journey || isEmpty(forms.journey))) {
      dispatchForms({ additionalData: { macroplots, plots }, renderWithoutJourney: false });
      dispatchSVG();
    }
  }, [macroplots, plots]);

  function AuthWatcher() {
    useAuthCookieWatcher();
    return null;
  }

  return (
    <>
      {!isSmallScreen ? (
        <Router>
          {/* Listener to track if cookies are set or not expired */}
          <AuthWatcher />
          <Routes>
            <Route path='/' element={<Homepage homepageContent={homepageContent} />} />
            <Route path='/admin' element={authenticatedUser?.isAdmin ? <Admin /> : <Navigate to='/' replace />} />
            <Route path='/sales-manager/login' element={<SalesMangerLogin />} />
            <Route path='/journey/:id' element={<ProtectedRouteRemote element={<JourneyBuilder />} />} />
            <Route path='/client' element={<ProtectedRoute element={<ClientIdPage />} />} />
            <Route path='/dashboard' element={<ProtectedRoute element={<Dashboard />} />} />
            <Route path='/client/register' element={<ProtectedRoute element={<ClientRegistration />} />} />
            {infoPagesContent?.termsData && (
              <Route
                path='/terms-and-conditions'
                element={<ProtectedRoute element={<InfoPageComponent data={infoPagesContent.termsData} />} />}
              />
            )}
            {infoPagesContent?.privacyData && (
              <Route
                path='/privacy-policy'
                element={<ProtectedRoute element={<InfoPageComponent data={infoPagesContent.privacyData} />} />}
              />
            )}
            <Route path='/verifyEmailRemote' element={<ClientIdPage isRemoteFlow />} />
          </Routes>
        </Router>
      ) : isExtraSmallScreen ? (
        <UnsupportedDevice small />
      ) : (
        <UnsupportedDevice />
      )}
      {notification.visibility && (
        <Notification
          timer={notification.timer}
          type={notification.type}
          length={notification.length}
          status={notification.status}
          action={notification.action}
          title={notification.title}
          description={notification.description}
        />
      )}
    </>

    // <div className='App'>
    //   {!isEmpty(forms.journey) && <JourneyBuilder svg={svg} />}
    //   {/* <LeadIntegration /> */}
    // </div>
  );
}

export default App;
