import { houseTypesAction } from './houseTypes-slice';
import { getHouseTypes } from '@services/houseTypes';

export const setHouseTypes = async (dispatch, SMToken) => {
  try {
    const response = await getHouseTypes(true, 'small', SMToken);
    dispatch(houseTypesAction.setHouseTypes(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
