import { useState, useEffect } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import Button from '@components/ui/Buttons/Button/Button';
import ProfileCell from '@components/ui/Cells/ProfileCell/ProfileCell';
import Tag from '@components/ui/Tag/Tag';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './MultiChoiceModal.scss';
//services
import { sendEmail } from '@services/emails';
import { updateRequest } from '@services/client';
import Cookies from 'js-cookie';
//translations
import { useTranslation } from 'react-i18next';
//utils
import { MODAL_TYPE } from '@utils/enums';
import { SVG } from '@assets/svg';
import { isEmpty } from '@utils/functions';

const MultiChoiceModal = ({ onClose, openServicePartner }) => {
  const { getModals, dispatchNotification, getCurrentProject, getServicesContent, PREFERENCES } = useRedux();
  const [emptyChoicesError, setEmptyChoicesError] = useState(false);
  const sales_manager_token = Cookies.get('sessionId');
  const sales_manager_id = Cookies.get('salesManagerId');
  const { t } = useTranslation();
  const [selectedTag, setSelectedTag] = useState({label: t('multi_choice_modal.all_tag'), value: t('multi_choice_modal.all_tag')});
  const modal = getModals()[MODAL_TYPE.DesignExperts]?.[PREFERENCES.get().language.code].content;
  let profiles = modal?.choices;
  const [selected, setSelected] = useState([]);

  let tags = [{ label: t('multi_choice_modal.all_tag'), value: t('multi_choice_modal.all_tag') }];
  profiles?.map(profile => {
    const serviceCode = getServicesContent()?.en?.find(item => item?.attributes?.label === profile?.service)?.attributes
      ?.identifier;
    const translatedService = getServicesContent()?.[PREFERENCES?.get()?.language?.code]?.find(
      item => item.attributes.identifier === serviceCode
    )?.attributes?.label;
    if (!tags.some(tag => tag.value === profile.service)) {
      tags.push({ label: translatedService, value: profile.service });
    }
  });

  profiles = profiles.filter(
    profile => profile?.service === selectedTag?.value || selectedTag?.value === t('multi_choice_modal.all_tag')
  );

  // remove duplicates emails
  // if any email on CC is on TO, remove it

  const getEmailsTo = () => {
    let emailsTo = [];
    selected.forEach(item => {
      if (item?.emailTo) {
        emailsTo = [...emailsTo, ...item.emailTo.split(';').map(email => email.trim())];
      }
    });
    emailsTo = [...new Set(emailsTo)];
    return emailsTo;
  };

  const getEmailsCC = emailsTo => {
    let emailsCC = [];
    selected.map(item => {
      if (item?.emailCC) {
        emailsCC = [...emailsCC, ...item.emailCC.split(';').map(email => email.trim())];
      }
    });
    emailsCC = [...emailsCC, getCurrentProject().sales_manager.email];
    emailsCC = [...new Set(emailsCC)];
    if (!isEmpty(emailsTo)) emailsCC = emailsCC.filter(emailCC => !emailsTo.includes(emailCC));
    return emailsCC;
  };

  const handleRequestContactButton = async () => {
    if (selected.length > 0) {
      try {
        const payloadToSend = {
          to: getEmailsTo(),
          cc: getEmailsCC(getEmailsTo()) || null,
          identifier: 'additional-services',
          projectName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
          bespokeId: getCurrentProject()?.id,
          clientName: getCurrentProject()?.clientId?.name,
          clientEmail: getCurrentProject()?.clientId?.email,
          services: selected.map(item => ({
            type: item?.service,
            name: item.name
          }))
        };

        if (sales_manager_token && getCurrentProject().requestId) {
          const unformattedServices = selected.map(item => ({
            type: item?.service,
            name: item.name
          }));

          const formattedServices = unformattedServices.map(item => `<p>${item.name}: ${item.type}</p>`).join('\n');

          await updateRequest(
            {
              requestId: getCurrentProject().requestId,
              payloadToUpdate: { Platform_Services_Comporta__c: formattedServices }
            },
            sales_manager_token
          );
        }

        sendEmail(payloadToSend);
        onClose();
        dispatchNotification({
          timer: true,
          status: 'success',
          title: t('requestContact.success')
        });
      } catch (error) {
        console.log('Error', error);
        onClose();
        dispatchNotification({
          timer: true,
          status: 'error',
          title: t('requestContact.error')
        });
      }
    } else {
      setEmptyChoicesError(true);
    }
  };

  const buttonActions = {
    EXIT: onClose,
    'SEND-EMAIL': handleRequestContactButton
  };

  return (
    <Swiper
      watchSlidesProgress={true} // enables to see next or previous slide
      slidesPerView={'auto'} // max os slides per view
      centeredSlides={true}
      spaceBetween={40}
      pagination={{
        clickable: true,
        dynamicBullets: false
      }}
      modules={[Navigation, Pagination]}
      className='multi-choice-modal-swiper'
    >
      <SwiperSlide className='multi-choice-modal-swiper-slide'>
        <div className='multi-choice-modal-slide-content'>
          <div className='multi-choice-modal-slide-text'>
            <div className='multi-choice-modal-text-header'>
              <IconButton
                className='multi-choice-modal-close'
                size={'small'}
                onImageBg={false}
                icon={'semantic/close'}
                onClickFn={onClose}
              />
            </div>
            <span className='multi-choice-modal-title'>{modal?.title}</span>
            <span className='multi-choice-modal-description'>{modal?.descriptions}</span>
          </div>
          <div className='multi-choice-modal-content'>
            {emptyChoicesError && (
              <div className='empty-error-message'>
                <SVG customCSS={'error-icon'} icon='semantic/circle-x' /> <p>{t('requestContact.empty')}</p>
              </div>
            )}
            <div className='multi-choice-modal-content-header'>
              <div className='multi-choice-modal-tag'>
                {tags.map(tag => {
                  return <Tag label={tag.label} selected={selectedTag.label} onClickFn={() => setSelectedTag({label: tag.label, value: tag.value})}></Tag>;
                })}
              </div>
              <LinkButton text={t('multi_choice_modal.deselect_all')} onClickFn={() => setSelected([])}></LinkButton>
            </div>
            <div className='multi-choice-modal-profile'>
              {profiles.map(profile => {
                return (
                  <ProfileCell
                    error={emptyChoicesError}
                    profile={profile}
                    onSelect={() => {
                      setEmptyChoicesError(false);
                      setSelected([...selected, profile]);
                    }}
                    onDeselect={() => setSelected(selected.filter(item => item !== profile))}
                    isProfileSelected={selected.includes(profile)}
                    openServicePartner={openServicePartner}
                  ></ProfileCell>
                );
              })}
            </div>
          </div>
          <div className='multi-choice-modal-footer'>
            {modal?.actions.map(action => {
              const buttonPropsMap = {
                primary: {
                  primary: true,
                  isDark: action.type.toLowerCase().includes('dark'),
                  destructive: action.type.toLowerCase().includes('destructive')
                },
                secondary: {
                  secondary: true,
                  isDark: action.type.toLowerCase().includes('dark'),
                  destructive: action.type.toLowerCase().includes('destructive')
                },
                ghost: {
                  ghost: true,
                  isDark: action.type.toLowerCase().includes('dark'),
                  destructive: action.type.toLowerCase().includes('destructive')
                }
              };
              const buttonProps = buttonPropsMap[action.type.toLowerCase()?.split('-')?.[0]];

              return (
                <Button
                  {...buttonProps}
                  onClickFn={() => {
                    buttonActions[action.action] && buttonActions[action.action]();
                  }}
                  text={action.label}
                  key={action.label}
                />
              );
            })}
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MultiChoiceModal;
