import { useEffect } from 'react';
import './DetailedCell.scss';
import useRender from '@hooks/useRender';
import useRedux from '@hooks/useRedux';
import { MODAL_TYPE } from '@utils/enums';

const DetailedCell = ({
  field,
  item,
  renderCondition,
  shouldTriggerRenderCondition = true,
  isSelected,
  customFn,
  isPlot,
  isMacroplot,
  children,
  disabled,
  onClickFn
}) => {
  const { itemShouldRender } = useRender();
  const { dispatchSetPlotHover, dispatchSetMacroplotHover, getTracking, dispatchSetDialog } = useRedux();

  const handleClick = () => {
    if (!disabled && onClickFn) onClickFn();
    else if (!disabled) {
      field.onChange(item.id);
      if (item.isPremium) dispatchSetDialog({ open: true, type: 'premium', id: MODAL_TYPE.PremiumModal });
      if (customFn) customFn();
    }
  };

  const handleMouseEnter = () => {
    if (!disabled) {
      if (isPlot && (!getTracking().plotHover || getTracking().plotHover === null)) dispatchSetPlotHover(item.id);
      if (isMacroplot && (!getTracking().macroplotHover || getTracking().macroplotHover === null))
        dispatchSetMacroplotHover(item.id);
    }
  };

  const handleMouseLeave = () => {
    if (!disabled) {
      if (isPlot) dispatchSetPlotHover(null);
      if (isMacroplot) dispatchSetMacroplotHover(null);
    }
  };

  const handleScroll = () => {
    // Manually trigger mouse leave when scrolling happens.
    if (getTracking().plotHover) {
      if (isPlot) dispatchSetPlotHover(null);
    }
    if (getTracking().macroplotHover) {
      if (isMacroplot) dispatchSetMacroplotHover(null);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [getTracking().plotHover, getTracking().macroplotHover]);

  // The component will only render if either:
  // shouldTriggerRenderCondition is false, or
  // itemShouldRender(renderCondition, item) returns true.
  const shouldRender = !shouldTriggerRenderCondition || itemShouldRender(renderCondition, item);

  if (!shouldRender) return null;

  return (
    <div
      className={`detailed-cell${isSelected ? ' selected' : ''}${disabled ? ' disabled' : ''}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      tabIndex={disabled ? -1 : 0}
    >
      {children}
    </div>
  );
};

export { DetailedCell };
