import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import validator from 'validator';
// components
import Checkbox from '@components/ui/Checkbox/Checkbox';
import Button from '@components/ui/Buttons/Button/Button';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Dropdown from '@components/ui/Dropdown/Dropdown';
import RadioButton from '@components/ui/RadioButton/RadioButton';
import Header from '@components/ui/Header/Header';
import InputPhone from '@components/ui/Inputs/PhoneInput/InputPhone';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import ClientOTPRequest from './ClientOTPRequest';
import VerifyCodePage from './VerifyCode';
import EnterpriseSelection from '@pages/EnterpriseSelection/EnterPriseSelection';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './ClientRegistration.scss';
import { isEmpty, isValidEmail } from '@utils/functions';
import { NATIONALITY_OPTIONS, LANGUAGES_OPTIONS, PARTNERS_OPTIONS } from '@utils/client/registration/constants';
// translations
import { useTranslation } from 'react-i18next';

const ClientRegistration = () => {
  const { dispatchClient, getClient, getForms, PREFERENCES, getTracking, dispatchUpdateTracking } = useRedux();
  const [registrationStep, setRegistrationStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const lang = PREFERENCES.get()?.language?.code;
  const pageContent = getForms()?.register?.[lang]?.type;
  const { t } = useTranslation();
  const emailInputRef = useRef();
  const agentNameInputRef = useRef();

  const scrollIntoEmailInput = () => {
    emailInputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const scrollIntoAgentNameInput = () => {
    agentNameInputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    dispatchUpdateTracking({
      idToUpdate: 'registrationFlow',
      updatedObject: true
    });
  }, []);

  const routesIdentifer = { 'terms-and-conditions': '/terms-and-conditions', 'privacy-policy': '/privacy-policy' };

  function renderDisclaimerContent(content) {
    const regex = /\[([^\]]+)]\(([^)]+)\)/g;
    const parts = [];
    let lastIndex = 0;

    content.replace(regex, (match, text, url, offset) => {
      if (lastIndex < offset) {
        parts.push(content.slice(lastIndex, offset));
      }

      parts.push(
        <Link
          to={routesIdentifer?.[url]}
          target='_blank'
          rel='noopener noreferrer'
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {text}
        </Link>
      );

      lastIndex = offset + match.length;
    });

    if (lastIndex < content.length) {
      parts.push(content.slice(lastIndex));
    }

    return parts;
  }

  const [clientInfo, setClientInfo] = useState(
    !isEmpty(getClient()) && getTracking()?.registrationFlow
      ? getClient()
      : {
          name: '',
          surname: '',
          email: location.state,
          phone: '',
          nationality: '',
          language: '',
          brokerage: '',
          partner: '',
          partnerName: '',
          agentName: '',
          agentEmail: '',
          agentPhone: '',
          terms: false,
          marketing: false,
          newsletter: false
        }
  );

  const [error, setError] = useState({});
  const [agentPhone, setAgentPhone] = useState(clientInfo.agentPhone);
  const [phone, setPhone] = useState(clientInfo.phone);

  const [goToNextPage, setGoToNextPage] = useState(false);

  useEffect(() => {
    if (goToNextPage && isEmpty(error)) {
      dispatchClient(clientInfo);
      setRegistrationStep(1);
    }
    setGoToNextPage(false);
  }, [goToNextPage, error]);

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target;
    setClientInfo(prev => ({ ...prev, [name]: value }));
  };

  const handlePhone = (value, countryCode) => {
    const code = countryCode.country.dialCode;
    const phoneNumber = value.replace('+', '').replace(code, '');
    setPhone(phoneNumber);
    setClientInfo(prev => ({ ...prev, phone: value }));
  };

  const handleAgentPhone = (value, countryCode) => {
    const code = countryCode.country.dialCode;
    const phoneNumber = value.replace('+', '').replace(code, '');
    setAgentPhone(phoneNumber);
    setClientInfo(prev => ({ ...prev, agentPhone: value }));
  };

  const handleClear = e => {
    if (e.target.name === 'phone') setPhone('');
    if (e.target.name === 'agentPhone') setAgentPhone('');
    setClientInfo(prev => ({ ...prev, [e.target.name]: '' }));
  };

  useEffect(() => {
    if (clientInfo?.brokerage === 'No') {
      setClientInfo(prev => ({ ...prev, partner: '', partnerName: '', agentName: '', agentEmail: '', agentPhone: '' }));
    }
  }, [clientInfo?.brokerage]);

  const handleRegister = () => {
    setError({});

    if (isEmpty(clientInfo?.name)) {
      setError(prev => ({ ...prev, name: t('client_registration.empty_name') }));
      scrollIntoEmailInput();
    }
    if (isEmpty(clientInfo?.surname)) {
      setError(prev => ({ ...prev, surname: t('client_registration.empty_surname') }));
      scrollIntoEmailInput();
    }
    if (isEmpty(phone)) {
      setError(prev => ({ ...prev, phone: t('client_registration.empty_phone_number') }));
      scrollIntoEmailInput();
    }
    if (isEmpty(clientInfo?.nationality)) {
      setError(prev => ({ ...prev, nationality: t('client_registration.empty_nationality') }));
      scrollIntoEmailInput();
    }
    if (isEmpty(clientInfo?.brokerage)) {
      setError(prev => ({ ...prev, brokerage: t('client_registration.empty_brokerage') }));
    }
    if (clientInfo?.brokerage === 'Yes' && isEmpty(clientInfo?.partner)) {
      setError(prev => ({ ...prev, partner: t('client_registration.empty_partner') }));
      scrollIntoAgentNameInput();
    }
    if (clientInfo?.partner === 'Other' && isEmpty(clientInfo?.partnerName)) {
      setError(prev => ({ ...prev, partnerName: t('client_registration.empty_partner_name') }));
      scrollIntoAgentNameInput();
    }
    if (clientInfo?.brokerage === 'Yes' && isEmpty(clientInfo?.agentName)) {
      setError(prev => ({ ...prev, agentName: t('client_registration.empty_agent_name') }));
      scrollIntoAgentNameInput();
    }
    if (clientInfo?.brokerage === 'Yes' && isEmpty(clientInfo?.agentEmail)) {
      setError(prev => ({ ...prev, agentEmail: t('client_registration.empty_agent_email') }));
      scrollIntoAgentNameInput();
    }
    if (clientInfo?.terms === false) setError(prev => ({ ...prev, terms: true }));

    if (!isEmpty(clientInfo.name) && clientInfo.name.includes(' ')) {
      setError(prev => ({ ...prev, name: t('client_registration.invalid_name') }));
      scrollIntoEmailInput();
    }
    if (!isEmpty(phone) && !validator.isMobilePhone(clientInfo.phone)) {
      setError(prev => ({ ...prev, phone: t('client_registration.invalid_phone_number') }));
      scrollIntoEmailInput();
    }
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(clientInfo?.agentEmail) && !isValidEmail(clientInfo?.agentEmail)) {
      setError(prev => ({ ...prev, agentEmail: t('client_registration.invalid_email') }));
      scrollIntoAgentNameInput();
    }
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(agentPhone) && !validator.isMobilePhone(clientInfo.agentPhone)) {
      setError(prev => ({ ...prev, agentPhone: t('client_registration.invalid_phone_number') }));
      scrollIntoAgentNameInput();
    }

    setGoToNextPage(true);
  };

  useEffect(() => {
    if (!isEmpty(clientInfo?.name)) setError(prev => ({ ...prev, name: '' }));
    if (!isEmpty(clientInfo?.surname)) setError(prev => ({ ...prev, surname: '' }));
    if (!isEmpty(clientInfo?.phone) && validator.isMobilePhone(clientInfo.phone))
      setError(prev => ({ ...prev, phone: '' }));
    if (!isEmpty(clientInfo?.nationality)) setError(prev => ({ ...prev, nationality: '' }));
    if (!isEmpty(clientInfo?.brokerage)) setError(prev => ({ ...prev, brokerage: '' }));
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(clientInfo?.partner)) setError(prev => ({ ...prev, partner: '' }));
    if (clientInfo?.partner === 'Other' && !isEmpty(clientInfo?.partnerName))
      setError(prev => ({ ...prev, partnerName: '' }));
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(clientInfo?.agentName))
      setError(prev => ({ ...prev, agentName: '' }));
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(clientInfo?.agentEmail))
      setError(prev => ({ ...prev, agentEmail: '' }));
    if (clientInfo?.terms === true) setError(prev => ({ ...prev, terms: false }));
  }, [clientInfo]);

  return (
    <>
      {registrationStep === 0 && pageContent && (
        <div className='client-data-container'>
          <Header shouldDisplay isDark />
          <div className='client-data-wrapper'>
            <img src={pageContent.image} className='client-data-wrapper-background-image' />
            <div className='content-wrapper'>
              <div className='client-data'>
                <div className='back-button'>
                  <LinkButton
                    icon='navigation/arrow-left'
                    text={pageContent.buttonBack}
                    onClickFn={() => navigate('/client')}
                  />
                </div>
                <div className='client-data-header'>
                  <span className='client-data-header-title'>{pageContent.title}</span>
                  <span className='client-data-header-description'>{pageContent.subtitle}</span>
                </div>
                <div className='client-data-body'>
                  <TextInput
                    label={pageContent.nameLabel}
                    name='name'
                    icon='action/user'
                    value={clientInfo.name}
                    onChangeFn={handleChange}
                    clearFn={() => handleClear({ target: { name: 'name' } })}
                    required
                    errorMessage={error.name}
                  />

                  <TextInput
                    label={pageContent.surnameLabel}
                    name='surname'
                    icon='action/user'
                    value={clientInfo.surname}
                    onChangeFn={handleChange}
                    clearFn={() => handleClear({ target: { name: 'surname' } })}
                    required
                    errorMessage={error.surname}
                  />
                  <div ref={emailInputRef}>
                    <TextInput
                      label={pageContent.emailLabel}
                      name='email'
                      icon='action/email'
                      lock
                      value={clientInfo.email}
                      required
                    />
                  </div>
                  <InputPhone
                    name='phone'
                    value={`${clientInfo.phone}`}
                    onChangeFn={handlePhone}
                    clearFn={() => handleClear({ target: { name: 'phone' } })}
                    required
                    errorMessage={error.phone}
                    translatedLabels={{
                      countryCode: pageContent.countryCodeLabel,
                      phoneNumber: pageContent.phoneNumberLabel,
                      optional: pageContent.optionalLabel
                    }}
                  />
                  <div className='dropdown'>
                    <Dropdown
                      label={pageContent.nationalityLabel}
                      placeholder={pageContent.nationalityPlaceholder}
                      options={NATIONALITY_OPTIONS}
                      onChange={item => {
                        setClientInfo(prev => ({ ...prev, nationality: item }));
                      }}
                      value={clientInfo.nationality}
                      errorMessage={error.nationality}
                    ></Dropdown>
                    <Dropdown
                      label={pageContent.languageLabel}
                      placeholder={pageContent.languagePlaceholder}
                      options={LANGUAGES_OPTIONS}
                      onChange={item => {
                        setClientInfo(prev => ({ ...prev, language: item }));
                      }}
                      value={clientInfo.language}
                      optional
                      translatedOptional={pageContent.optionalLabel}
                    ></Dropdown>
                  </div>
                  <div className='brokerage'>
                    <span className='brokerage-title'>{pageContent.brokerageLabel}</span>
                    <div className='brokerage-list'>
                      {[
                        { label: pageContent.brokerageYesLabel, value: 'Yes' },
                        { label: pageContent.brokerageNoLabel, value: 'No' }
                      ].map(entry => {
                        return (
                          <RadioButton
                            name='brokerage'
                            id={entry.value}
                            label={entry.label}
                            onSelect={() => {
                              setClientInfo(prev => ({ ...prev, brokerage: entry.value }));
                            }}
                            value={clientInfo.brokerage !== '' ? clientInfo.brokerage : false}
                            error={error.brokerage || false}
                          />
                        );
                      })}
                    </div>
                    <span className='brokerage-error-message'>{error.brokerage}</span>
                  </div>
                  {clientInfo.brokerage === 'Yes' && (
                    <>
                      <Dropdown
                        label={pageContent.partnerLabel}
                        placeholder={pageContent.partnerPlaceholder}
                        options={PARTNERS_OPTIONS}
                        onChange={item => {
                          setClientInfo(prev => ({ ...prev, partner: item }));
                        }}
                        value={clientInfo.partner}
                        errorMessage={error.partner}
                      ></Dropdown>
                      {clientInfo.partner === 'Other' && (
                        <TextInput
                          label={pageContent.partnerNameLabel}
                          name='partnerName'
                          icon='action/user'
                          value={clientInfo.partnerName}
                          onChangeFn={handleChange}
                          clearFn={() => handleClear({ target: { name: 'partnerName' } })}
                          required
                          errorMessage={error.partnerName}
                        />
                      )}
                      <div ref={agentNameInputRef}>
                        <TextInput
                          label={pageContent.agentNameLabel}
                          name='agentName'
                          icon='action/user'
                          value={clientInfo.agentName}
                          onChangeFn={handleChange}
                          clearFn={() => handleClear({ target: { name: 'agentName' } })}
                          required
                          errorMessage={error.agentName}
                        />
                      </div>
                      <TextInput
                        label={pageContent.agentEmailLabel}
                        name='agentEmail'
                        icon='action/email'
                        value={clientInfo.agentEmail}
                        onChangeFn={handleChange}
                        clearFn={() => handleClear({ target: { name: 'agentEmail' } })}
                        required
                        errorMessage={error.agentEmail}
                      />
                      <InputPhone
                        name='agentPhone'
                        value={`${clientInfo.agentPhone}`}
                        onChangeFn={handleAgentPhone}
                        clearFn={() => handleClear({ target: { name: 'agentPhone' } })}
                        errorMessage={error.agentPhone}
                        translatedLabels={{
                          countryCode: pageContent.agentCountryCodeLabel,
                          phoneNumber: pageContent.agentPhoneNumberLabel,
                          optional: pageContent.optionalLabel
                        }}
                      />
                    </>
                  )}
                </div>
                <div className='client-data-footer'>
                  <div className='client-data-footer-list'>
                    <Checkbox
                      label={<>{renderDisclaimerContent(pageContent.disclaimerTermsPolicy)}</>}
                      onSelect={() => setClientInfo(prev => ({ ...prev, terms: true }))}
                      onDeselect={() => setClientInfo(prev => ({ ...prev, terms: false }))}
                      value={clientInfo.terms}
                      error={error.terms}
                    ></Checkbox>

                    <Checkbox
                      label={<>{renderDisclaimerContent(pageContent.disclaimerMarketing)}</>}
                      onSelect={() => setClientInfo(prev => ({ ...prev, marketing: true }))}
                      onDeselect={() => setClientInfo(prev => ({ ...prev, marketing: false }))}
                      value={clientInfo.marketing}
                    ></Checkbox>

                    <Checkbox
                      label={<>{renderDisclaimerContent(pageContent.disclaimerNewsletters)}</>}
                      onSelect={() => setClientInfo(prev => ({ ...prev, newsletter: true }))}
                      onDeselect={() => setClientInfo(prev => ({ ...prev, newsletter: false }))}
                      value={clientInfo.newsletter}
                    ></Checkbox>
                    <p>{pageContent.disclaimer}</p>
                  </div>
                  <div className='client-data-footer-button'>
                    <Button
                      primary
                      text={pageContent.buttonRegister}
                      onClickFn={handleRegister}
                      style={{ marginBottom: '30px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {registrationStep === 1 && <ClientOTPRequest setRegistrationStep={setRegistrationStep} />}
      {registrationStep === 2 && <VerifyCodePage setRegistrationStep={setRegistrationStep} />}
      {registrationStep === 3 && <EnterpriseSelection isRegisterFlow />}
    </>
  );
};

export default ClientRegistration;
