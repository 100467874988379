import DetailedCell from '@components/ui/Cells/DetailedCell';
import State from '@components/ui/State/State';
import useRender from '@hooks/useRender';
import useRedux from '@hooks/useRedux';
import { getArea } from '@utils/areas';

const TextWithImage = ({ type, field, item, renderCondition, controllerId, disabled }) => {
  const { PREFERENCES, getHouseTypes, getTracking } = useRedux();
  const isSelected = useRender().isElementSelected({ controllerId, item });
  const isRecommended =
    getTracking()?.recommendation?.houseType === item.id &&
    getTracking()?.recommendation?.typology === getTracking()?.nrRooms;
  const area = getArea(PREFERENCES.get().units, item.description);
  // get the house type label in the selected language
  const label = getHouseTypes()[PREFERENCES.get().language.code].find(houseType => houseType.id === item.id).label;

  return (
    <DetailedCell.Root
      field={field}
      item={item}
      renderCondition={renderCondition}
      isSelected={isSelected}
      disabled={disabled}
    >
      <div className='image-wrap'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {isRecommended ? (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <DetailedCell.Title>{label}</DetailedCell.Title>
              {!disabled && <State type='info' text='recommended' />}
            </div>
          ) : (
            <DetailedCell.Title>{label}</DetailedCell.Title>
          )}

          <DetailedCell.Row>
            <DetailedCell.Description regular>{area}</DetailedCell.Description>
          </DetailedCell.Row>
        </div>

        <DetailedCell.Image url={item.image.url} altText={item.image.name} />
      </div>
    </DetailedCell.Root>
  );
};

export default TextWithImage;
