import { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
// styles
import './InputPhone.scss';
// utils
import { SVG } from '@assets/svg';

const InputPhone = ({ name, value, onChangeFn, clearFn, required, errorMessage, translatedLabels }) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  return (
    <div className='phone-input-container'>
      <div className='phone-input-label-wrapper'>
        <label className='country-code-label'>{translatedLabels?.countryCode}</label>
        <label className='phone-label'>{translatedLabels?.phoneNumber}</label>
        {!required && <label className='optional-label'>{translatedLabels?.optional}</label>}
      </div>
      <div className='phone-input-wrapper'>
        <PhoneInput
          defaultCountry='pt'
          onChange={onChangeFn}
          value={`${value}`}
          required={required}
          inputClassName={`custom-phone-input${errorMessage ? ' error' : ''}`}
          countrySelectorStyleProps={{ buttonClassName: `custom-phone-button${errorMessage ? ' error' : ''}` }}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
        {isInputFocused && (
          <button type='button' className='phone-input-clear-icon' onMouseDown={clearFn}>
            <SVG icon='semantic/circle-x' />
          </button>
        )}
      </div>
      <span className='errorMessage'>{errorMessage}</span>
    </div>
  );
};

export default InputPhone;
