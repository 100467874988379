import { useState } from 'react';
// components
import Button from '@components/ui/Buttons/Button/Button';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import PreferencesButton from '@components/ui/Buttons/PreferencesButton/PreferencesButton';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './Preferences.scss';
//services
import { updatePreferences } from '@services/projects';
//utils
import { languageCode, currencyCode, unitsCode } from '@utils/preferences';
import { ACTIONS } from '@utils/enums';
// translations
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const Preferences = ({ onClose }) => {
  const { PREFERENCES, dispatchNotification, dispatchProjectInfo, getCurrentProject } = useRedux();
  const preferences = PREFERENCES.get();
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    Object.entries(languageCode).find(([_, value]) => value === preferences?.language?.code)?.[0]
  );
  const [selectedCurrency, setSelectedCurrency] = useState(preferences.currency);
  const [selectedUnits, setSelectedUnits] = useState(preferences.units);
  const [content] = useState(preferences.content[preferences?.language?.code]);
  const languageOptions = preferences.languageOptions;
  const { i18n } = useTranslation();

  const ProjectDefaultName = {
    pt: 'Projeto Torre',
    en: 'Project Torre'
  };

  const handleClick = async action => {
    if (action === ACTIONS.Back) {
      onClose();
    } else if (action === ACTIONS.Confirm) {
      try {
        //Update language and default project name to the correct language
        localStorage.setItem(
          'lang',
          JSON.stringify(
            PREFERENCES.get().languageOptions.find(option => option.code === languageCode?.[selectedLanguage])
          )
        );
        PREFERENCES.updateLanguage(
          PREFERENCES.get().languageOptions.find(option => option.code === languageCode?.[selectedLanguage])
        );
        i18n.changeLanguage(languageCode?.[selectedLanguage]);
        PREFERENCES.updateCurrency(selectedCurrency);
        PREFERENCES.updateUnits(selectedUnits);
        const currentDefaultName = getCurrentProject().configurationDefaultName;
        console.log('currentDefaultName', currentDefaultName);
        const match = currentDefaultName.match(/\d+$/);
        const numberOnDefaultName = match ? match[0] : null;
        const response = await updatePreferences(
          {
            projectId: getCurrentProject().id,
            preferredLanguage: languageCode?.[selectedLanguage],
            preferredCurrency: currencyCode?.[selectedCurrency],
            preferredUnits: unitsCode?.[selectedUnits],
            configurationDefaultName:
              selectedLanguage !== PREFERENCES.get().language.name.split(' ')[0] &&
              !getCurrentProject().configurationName
                ? `${ProjectDefaultName?.[languageCode?.[selectedLanguage]]} ${numberOnDefaultName}`
                : getCurrentProject().configurationDefaultName,
            configurationName: getCurrentProject().configurationName ?? null
          },
          Cookies.get('sessionId')
        );

        dispatchProjectInfo(response?.updatedProject);
      } catch (error) {
        console.error('Error updating preferences', error);
        dispatchNotification({
          timer: true,
          type: 'banner',
          status: 'error',
          title: t('preferences.error_updating_preferences')
        });
      }

      onClose();
    }
  };

  return (
    <>
      <div className='preferences-header'>
        <IconButton size={'small'} onImageBg={false} icon={'semantic/close'} onClickFn={onClose} />
      </div>
      <div className='preferences-content'>
        <span className='preferences-title'>{content.title}</span>
        <div className='preferences-section'>
          <span className='preferences-section-title'>{content.languageLabel}</span>
          <div className='preferences-section-content'>
            {languageOptions.map(option => {
              return (
                <PreferencesButton
                  text={option.name.split(' ')[0]}
                  option={option.name.split(' ')[0]}
                  selected={selectedLanguage}
                  onClickFn={() => setSelectedLanguage(option.name.split(' ')[0])}
                />
              );
            })}
          </div>
        </div>
        <div className='preferences-section'>
          <span className='preferences-section-title'>{content.currencyLabel}</span>
          <div className='preferences-section-content'>
            <PreferencesButton
              text={content.currencyEuro}
              option={preferences.content.en.currencyEuro}
              selected={selectedCurrency}
              onClickFn={() => setSelectedCurrency(preferences.content.en.currencyEuro)}
            />
            <PreferencesButton
              text={content.currencyDollar}
              option={preferences.content.en.currencyDollar}
              selected={selectedCurrency}
              onClickFn={() => setSelectedCurrency(preferences.content.en.currencyDollar)}
            />
          </div>
        </div>
        <div className='preferences-section'>
          <span className='preferences-section-title'>{content.unitsLabel}</span>
          <div className='preferences-section-content'>
            <PreferencesButton
              text={content.unitsMetric}
              option={preferences.content.en.unitsMetric}
              selected={selectedUnits}
              onClickFn={() => setSelectedUnits(preferences.content.en.unitsMetric)}
            />
            <PreferencesButton
              text={content.unitsImperial}
              option={preferences.content.en.unitsImperial}
              selected={selectedUnits}
              onClickFn={() => setSelectedUnits(preferences.content.en.unitsImperial)}
            />
          </div>
        </div>
      </div>
      <div className='preferences-footer'>
        {content?.actions.map(action => {
          const type = action.type.toLowerCase();
          if (type === 'primary') {
            return (
              <Button
                primary
                text={action.label}
                onClickFn={() => {
                  handleClick(action.action);
                }}
              ></Button>
            );
          } else if (type === 'secondary') {
            return (
              <Button
                secondary
                text={action.label}
                onClickFn={() => {
                  handleClick(action.action);
                }}
              ></Button>
            );
          }
        })}
      </div>
    </>
  );
};

export default Preferences;
