// components
import { SVG } from '@assets/svg';
//styles
import './PreferencesButton.scss';

const PreferencesButton = ({ text, option, selected, onClickFn }) => {
  return (
    <div className={`preferences-button ${selected === option ? 'selected' : ''}`} onClick={onClickFn}>
      <span>{text}</span>
      {selected === option && <SVG icon={'semantic/check'} />}
    </div>
  );
};

export default PreferencesButton;
