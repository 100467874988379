import { useState, useRef } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
// styles
import './ServicesPartnersModal.scss';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// hooks
import useRedux from '@hooks/useRedux';
import { useEffect } from 'react';
import { isEmpty } from '@utils/functions';

const ServicesPartnersModal = ({ partnerId, onClose }) => {
  const { getPartners, PREFERENCES } = useRedux();
  const partners = getPartners();
  const [partner, setPartner] = useState({});
  const swiperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (swiperRef.current && !swiperRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(partners)) setPartner(partners[partnerId]?.[PREFERENCES.get().language.code]);
  }, [partners]);

  useEffect(() => {
    if (!isEmpty(partners)) setPartner(partners[partnerId]?.[PREFERENCES.get().language.code]);
  }, [PREFERENCES.get().language]);

  return (
    <div ref={swiperRef}>
      <Swiper
        watchSlidesProgress={true} // enables to see next or previous slide
        slidesPerView={'auto'} // max os slides per view
        centeredSlides={true}
        spaceBetween={40}
        pagination={{
          clickable: true,
          dynamicBullets: false
        }}
        modules={[Navigation, Pagination]}
        className='partners-modal-swiper'
      >
        <SwiperSlide className='partners-modal-swiper-slide'>
          <div div className='services-partner'>
            <div className='services-partner-content'>
              <div className='partners-modal-text-header'>
                <IconButton
                  className='partners-modal-close'
                  size={'small'}
                  onImageBg={false}
                  icon={'semantic/close'}
                  onClickFn={onClose}
                />
              </div>
              <span className='services-partner-content-name'>{partner?.service_label}</span>
              <div className='services-partner-content-details'>
                <img src={partner?.image} alt='' />
                <div className='services-partner-content-details-service'>
                  {partner.service && (
                    <span className='services-partner-content-details-service-title'>{partner?.service}</span>
                  )}
                  <span className='services-partner-content-details-service-description'>{partner?.bodycopy}</span>
                </div>
              </div>
            </div>
            {/* {partner?.quote && (
            <div className='services-partner-quote'>
              <span>{partner?.quote}</span>
            </div>
          )} */}
            {partner?.project_images && partner?.projects_label !== null && (
              <div className='services-partner-projects'>
                <span>{partner?.projects_label}</span>
                <div className='services-partner-projects-images'>
                  {partner?.project_images?.map(image => {
                    return <img src={image} alt='' />;
                  })}
                </div>
              </div>
            )}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ServicesPartnersModal;
