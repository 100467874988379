import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const sendEmail = async (data, token) => {
  const HEADERS = await getHeadersToRequest({ SMToken: token });

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'email' })}`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);
  return response.data;
};
