import { useEffect, useRef, useState } from 'react';

const useAudio = (url, audioPlaying) => {
  let audio = useRef();
  const [playing, setPlaying] = useState(audioPlaying);

  useEffect(() => {
    audio.current = new Audio(url);
    audio.current.play();
  }, []);

  // Stop the audio when the component unmounts
  useEffect(() => {
    return () => {
      console.log('audio cleanup');
      audio.current.pause();
    };
  }, []);

  useEffect(() => {
    audio.current.addEventListener('ended', () => audio.current.play());
    return () => {
      audio.current.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [
    playing,
    () => {
      if (audio.current) audio.current.play();
    },
    () => {
      if (audio.current) audio.current.pause();
    }
  ];
};

export default useAudio;
