import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  journeyPrice: 0,
  filterValues: {
    minValue: 0,
    maxValue: 0,
    plotMinMax: []
  }
};

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setPrice: (state, action) => {
      state.journeyPrice = action.payload;
    },
    resetPrice: state => {
      state.journeyPrice = initialState.journeyPrice;
    },
    setFilterValues: (state, action) => {
      state.filterValues = action.payload;
    }
  }
});

const pricingAction = pricingSlice.actions;
export { pricingSlice, pricingAction };
