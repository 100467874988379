import { useState } from 'react';
// components
import DropdownOptions from '../Dropdown/DropdownOptions';
import { SVG } from '@assets/svg';
// styles
import './AudioPlayer.scss';

const Picker = ({ options, onChange, value, audioOn, toggleAudio }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    if (audioOn) {
      if (anchorEl) {
        setAnchorEl(null); // Close popper if it's open
      } else {
        setAnchorEl(event?.currentTarget); // Open popper if it's closed
      }
    }
  };
  const handleToggle = event => {
    event.stopPropagation();
    toggleAudio();
  };

  return (
    <>
      <div className='dropdown-content'>
        <div
          className={`dropdown-box dropdown-box--${open ? 'open' : 'close'} dropdown-box--${audioOn ? '' : 'muted'}`}
          onClick={handleClick}
        >
          <button onClick={handleToggle}>
            <SVG customCSS='audio-icon' icon={audioOn ? 'audio/on' : 'audio/off'} />
          </button>
          {audioOn && (
            <>
              <span className='dropdown-box-border' />
              {value}
            </>
          )}
        </div>
      </div>

      <DropdownOptions
        open={open}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        optionsToDisplay={options.map(option => (
          <span style={{ display: 'flex', gap: '8px' }}>
            <SVG customCSS='audio-icon' icon={`audio/${option}`} />
            {option}
          </span>
        ))}
        options={options}
        selection={value}
        setSelection={onChange}
        onChange={item => onChange(item)}
        handleClick={handleClick}
      />
    </>
  );
};

export default Picker;
