import './Cell.scss';
import useRender from '@hooks/useRender';

const Cell = ({ field, item, renderCondition, isSelected, children, disabled, onClickFn }) => {
  const { itemShouldRender } = useRender();
  return (
    <>
      {itemShouldRender(renderCondition, item) && (
        <div
          className={`cell${isSelected ? ' selected' : ''}${disabled ? ' disabled' : ''}`}
          onClick={() => {
            if (!disabled && onClickFn) {
              onClickFn();
            } else if (!disabled) {
              field.onChange(item.id);
            }
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export { Cell };
