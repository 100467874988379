import './State.scss';
import { SVG } from '@assets/svg';

const State = ({ type, text, icon }) => {
  // List of valid types
  const validTypes = ['success', 'info', 'warning', 'error'];

  // Check if the type is valid
  if (!validTypes.includes(type)) {
    console.error('Invalid type prop provided. Must be one of:', validTypes);
    return null;
  }

  return (
    <div className={`state ${type}`}>
      <span className='text'>{text}</span>
      {icon && <SVG icon={icon} />}
    </div>
  );
};

export default State;
