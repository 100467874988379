import { createSlice } from '@reduxjs/toolkit';

const registerRemoteSlice = createSlice({
  name: 'registerRemote',
  initialState: {},
  reducers: {
    setRegisterRemoteContent: (state, action) => {
      return { ...action.payload };
    }
  }
});

const registerRemoteAction = registerRemoteSlice.actions;
export { registerRemoteSlice, registerRemoteAction };
