import { createSlice } from '@reduxjs/toolkit';

const imagesSlice = createSlice({
  name: 'images',
  initialState: {
    current: '',
    vimeoID: null,
    svg: ''
  },
  reducers: {
    setCurrentImage: (state, action) => {
      state.current = action.payload.imageUrl;
    },
    setImage: (state, action) => {
      // console.log({ ...action.payload });
      const { hash, imageUrl, vimeoID } = action.payload;
      if (imageUrl && vimeoID) {
        // set current image from a new entry
        state.current = imageUrl;
        state.vimeoID = vimeoID;
        state[hash] = { image: imageUrl, vimeoID: vimeoID };
      } else if (imageUrl) {
        state.current = imageUrl;
        state.vimeoID = null;
        state[hash] = { image: imageUrl };
      } else if (vimeoID) {
        state.vimeoID = vimeoID;
        state.current = '';
        state[hash] = { vimeoID: vimeoID };
      } else {
        // set current image from a preloaded entry
        state.current = state[hash]?.image;
        state.vimeoID = state[hash]?.vimeoID;
      }
    },
    setSVG: (state, action) => {
      state.svg = action.payload.svg;
    }
  }
});

const imagesAction = imagesSlice.actions;
export { imagesSlice, imagesAction };
