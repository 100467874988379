import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const login = async credentials => {
  const HEADERS = {
    accept: '*/*'
  };
  const requestObj = {
    method: 'post',
    url: getFullEndpoint({ microservice: 'salesmanager', service: 'login' }),
    headers: HEADERS,
    data: credentials
  };

  const response = await axios.request(requestObj);
  // const response = await axios.request(requestObj).catch(error => handleError(error));
  return response;
};

export const getSalesmanagerInfoFromBO = async (salesmanagerEmail, isAdmin, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const url = isAdmin
    ? `${getFullEndpoint({ microservice: 'salesmanager' })}/BOInfo?isAdmin=true`
    : `${getFullEndpoint({ microservice: 'salesmanager' })}/BOInfo?username=${salesmanagerEmail}`;

  const requestObj = {
    method: 'get',
    url,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);
  return response?.data;
};
