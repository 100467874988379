import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const updateCollections = async (file, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'post',
    url: getFullEndpoint({ microservice: 'strapi', service: 'updateCollections' }),
    headers: HEADERS,
    data: file
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));
  // const response = await axios.request(requestObj).catch(error => handleError(error));
  return response.data;
};
