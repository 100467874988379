import { partnersAction } from './partners-slice';
import { getPartners } from '@services/partners';

export const setPartners = async (dispatch, SMToken) => {
  try {
    const response = await getPartners(SMToken);
    dispatch(partnersAction.setPartners(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
