// styles
import './Tag.scss';

const Tag = ({ label, selected, onClickFn, status }) => {
  const TagClass = `
    ${selected && selected === label ? 'tag-selected' : 'tag'}${status ? `-${status}` : ''}
  `;

  return (
    <span className={`${TagClass.trim()}`} onClick={onClickFn}>
      {label}
    </span>
  );
};

export default Tag;
