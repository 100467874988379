import axios from 'axios';
import { getFullEndpoint } from '../../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const getProjectSubmittedContent = async SMToken => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: getFullEndpoint({ microservice: 'projectSubmitted' }),
    headers: HEADERS
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));

  return response.data;
};
