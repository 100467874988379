import './PriceCard.scss';
// hooks
import useRedux from '@hooks/useRedux';

const PriceCard = ({ title, description, price }) => {
  const { PRICING } = useRedux();
  const pricing = PRICING.get().journeyPrice;

  return (
    <div className='price-card'>
      <div className='price-card-header'>
        <span className='price-card-header-title'>{title}</span>
        <span className='price-card-header-price'>{price ?? pricing}</span>
      </div>
      <span className='price-card-description'>{description}</span>
    </div>
  );
};

export default PriceCard;
