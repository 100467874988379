import { modulesAction } from './modules-slice';
import { getModules } from '@services/modules';

export const setModules = async (dispatch, SMToken) => {
  try {
    const response = await getModules(SMToken);
    dispatch(modulesAction.setModules(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
