import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const getPricing = async (formState, currency, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'post',
    url: getFullEndpoint({ microservice: 'pricing' }),
    headers: HEADERS,
    data: { formState: formState, currency: currency }
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));
  return response.data;
};

export const getFiltersValuesMinAndMax = async SMToken => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'pricing', service: 'filterValues' })}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));
  return response.data;
};
