// hooks
import useRedux from '@hooks/useRedux';
import { useNavigate } from 'react-router-dom';
// components
import Button from '@components/ui/Buttons/Button/Button';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import PriceCard from '@components/ui/Cards/PriceCard/PriceCard';
// services
import { createRequest, getClientDetails, updateRequest } from '@services/client';
import { updateProject, createProject } from '@services/projects';
import { sendEmail } from '@services/emails';
import Cookies from 'js-cookie';
// utils
import { ACTIONS, DIALOG_TYPE } from '@utils/enums';
import { getArea } from '@utils/areas';
import { CONFIG_STATUS, CONFIG_FLOW } from '@utils/enums';
import { isEmpty } from '@utils/functions';
// styles
import './Dialog.scss';
//hooks
import { useFormContext } from 'react-hook-form';
//translations
import { useTranslation } from 'react-i18next';
//env
import { FRONTEND_URL } from '../../../../config';

const Dialog = ({
  type,
  id,
  projectName,
  onClose,
  setCustomFormStep,
  setProjectSubmittedPageOpen,
  updateStatusProps
}) => {
  const {
    getModals,
    dispatchSetDialog,
    dispatchResetForm,
    dispatchNotification,
    dispatchProjectInfo,
    getCurrentProject,
    dispatchResetTracking,
    FORMSTATE,
    dispatchResetFilters,
    getHouseTypes,
    getHouses,
    PREFERENCES,
    getTracking,
    getPlots,
    getIsAuthenticated,
    dispatchRemoteInformationPage
  } = useRedux();
  const navigate = useNavigate();
  const dialog = getModals()[id]?.[PREFERENCES.get().language.code].content[0];
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_token = Cookies.get('sessionId');
  const sales_manager_id = Cookies.get('salesManagerId');
  const houseTypesInfo = getHouseTypes()[PREFERENCES.get().language.code];
  const taxConversion = PREFERENCES.get()?.content?.[PREFERENCES.get().language.code]?.EUR_TO_USD;

  const formContext = useFormContext();
  const watch = formContext?.watch; // Evita erro caso useFormContext() seja null
  let watcher = null;

  if (formContext && id !== DIALOG_TYPE.StatusChange) {
    watcher = watch();
  }

  const { t } = useTranslation();
  const units = PREFERENCES.get().units;
  const authenticatedUser = getIsAuthenticated();
  const status = getCurrentProject()?.status;
  const configurationFlow = getCurrentProject()?.configurationFlow;
  // status variables
  const isDraft = status === CONFIG_STATUS.Draft;
  const isActive = status === CONFIG_STATUS.Active;
  const isInactive = status === CONFIG_STATUS.Inactive;
  const isSubmitted = status === CONFIG_STATUS.Submitted;
  // flow variables
  const isGuided = configurationFlow === CONFIG_FLOW.Guided;
  const isRemote = configurationFlow === CONFIG_FLOW.Remote;
  const lang = PREFERENCES.get().language.code;
  // area calculations
  const house = getHouses()?.find(house => house?.id === watcher?.layout?.houseLayout);
  const layoutArea = house?.area && house?.area > 0 ? getArea(units, house?.area) : null;
  let totalArea;
  let constructionArea;
  if (!isEmpty(getPlots())) {
    totalArea = getArea(units, getPlots()?.find(plot => plot.id === watcher?.plot?.plot)?.area) ?? null;
    constructionArea =
      getArea(units, getPlots()?.find(plot => plot.id === watcher?.plot?.plot)?.areaOfConstruction) ?? null;
  }

  const todayDate = new Date();
  // remaining remote versions after saving a new child project
  const remainingCount = 3 - (parseInt(getCurrentProject()?.remoteVersions) + 1 || 1);

  const getType = type => {
    return type === 'image' ? 'dialog-image' : 'dialog';
  };

  //function to call SF and add new project to DB
  // const createRequestNewProject = async () => {
  //   await createRequest(
  //     {
  //       clientEmail: getCurrentProject()?.client,
  //       salesManagerEmail: sales_manager,
  //       salesManagerId: sales_manager_id,
  //       projectDefaultLanguage: PREFERENCES?.get()?.language?.code
  //     },
  //     sales_manager_token
  //   )
  //     .then(async response => {
  //       if (response) {
  //         console.log('Project saved to DB', response);
  //         dispatchProjectInfo(response.projectInfo);
  //         navigate(`/journey/${response.projectInfo.id}`);
  //       }
  //     })
  //     .catch(error => {
  //       console.log('Error saving project do DB', error);
  //       dispatchNotification({
  //         timer: true,
  //         status: 'error',
  //         title: t('projects.error_saving_project_title'),
  //         description: t('projects.error_saving_project_description')
  //       });
  //       navigate('/');
  //     });
  // };

  //function to call SF and add new project to DB that is a copy from the current project
  // this is not a child, it will be a brand new configuration but with the values copied
  const createMasterProject = async status => {
    const clientSFInfo = await getClientDetails(getCurrentProject()?.client, sales_manager_token, sales_manager_id);
    const response = await createRequest(
      {
        clientEmail: getCurrentProject()?.client,
        salesManagerEmail: sales_manager,
        salesManagerId: sales_manager_id,
        clientDetailsFromSF: clientSFInfo.data,
        projectDefaultLanguage: PREFERENCES?.get()?.language?.code,
        projectPrefix: getCurrentProject()?.project,
        totalProjectData: {
          form: watcher,
          plot: watcher?.plot?.plot ?? null,
          layoutSize: layoutArea,
          totalSize: totalArea,
          grossArea: constructionArea,
          layout: getHouseTypes()[lang]?.find(el => el.id === watcher?.layout?.houseType)?.id,
          project: getCurrentProject()?.project,
          client: getCurrentProject()?.client,
          status: status ?? CONFIG_STATUS.Active,
          configurationDefaultName: getCurrentProject()?.configurationDefaultName,
          configurationName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
          sales_manager_id: getCurrentProject()?.sales_manager_id,
          sales_manager: getCurrentProject()?.sales_manager,
          projectLanguage: PREFERENCES.get().language.code,
          projectCurrency: PREFERENCES.get().currency,
          projectUnits: PREFERENCES.get().units,
          configurationFlow: CONFIG_FLOW.Guided,
          lastActiveDate: new Date(todayDate.setHours(0, 0, 0, 0)),
          parent: null
        }
      },
      sales_manager_token
    );

    return response;
  };

  //function to update the project that is being edited or viewed
  const updateCurrentProject = async (status, flow) => {
    const updateResponse = await updateProject(
      {
        form: watcher,
        plot: watcher?.plot?.plot ?? null,
        layoutSize: layoutArea,
        totalSize: totalArea,
        grossArea: constructionArea,
        layout: getHouseTypes()[lang]?.find(el => el.id === watcher?.layout?.houseType)?.id ?? null,
        configurationName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
        projectLanguage: PREFERENCES.get().language.code,
        projectCurrency: PREFERENCES.get().currency,
        projectUnits: PREFERENCES.get().units,
        status: status ?? undefined,
        lastActiveDate:
          isDraft && status === CONFIG_STATUS.Active ? new Date(todayDate.setHours(0, 0, 0, 0)) : undefined,
        configurationFlow: flow ?? undefined
      },
      getCurrentProject()?.id,
      sales_manager_token
    );

    console.log('Project saved to DB', updateResponse);
  };

  // functio to create a new configuration that is child of the current project
  // this happens when a user saves a project that is active
  // or when submits a project that has changes on the answers
  const createChildProject = async status => {
    const response = await createProject(
      {
        form: watcher,
        plot: watcher?.plot?.plot ?? null,
        layoutSize: layoutArea,
        totalSize: totalArea,
        grossArea: constructionArea,
        layout: getHouseTypes()[lang]?.find(el => el.id === watcher?.layout?.houseType)?.id,
        project: getCurrentProject()?.project,
        client: getCurrentProject()?.client,
        status: status ?? CONFIG_STATUS.Inactive,
        configurationDefaultName: getCurrentProject()?.configurationDefaultName,
        configurationName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
        sales_manager_id: getCurrentProject()?.sales_manager_id,
        sales_manager: getCurrentProject()?.sales_manager,
        projectLanguage: PREFERENCES.get().language.code,
        projectCurrency: PREFERENCES.get().currency,
        projectUnits: PREFERENCES.get().units,
        configurationFlow: authenticatedUser?.type === 'Client' ? CONFIG_FLOW.Remote : CONFIG_FLOW.Guided,
        parent: authenticatedUser?.type === 'Client' ? getCurrentProject()?.id : null,
        lastActiveDate: authenticatedUser?.type === 'SM' ? new Date(todayDate.setHours(0, 0, 0, 0)) : null
      },
      getCurrentProject()?.client,
      sales_manager_token
    );

    return response;
  };

  const dialogClass = `${getType(type)}`;

  const handleClick = async action => {
    dispatchSetDialog({ open: false, type: '', id: '', title: '' });
    // if (action === ACTIONS.NewDesign) {
    //   dispatchResetForm({ reset: true, trigger: 'RESET_STEP_PLOT' });
    //   FORMSTATE.reset();
    //   dispatchResetFilters();
    //   setCustomFormStep(0);
    //   await createRequestNewProject();
    // }
    if (action === ACTIONS.Exit) {
      if (id === DIALOG_TYPE.SaveExit) {
        try {
          onClose();
          await updateCurrentProject();
          navigate('/');
          dispatchNotification({
            timer: true,
            status: 'success',
            title: t('projects.save_exit_project_success')
          });
        } catch (error) {
          console.log('Error saving project', error);
          navigate('/');
          dispatchNotification({
            timer: true,
            status: 'error',
            title: t('projects.error_saving_project_title'),
            description: t('projects.error_saving_project_description')
          });
        }
      } else if (id === DIALOG_TYPE.SaveProject || id === DIALOG_TYPE.SaveProjectClient) {
        try {
          onClose();

          //if the project is draft, update its status
          if (isDraft && authenticatedUser?.type === 'SM') {
            await updateCurrentProject(CONFIG_STATUS.Active, CONFIG_FLOW.Guided);
            const emailPayload = {
              to: getCurrentProject()?.clientId.email,
              cc: getCurrentProject()?.sales_manager.email ?? null,
              identifier: 'project-submitted',
              projectName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
              bespokeId: getCurrentProject()?.id,
              salesManagerId: getCurrentProject()?.sales_manager_id,
              actionURL: `${FRONTEND_URL}/verifyEmailRemote?h=${getCurrentProject()?.projectHash}`,
              lang: lang
            };

            if (getTracking().currentStepId !== 'plot') sendEmail(emailPayload);
            navigate(`/dashboard?clientId=${getCurrentProject()?.clientId.id}`);
            dispatchNotification({
              timer: true,
              status: 'success',
              title: t('projects.saving_project_success')
            });
          }
          //if the project is active, create a new child configuration
          else if (isActive) {
            if (authenticatedUser?.type === 'SM') {
              createMasterProject()
                .then(async response => {
                  if (response) {
                    const emailPayload = {
                      to: getCurrentProject()?.clientId.email,
                      cc: getCurrentProject()?.sales_manager.email ?? null,
                      identifier: 'project-submitted',
                      projectName: response.configurationName,
                      bespokeId: response.projectInfo?.id,
                      salesManagerId: getCurrentProject()?.sales_manager_id,
                      actionURL: `${FRONTEND_URL}/verifyEmailRemote?h=${response.projectHash}`,
                      lang: lang
                    };

                    if (getTracking().currentStepId !== 'plot') sendEmail(emailPayload);
                    console.log('Project saved to DB', response);
                    navigate(`/dashboard?clientId=${getCurrentProject()?.clientId.id}`);
                    dispatchNotification({
                      timer: true,
                      status: 'success',
                      title: t('projects.saving_project_success')
                    });
                  }
                })
                .catch(error => {
                  console.log('Error saving project do DB', error);
                  navigate('/');
                  dispatchNotification({
                    timer: true,
                    status: 'error',
                    title: t('projects.error_saving_project_title'),
                    description: t('projects.error_saving_project_description')
                  });
                });
            } else {
              const createChildResponse = await createChildProject();

              const emailInternalPayload = {
                to: getCurrentProject()?.sales_manager.email ?? null,
                cc: null,
                identifier: 'project-saved-internal',
                bespokeId: createChildResponse?.id,
                bespokeIdMaster: getCurrentProject()?.id,
                clientName: getCurrentProject()?.clientId.name,
                clientEmail: getCurrentProject()?.clientId.email,
                clientPhone: getCurrentProject()?.clientId.phone,
                lang: lang
              };
              const emailPayload = {
                to: getCurrentProject()?.clientId.email,
                cc: getCurrentProject()?.sales_manager.email ?? null,
                identifier: 'project-submitted',
                projectName: createChildResponse?.configurationName,
                bespokeId: createChildResponse?.id,
                salesManagerId: getCurrentProject()?.sales_manager_id,
                actionURL: `${FRONTEND_URL}/verifyEmailRemote?h=${createChildResponse.projectHash}`,
                lang: lang
              };

              if (getTracking().currentStepId !== 'plot') sendEmail(emailPayload);
              if (getTracking().currentStepId !== 'plot') sendEmail(emailInternalPayload);

              // if user is client, increase the remote versions on the parent, and change its status if the remote versions
              // are equal to 3
              const remoteVersions = parseInt(getCurrentProject()?.remoteVersions) || 0;

              await updateProject(
                {
                  remoteVersions: remoteVersions + 1,
                  status: remoteVersions + 1 < 3 ? CONFIG_STATUS.Active : CONFIG_STATUS.Inactive
                },
                getCurrentProject()?.id,
                sales_manager_token
              );

              dispatchRemoteInformationPage({
                status: 'registerRemote',
                pageInformation: { ...createChildResponse, remainingCount: 3 - (remoteVersions + 1) }
              });
            }
          }
        } catch (error) {
          console.log('Error saving project', error);
          navigate(`/dashboard?clientId=${getCurrentProject()?.clientId.id}`);
          dispatchNotification({
            timer: true,
            status: 'error',
            title: t('projects.error_saving_project_title'),
            description: t('projects.error_saving_project_description')
          });
        }
      } else {
        PREFERENCES.reset();
        dispatchResetTracking();
        navigate(`/dashboard?clientId=${getCurrentProject()?.clientId.id}`);
      }
      FORMSTATE.reset();
      dispatchResetFilters();
    }

    if (action === ACTIONS.Confirm) {
      try {
        onClose();
        const newStatus = updateStatusProps?.newStatus;
        const projectId = updateStatusProps?.projectId;
        const todayDate = new Date();
        await updateProject(
          {
            status: newStatus,
            lastActiveDate: newStatus === 'active' ? new Date(todayDate.setHours(0, 0, 0, 0)) : undefined,
            remoteVersions: newStatus === 'active' ? 0 : undefined
          },
          projectId,
          sales_manager_token
        );

        updateStatusProps.setUnfilteredProjects(prevProjects =>
          prevProjects.map(project =>
            project.bespokeId?.value === projectId
              ? { ...project, status: { ...project.status, value: newStatus } }
              : project
          )
        );
      } catch (error) {
        console.error('Error updating project status:', error);
        dispatchNotification({
          status: 'error',
          timer: true,
          title: 'Error updating project status'
        });
      }
    }
    if (action === ACTIONS.Back) {
      onClose();
    }
    if (action === ACTIONS.ConfirmInterest) {
      try {
        if (!getTracking()?.dialog?.isPremium) {
          onClose();
          // if sales manager is authenticated and is draft
          // or if is client and is active
          // or is inactive
          // we update the current project to submitted
          if (
            (authenticatedUser?.type === 'SM' && isDraft) ||
            isInactive ||
            (authenticatedUser?.type === 'Client' && isActive && isRemote)
          ) {
            await updateCurrentProject(CONFIG_STATUS.Submitted);

            const emailPayload = {
              to: getCurrentProject()?.clientId.email,
              cc: getCurrentProject()?.sales_manager.email ?? null,
              identifier: 'project-submitted',
              projectName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
              bespokeId: getCurrentProject()?.id,
              salesManagerId: getCurrentProject()?.sales_manager_id,
              actionURL: `${FRONTEND_URL}/verifyEmailRemote?h=${getCurrentProject()?.projectHash}`,
              lang: lang
            };
            const emailInternalPayload = {
              to: getCurrentProject()?.sales_manager.email ?? null,
              cc: null,
              identifier: 'project-submitted-internal',
              bespokeId: getCurrentProject()?.id,
              clientName: getCurrentProject()?.clientId.name,
              clientEmail: getCurrentProject()?.clientId.email,
              clientPhone: getCurrentProject()?.clientId.phone,
              plot: watcher?.plot?.plot ?? null,
              lang: lang
            };
            if (getTracking().currentStepId !== 'plot') sendEmail(emailInternalPayload);
            if (getTracking().currentStepId !== 'plot') sendEmail(emailPayload);

            if (authenticatedUser?.type === 'Client') {
              dispatchRemoteInformationPage({
                status: 'submissionRemote',
                pageInformation: getCurrentProject()
              });
            } else {
              setProjectSubmittedPageOpen(true);
              dispatchNotification({
                timer: true,
                status: 'success',
                title: t('projects.submit_project_success')
              });
            }
            // if sales manager is authenticated and is active
            // or is client and is active and guided
            // we check if the form has changed
            // if it didnt, we submit the current project
            // if it did, we create a new child configuration
          } else if (
            (authenticatedUser?.type === 'SM' && isActive) ||
            (authenticatedUser?.type === 'Client' && isActive && isGuided)
          ) {
            const hasAnswersChanged = JSON.stringify(watcher) !== JSON.stringify(getCurrentProject()?.form);

            if (!hasAnswersChanged) {
              await updateCurrentProject(CONFIG_STATUS.Submitted);

              const emailPayload = {
                to: getCurrentProject()?.clientId.email,
                cc: getCurrentProject()?.sales_manager.email ?? null,
                identifier: 'project-submitted',
                projectName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
                bespokeId: getCurrentProject()?.id,
                salesManagerId: getCurrentProject()?.sales_manager_id,
                actionURL: `${FRONTEND_URL}/verifyEmailRemote?h=${getCurrentProject()?.projectHash}`,
                lang: lang
              };
              const emailInternalPayload = {
                to: getCurrentProject()?.sales_manager.email ?? null,
                cc: null,
                identifier: 'project-submitted-internal',
                bespokeId: getCurrentProject()?.id,
                clientName: getCurrentProject()?.clientId.name,
                clientEmail: getCurrentProject()?.clientId.email,
                clientPhone: getCurrentProject()?.clientId.phone,
                plot: watcher?.plot?.plot ?? null,
                lang: lang
              };
              if (getTracking().currentStepId !== 'plot') sendEmail(emailInternalPayload);
              if (getTracking().currentStepId !== 'plot') sendEmail(emailPayload);

              if (authenticatedUser?.type === 'Client') {
                dispatchRemoteInformationPage({
                  status: 'submissionRemote',
                  pageInformation: getCurrentProject()
                });
              } else {
                setProjectSubmittedPageOpen(true);
                dispatchNotification({
                  timer: true,
                  status: 'success',
                  title: t('projects.submit_project_success')
                });
              }
            } else {
              if (authenticatedUser?.type === 'SM') {
                createMasterProject(CONFIG_STATUS.Submitted)
                  .then(async response => {
                    if (response) {
                      console.log('Project saved to DB', response);
                      const emailPayload = {
                        to: getCurrentProject()?.clientId.email,
                        cc: getCurrentProject()?.sales_manager.email ?? null,
                        identifier: 'project-submitted',
                        projectName: response.configurationName,
                        bespokeId: response.id,
                        salesManagerId: getCurrentProject()?.sales_manager_id,
                        actionURL: `${FRONTEND_URL}/verifyEmailRemote?h=${response.projectHash}`,
                        lang: lang
                      };

                      if (getTracking().currentStepId !== 'plot') sendEmail(emailPayload);

                      setProjectSubmittedPageOpen(true);
                      dispatchRemoteInformationPage({
                        status: 'submissionSM',
                        pageInformation: { ...response }
                      });
                      dispatchNotification({
                        timer: true,
                        status: 'success',
                        title: t('projects.submit_project_success')
                      });
                    }
                  })
                  .catch(error => {
                    console.log('Error saving project do DB', error);
                    dispatchNotification({
                      timer: true,
                      status: 'error',
                      title: t('projects.error_saving_project_title'),
                      description: t('projects.error_saving_project_description')
                    });
                    navigate('/');
                  });
              } else {
                const createChildResponse = await createChildProject(CONFIG_STATUS.Submitted);

                const emailPayload = {
                  to: getCurrentProject()?.clientId.email,
                  cc: getCurrentProject()?.sales_manager.email ?? null,
                  identifier: 'project-submitted',
                  projectName: createChildResponse.configurationName,
                  bespokeId: createChildResponse.id,
                  salesManagerId: getCurrentProject()?.sales_manager_id,
                  actionURL: `${FRONTEND_URL}/verifyEmailRemote?h=${createChildResponse.projectHash}`,
                  lang: lang
                };
                const emailInternalPayload = {
                  to: getCurrentProject()?.sales_manager.email ?? null,
                  cc: null,
                  identifier: 'project-submitted-internal',
                  bespokeId: createChildResponse?.id,
                  clientName: getCurrentProject()?.clientId.name,
                  clientEmail: getCurrentProject()?.clientId.email,
                  clientPhone: getCurrentProject()?.clientId.phone,
                  plot: watcher?.plot?.plot ?? null,
                  lang: lang
                };

                if (getTracking().currentStepId !== 'plot') sendEmail(emailPayload);
                if (getTracking().currentStepId !== 'plot') sendEmail(emailInternalPayload);

                const remoteVersions = parseInt(getCurrentProject()?.remoteVersions) || 0;

                await updateProject(
                  {
                    remoteVersions: remoteVersions + 1,
                    status: remoteVersions + 1 < 3 ? CONFIG_STATUS.Active : CONFIG_STATUS.Inactive
                  },
                  getCurrentProject()?.id,
                  sales_manager_token
                );

                dispatchRemoteInformationPage({
                  status: 'submissionRemote',
                  pageInformation: { ...createChildResponse }
                });
              }
            }
          }
        } else {
          await updateProject(
            {
              status: CONFIG_STATUS.Submitted,
              isPremium: true
            },
            getCurrentProject()?.id,
            sales_manager_token
          );

          await updateRequest(
            {
              requestId: getCurrentProject().requestId,
              payloadToUpdate: {
                Notes__c: `A configuração ${getCurrentProject()?.id} foi feita num lote premium.`
              }
            },
            sales_manager_token
          );

          onClose();
          setProjectSubmittedPageOpen(true);
        }
      } catch (error) {
        console.log('Error saving project', error);
        navigate('/');
        dispatchNotification({
          timer: true,
          status: 'error',
          title: t('projects.error_submitting_project_title'),
          description: t('projects.error_submitting_project_description')
        });
      }
    }
    return;
  };

  return (
    <div className={dialogClass.trim()}>
      {type === 'image' && (
        <div className='image-wrapper'>
          <img src={houseTypesInfo?.find(el => el?.id === watcher?.layout?.houseType)?.image} alt='' />
        </div>
      )}
      <div className={`${dialogClass.trim()}-content`}>
        <div className='dialog-header'>
          <div className='dialog-header-text'>
            <div className='dialog-header-text-left'>
              <span className='title'>
                {dialog?.title
                  ?.replace('{{projectName}}', projectName)
                  .replace('{{status}}', updateStatusProps?.newStatus)}
              </span>
            </div>
            <IconButton
              className='close'
              size={'small'}
              onImageBg={false}
              icon={'semantic/close'}
              onClickFn={onClose}
            />
          </div>
          <span className='subtitle'>{dialog?.description?.replace('{{remainingCount}}', remainingCount)}</span>
        </div>
        {type === 'image' && (
          <PriceCard
            title={dialog?.card[0]?.infoCardTitle}
            description={dialog?.card[0]?.infoCardDescription?.replace('{{ EUR_TO_USD }}', taxConversion)}
          ></PriceCard>
        )}
        <div className='dialog-footer'>
          {dialog?.actions.map(action => {
            const type = action.type.toLowerCase();
            if (type === 'primary') {
              return (
                <Button
                  primary
                  text={action.label.replace('{{status}}', updateStatusProps?.newStatus)}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'primary-destructive') {
              return (
                <Button
                  primary
                  destructive
                  text={action.label.replace('{{status}}', updateStatusProps?.newStatus)}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'secondary') {
              return (
                <Button
                  secondary
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'ghost') {
              return (
                <Button
                  ghost
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Dialog;
