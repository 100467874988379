import Stepper from '../../ui/Stepper/Stepper';
import './PanelHeader.scss';

const PanelHeader = ({ formState, stepsNames, stepsIds, currentStepId, currentStepNr, onStepClick }) => {
  return (
    <div className='form-header'>
      <Stepper
        formState={formState}
        stepsNames={stepsNames}
        stepsIds={stepsIds}
        currentStepId={currentStepId}
        currentStepNr={currentStepNr}
        onStepClick={onStepClick}
      />
    </div>
  );
};

export default PanelHeader;
