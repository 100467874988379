import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState =
  !!Cookies.get('sessionId') && !!Cookies.get('salesManager') ? { type: 'SM', isAdmin: false } : null;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      return action.payload;
    }
  }
});

const authAction = authSlice.actions;
export { authSlice, authAction };
