import { SVG } from '@assets/svg';
import './Dropdown.scss';
import { useEffect, useRef } from 'react';
import MenuItem from '../MenuItem/MenuItem';
import { Popper } from '@mui/base/Popper';

const useClickOutside = (ref, handler, exceptionRef) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !(exceptionRef?.current && exceptionRef.current.contains(event.target))
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, exceptionRef]);
};

const DropdownOptions = ({
  options,
  optionsToDisplay,
  open,
  selection,
  setSelection,
  onChange,
  handleClick,
  anchorEl,
  setAnchorEl
}) => {
  const id = open ? 'simple-popper' : undefined;
  const popperRef = useRef(null); // Reference to the popper content
  const buttonRef = useRef(null); // Reference to the toggle button

  // Use the custom hook to close the popper when clicking outside, except for the toggle button
  useClickOutside(popperRef, () => setAnchorEl(null), buttonRef);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement='bottom'
      style={{ zIndex: 9999, width: anchorEl?.clientWidth }}
    >
      <div className='dropdown-menu' ref={popperRef}>
        {options.map((item, index) => {
          return (
            <MenuItem
              text={optionsToDisplay ? optionsToDisplay[index] : item}
              icon={'semantic/check'}
              selected={optionsToDisplay ? selection === optionsToDisplay[index] : selection === item}
              onClickFn={() => {
                setSelection(optionsToDisplay ? optionsToDisplay[index] : item);
                onChange(item);
                handleClick();
              }}
            />
          );
        })}
      </div>
    </Popper>
  );
};

export default DropdownOptions;
