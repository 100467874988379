import React from 'react';

// action
import { ReactComponent as GRID } from './icons/action/grid.svg';
import { ReactComponent as FILTER } from './icons/action/filter.svg';
import { ReactComponent as BOOK_OPEN } from './icons/action/book-open.svg';
import { ReactComponent as ROTATE_CCW } from './icons/action/rotate-ccw.svg';
import { ReactComponent as TAG } from './icons/action/tag.svg';
import { ReactComponent as USER } from './icons/action/user.svg';
import { ReactComponent as KEY } from './icons/action/key.svg';
import { ReactComponent as EYE } from './icons/action/eye.svg';
import { ReactComponent as EYE_OFF } from './icons/action/eye-off.svg';
import { ReactComponent as GLOBE } from './icons/action/globe.svg';
import { ReactComponent as EMAIL } from './icons/action/email.svg';
import { ReactComponent as SEARCH } from './icons/action/search.svg';
import { ReactComponent as LOCK_OUTLINE } from './icons/action/lock-outline.svg';
import { ReactComponent as BOX } from './icons/action/box.svg';
import { ReactComponent as MAP } from './icons/action/map.svg';
import { ReactComponent as UPLOAD } from './icons/action/upload.svg';
import { ReactComponent as FILE } from './icons/action/file-text.svg';
import { ReactComponent as SHIELD } from './icons/action/shield.svg';

// navigation
import { ReactComponent as COLLAPSE } from './icons/navigation/collapse.svg';
import { ReactComponent as EXPAND } from './icons/navigation/expand.svg';
import { ReactComponent as CHEVRON_DOWN } from './icons/navigation/chevron-down.svg';
import { ReactComponent as CHEVRON_UP } from './icons/navigation/chevron-up.svg';
import { ReactComponent as ARROW_LEFT } from './icons/navigation/arrow-left.svg';
import { ReactComponent as ARROW_RIGHT } from './icons/navigation/arrow-right.svg';
import { ReactComponent as CIRCLE_ARROW_RIGHT } from './icons/navigation/circle-arrow-right.svg';

// semantic
import { ReactComponent as CHECK_CIRCLE_FILLED } from './icons/semantic/check-circle-filled.svg';
import { ReactComponent as CHECK_CIRCLE } from './icons/semantic/check-circle.svg';
import { ReactComponent as CHECK } from './icons/semantic/check.svg';
import { ReactComponent as CLOSE } from './icons/semantic/close.svg';
import { ReactComponent as CIRCLE_X } from './icons/semantic/circle-x.svg';
import { ReactComponent as INFO_OUTLINE } from './icons/semantic/info-outline.svg';
import { ReactComponent as INFO_FILLED } from './icons/semantic/info-filled.svg';
import { ReactComponent as OCTAGON_X_FILLED } from './icons/semantic/octagon-x-filled.svg';
import { ReactComponent as OCTAGON_X_OUTLINE } from './icons/semantic/octagon-x-outline.svg';
import { ReactComponent as PLUS } from './icons/semantic/plus.svg';
import { ReactComponent as MINUS } from './icons/semantic/minus.svg';
import { ReactComponent as CROWN } from './icons/semantic/crown.svg';

// logo
import { ReactComponent as BESPOKELIVING } from './icons/logos/bespokeliving.svg';
import { ReactComponent as BESPOKELIVINGWHITE } from './icons/logos/bespokeliving_white.svg';
import { ReactComponent as BESPOKESMALL } from './icons/logos/bespokesmall.svg';
import { ReactComponent as BESPOKESMALLWHITE } from './icons/logos/bespokesmall_white.svg';
import { ReactComponent as COMPASS } from './icons/logos/compass.svg';
import { ReactComponent as BESPOKELIVINGGREY } from './icons/logos/bespokeliving_grey.svg';

// audios
import { ReactComponent as AUDIO_ON } from './icons/audio/audioOn.svg';
import { ReactComponent as AUDIO_OFF } from './icons/audio/audioOff.svg';
import { ReactComponent as AUDIO_AMBIENT } from './icons/audio/ambientSound.svg';
import { ReactComponent as AUDIO_MUSIC } from './icons/audio/musicSound.svg';
import { ReactComponent as PLAY } from './icons/audio/play.svg';
import { ReactComponent as PAUSE } from './icons/audio/pause.svg';

const SVG_MAPPING = {
  //action
  'action/grid': GRID,
  'action/filter': FILTER,
  'action/book-open': BOOK_OPEN,
  'action/rotate-ccw': ROTATE_CCW,
  'action/tag': TAG,
  'action/user': USER,
  'action/key': KEY,
  'action/eye': EYE,
  'action/eye-off': EYE_OFF,
  'action/globe': GLOBE,
  'action/email': EMAIL,
  'action/search': SEARCH,
  'action/lock-outline': LOCK_OUTLINE,
  'action/box': BOX,
  'action/map': MAP,
  'action/upload': UPLOAD,
  'action/file': FILE,
  'action/shield': SHIELD,
  // navigation
  'navigation/collapse': COLLAPSE,
  'navigation/expand': EXPAND,
  'navigation/chevron-down': CHEVRON_DOWN,
  'navigation/chevron-up': CHEVRON_UP,
  'navigation/arrow-left': ARROW_LEFT,
  'navigation/arrow-right': ARROW_RIGHT,
  'navigation/circle-arrow-right': CIRCLE_ARROW_RIGHT,
  // semantic
  'semantic/check-circle-filled': CHECK_CIRCLE_FILLED,
  'semantic/check-circle': CHECK_CIRCLE,
  'semantic/check': CHECK,
  'semantic/close': CLOSE,
  'semantic/circle-x': CIRCLE_X,
  'semantic/info-outline': INFO_OUTLINE,
  'semantic/info-filled': INFO_FILLED,
  'semantic/octagon-x-filled': OCTAGON_X_FILLED,
  'semantic/octagon-x-outline': OCTAGON_X_OUTLINE,
  'semantic/plus': PLUS,
  'semantic/minus': MINUS,
  'semantic/crown': CROWN,
  // logo
  'logo/bespokeliving': BESPOKELIVING,
  'logo/bespokeliving_white': BESPOKELIVINGWHITE,
  'logo/bespokesmall': BESPOKESMALL,
  'logo/bespokesmall_white': BESPOKESMALLWHITE,
  'logo/compass': COMPASS,
  'logo/bespokeliving_grey': BESPOKELIVINGGREY,
  // audio
  'audio/on': AUDIO_ON,
  'audio/off': AUDIO_OFF,
  'audio/ambient': AUDIO_AMBIENT,
  'audio/music': AUDIO_MUSIC,
  'audio/play': PLAY,
  'audio/pause': PAUSE
};

const SVG = ({ icon, customCSS }) => {
  // Check if the icon name exists in the mapping
  if (SVG_MAPPING[icon.toLowerCase()]) {
    const IconComponent = SVG_MAPPING[icon.toLowerCase()];
    return <IconComponent className={customCSS} />;
  } else {
    // Handle case where icon name does not match any imported SVG
    return null; // or you can render a default icon or an error message
  }
};

export { SVG };
