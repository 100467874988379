import { useEffect, useState } from 'react';
// components
import Player from './Player';
import Picker from './Picker';
// services
import { fetchAudios } from '@services/audios';
// styles
import './AudioPlayer.scss';
import Cookies from 'js-cookie';

const AudioPlayer = () => {
  const [audios, setAudios] = useState([]);
  const [audioPlaying, setAudioPlaying] = useState(true);
  const [audioType, setAudioType] = useState();

  useEffect(() => {
    getAudios();
  }, []);

  const getAudios = async () => {
    try {
      const audioData = await fetchAudios(Cookies.get('sessionId'));
      const sortAudioDate = audioData.sort((a, b) => a.id.localeCompare(b.id));
      setAudios(sortAudioDate);
      setAudioType(sortAudioDate[0].id);
    } catch (error) {
      console.error('Error getting audios', error);
    }
  };

  return (
    <div className='audio-player-container'>
      {audios?.length > 0 && (
        <>
          {audios.map(audio => (
            <Player audio={audio} audioType={audioType} audioPlaying={audioPlaying} setAudioPlaying={setAudioPlaying} />
          ))}
          <Picker
            options={audios.map(audio => audio.id)}
            onChange={val => setAudioType(val)}
            value={audioType}
            audioOn={audioPlaying}
            toggleAudio={() => setAudioPlaying(!audioPlaying)}
          />
        </>
      )}
    </div>
  );
};

export default AudioPlayer;
