import { useEffect, useState } from 'react';
import useRedux from '@hooks/useRedux';
// components
import Checkbox from '@components/ui/Checkbox/Checkbox';
// styles
import './ProfileCell.scss';
// translations
import { useTranslation } from 'react-i18next';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';

const ProfileCell = ({ profile, onSelect, onDeselect, isProfileSelected, openServicePartner, error }) => {
  const { getServicesContent, PREFERENCES } = useRedux();
  const [selected, setSelected] = useState(false);
  const { t } = useTranslation();
  const serviceCode = getServicesContent()?.en?.find(item => item?.attributes?.label === profile?.service)?.attributes
    ?.identifier;
  const translatedService = getServicesContent()?.[PREFERENCES?.get()?.language?.code]?.find(
    item => item.attributes.identifier === serviceCode
  )?.attributes?.label;
  useEffect(() => {
    setSelected(isProfileSelected);
  }, [isProfileSelected]);

  return (
    <div className={selected ? 'profile-cell selected' : 'profile-cell'}>
      <div className='profile-cell-header'>
        <img src={profile.image} alt='' />
        <div className='profile-cell-content'>
          <div className='profile-cell-text'>
            <span className='profile-cell-text-name'>{profile?.name}</span>
            <span className='profile-cell-text-jobTitle'>{translatedService}</span>
          </div>
          <LinkButton
            text={t('multi_choice_modal.know_more')}
            onClickFn={() => openServicePartner(profile?.id)}
          ></LinkButton>
        </div>
      </div>
      <div className='profile-cell-right'>
        <Checkbox
          error={!!error}
          onSelect={() => {
            setSelected(!selected);
            onSelect();
          }}
          onDeselect={() => {
            setSelected(!selected);
            onDeselect();
          }}
          value={selected}
        ></Checkbox>
      </div>
    </div>
  );
};

export default ProfileCell;
