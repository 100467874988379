import { packsAction } from './packs-slice';
import { getPacks } from '@services/packs';

export const setPacks = async (dispatch, SMToken) => {
  try {
    const response = await getPacks(SMToken);
    dispatch(packsAction.setPacks(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
