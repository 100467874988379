import React from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useRedux from '@hooks/useRedux';

const ProtectedRouteRemote = ({ element: Component }) => {
  const { getIsAuthenticated } = useRedux();
  const authenticatedUser = getIsAuthenticated();

  return !!authenticatedUser ? Component : <Navigate to='/sales-manager/login' />;
};

export default ProtectedRouteRemote;
