import axios from 'axios';
import { getFullEndpoint } from '../../utils/endpoints/functions';

export const getHomepageContent = async () => {
  const HEADERS = {
    accept: '*/*'
  };
  const requestObj = {
    method: 'get',
    url: getFullEndpoint({ microservice: 'homepage' }),
    headers: HEADERS
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));

  return response.data;
};
