import { SVG } from '@assets/svg';
import './FilterButton.scss';
import { useTranslation } from 'react-i18next';

const FilterButton = ({ filtersCount, label, onClickFn }) => {
  const { t } = useTranslation();
  return (
    <div className={`filter-button${filtersCount > 0 ? ' active' : ''}`} onClick={onClickFn}>
      <SVG icon={'action/filter'} />
      <p>{label ?? t('filters.filters_button_label')}</p>
      {filtersCount > 0 && (
        <div className='active'>
          <p>{filtersCount}</p>
        </div>
      )}
    </div>
  );
};

export default FilterButton;
