import ReactMarkdown from 'react-markdown';
// hooks
import useRedux from '@hooks/useRedux';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import Button from '@components/ui/Buttons/Button/Button';
import PriceCard from '@components/ui/Cards/PriceCard/PriceCard';
// styles
import './PremiumModal.scss';
// utils
import { ACTIONS, DIALOG_TYPE, MODAL_TYPE } from '@utils/enums';

const PremiumModal = ({ id, plot, onClose }) => {
  const { getPlots, getModals, dispatchSetDialog, PREFERENCES } = useRedux();
  const content = getModals()[MODAL_TYPE.PremiumModal]?.[PREFERENCES.get().language.code].content;
  const taxConversion = PREFERENCES.get()?.content?.[PREFERENCES.get().language.code]?.EUR_TO_USD;
  let price = getPlots().find(p => p.id === plot)?.price;
  if (PREFERENCES.get().currency === 'USD') {
    price = `$ ${price.toLocaleString('en-EN', { maximumFractionDigits: 0 })}`;
  } else {
    price = `€ ${price.toLocaleString('de-DE', { maximumFractionDigits: 0 })}`;
  }
  const paragraphs = content?.descriptions.split(/\n\n/);

  const handleClick = action => {
    dispatchSetDialog({ open: false, type: '', id: '' });
    if (action === ACTIONS.Back) {
      onClose();
    } else if (action === ACTIONS.ConfirmInterest) {
      dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SubmitProject, isPremium: true });
    }
  };

  return (
    <div className='premium-dialog'>
      <div className='image-wrapper'>
        <img src={content.image} alt='' />
      </div>
      <div className='premium-dialog-content'>
        <div className='premium-dialog-header'>
          <div className='premium-dialog-header-text'>
            <div className='premium-dialog-header-text-left'>
              <span className='title'>{content?.title}</span>
            </div>
            <IconButton
              className='close'
              size={'small'}
              onImageBg={false}
              icon={'semantic/close'}
              onClickFn={onClose}
            />
          </div>
          <div className='description-markdown'>
            {paragraphs.map((paragraph, index) => {
              return (
                <div key={index}>
                  <ReactMarkdown>{paragraph.replace(/\n/g, '  \n')}</ReactMarkdown>
                  {index < paragraphs.length - 1 && <br />}
                </div>
              );
            })}
          </div>
        </div>
        {price && (
          <PriceCard
            title={content?.card?.title}
            description={content?.card?.description.replace('{{ EUR_TO_USD }}', taxConversion)}
            price={price}
          ></PriceCard>
        )}
        <div className='premium-dialog-footer'>
          {content?.actions.map(action => {
            const type = action.type.toLowerCase();
            if (type === 'primary') {
              return (
                <Button
                  primary
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'secondary') {
              return (
                <Button
                  secondary
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default PremiumModal;
