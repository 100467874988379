import ReactMarkdown from 'react-markdown';
// hooks
import useRedux from '@hooks/useRedux';
// components
import { SVG } from '@assets/svg';
// styles
import './UnsupportedDevice.scss';

const UnsupportedDevice = ({ small }) => {
  const { UNSUPPORTEDDEVICE, PREFERENCES } = useRedux();
  const content = UNSUPPORTEDDEVICE?.get()[PREFERENCES?.get()?.language?.code];

  const paragraphs = content?.description.split(/\n\n/);

  return (
    <div className={`unsupported-device-container${small ? '-small' : ''}`}>
      <div className='background-image-container'>
        <div className='overlay'></div>
        <img src={content?.background} className='background-image' />
      </div>
      <div className='content-wrapper'>
        <div className='logo'>
          <SVG icon='logo/bespokeliving_white' />
        </div>
        <div className='content-section'>
          <span className='title'>{content?.title}</span>
          <div className='bodycopy'>
            {paragraphs?.map((paragraph, index) => {
              return (
                <div key={index}>
                  <ReactMarkdown>{paragraph?.replace(/\n/g, '  \n')}</ReactMarkdown>
                  {index < paragraphs?.length - 1 && <br />}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsupportedDevice;
