import { useEffect, useState } from 'react';
// components
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Accordion from '@components/ui/Accordion/Accordion';
import Portal from '@components/ui/Overlays/Portal/Portal';
import Dialog from '@components/ui/Overlays/Dialog/Dialog';
import PriceCard from '@components/ui/Cards/PriceCard/PriceCard';
// hooks
import { useFormContext } from 'react-hook-form';
import useRedux from '@hooks/useRedux';
import useRender from '@hooks/useRender';
import useModals from '@hooks/useModals';
// styles
import './review.scss';
// utils
import { getArea, getLayoutDetails, getSizeLabel } from '@utils/areas';
import { CONFIG_STATUS } from '@utils/enums';
// translations
import { useTranslation } from 'react-i18next';
//services
import Cookies from 'js-cookie';

const convertIdToLabel = id => {
  const versionMatch = id.match(/v(\d+)/);
  if (versionMatch) {
    const versionNumber = parseInt(versionMatch[1], 10);
    return `Layout ${versionNumber}`;
  }
};

const Review = ({ formState, journeyObj, setCustomFormStep, setProjectSubmittedPageOpen, children }) => {
  const { questions } = journeyObj;
  const {
    getPlots,
    getMacroplots,
    getHouseTypes,
    getHouses,
    getPacks,
    getTracking,
    getForms,
    dispatchSetDialog,
    getCurrentProject,
    dispatchProjectInfo,
    dispatchUpdateTracking,
    dispatchSetRecommendation,
    dispatchInteriorsAreas,
    getIsAuthenticated,
    FORMSTATE,
    PRICING,
    PREFERENCES
  } = useRedux();
  const { checkIsDisabled } = useRender();
  const { t } = useTranslation();
  const plots = getPlots();
  const macroplots = getMacroplots();
  const packs = getPacks();
  const pools = getForms().journey?.[PREFERENCES?.get()?.language?.code]?.steps[2].questions[1].answers;
  const parking = getForms().journey?.[PREFERENCES?.get()?.language?.code]?.steps[2].questions[2].answers;
  const nrRooms = getForms().journey?.[PREFERENCES?.get()?.language?.code]?.steps[1].questions[0].answers[0].items;
  const currency = PREFERENCES.get().currency;
  const taxConversion = PREFERENCES.get()?.content?.[PREFERENCES.get().language.code]?.EUR_TO_USD;
  const [mastersuites, setMastersuites] = useState([]);
  const [suites, setSuites] = useState([]);
  const [socialAreas, setSocialAreas] = useState([]);
  const [appliances, setAppliances] = useState([]);
  const units = PREFERENCES.get().units;
  const lang = PREFERENCES.get().language.code;
  const status = getCurrentProject()?.status;
  const startReviewStep = getTracking()?.startReviewStep;
  const [isToRender, setIsToRender] = useState(startReviewStep ? false : true);

  // status variables
  const isDraft = status === CONFIG_STATUS.Draft;
  const isActive = status === CONFIG_STATUS.Active;
  const isInactive = status === CONFIG_STATUS.Inactive;
  const isSubmitted = status === CONFIG_STATUS.Submitted;

  const projectOwner = getCurrentProject()?.sales_manager_id;
  const authenticatedUser = getIsAuthenticated();

  const sales_manager_id = Cookies.get('salesManagerId');

  const isProjectOwner = authenticatedUser?.type === 'Client' || projectOwner === sales_manager_id;

  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();

  const house = getHouses().find(house => house.id === watcher?.layout?.houseLayout);

  const [projectName, setProjectName] = useState(
    getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName
  );
  const [isModalOpen, setModalOpen] = useModals();
  // open and close modal
  const toggleModal = () => setModalOpen(!isModalOpen);

  useEffect(() => {
    if (startReviewStep) {
      //If project information comes from DB and first step rendered is review, do the dispatches
      dispatchSetRecommendation(getPlots().find(plot => plot.id === watcher?.plot?.plot)?.recommendation);
      dispatchUpdateTracking({ idToUpdate: 'nrRooms', updatedObject: watcher?.layout?.nrRooms });
      dispatchUpdateTracking({ idToUpdate: 'houseType', updatedObject: watcher?.layout?.houseType });
      dispatchUpdateTracking({ idToUpdate: 'houseName', updatedObject: watcher?.layout?.houseLayout });
      dispatchInteriorsAreas(watcher?.layout?.houseLayout ?? undefined);
      FORMSTATE.updateAll({ ...watcher });
      // dispatchUpdateTracking({
      //   idToUpdate: 'startReviewStep',
      //   updatedObject: false
      // });
    }
  }, []);

  // Update pricing when currency changes
  useEffect(() => {
    PRICING.update({ formState, currency });
  }, [currency]);

  const handleChange = e => {
    setProjectName(e.target.value);
    const currentProjectInfo = structuredClone(getCurrentProject());
    currentProjectInfo.configurationName = e.target.value;
    dispatchProjectInfo(currentProjectInfo);
  };

  if (!FORMSTATE.get('stepsFinished').includes('review')) FORMSTATE.dispatchUpdateStepFinished('review');

  useEffect(() => {
    if (getTracking()?.interiorsAreas) {
      Object.keys(getTracking().interiorsAreas).forEach(key => {
        if (key === 'intimate-areas') {
          setMastersuites(getTracking()?.interiorsAreas[key]?.mastersuites);
          setSuites(getTracking()?.interiorsAreas[key]?.suites);
        } else if (key === 'social-areas') {
          setSocialAreas(getTracking()?.interiorsAreas[key]);
        } else if (key === 'kitchen') {
          setAppliances(getTracking()?.interiorsAreas[key]?.kitchens[0]?.appliances?.appliances);
        }
      });
      setIsToRender(true);
    }
  }, [getTracking()?.interiorsAreas]);

  const getLayout = () => {
    const details = getLayoutDetails(house.modules?.combinations, units);
    return details;
  };

  const getIntimateAreas = () => {
    let intimateAreas = [];
    let counter = 0;
    if (watcher?.interiors?.intimateAreas?.mastersuite && mastersuites)
      Object.keys(watcher?.interiors.intimateAreas.mastersuite).forEach(key => {
        intimateAreas.push({
          label: mastersuites[counter]?.suite?.title,
          value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.mastersuite[key].suite)[0].label
        });
        counter++;
      });
    counter = 0;
    if (watcher?.interiors?.intimateAreas?.suites && suites)
      Object.keys(watcher?.interiors?.intimateAreas?.suites).forEach(key => {
        intimateAreas.push({
          label: suites[counter]?.suite?.title,
          value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.suites[key].suite)[0].label
        });
        counter++;
      });
    return intimateAreas;
  };

  const getBathrooms = () => {
    let bathrooms = [];
    let counter = 0;
    bathrooms.push({
      label: socialAreas?.filter(area => area.type === 'WC')[0]?.label,
      value: packs.filter(pack => pack.id === watcher?.interiors.socialAreas.bathroom)[0].label
    });
    if (watcher?.interiors?.intimateAreas?.mastersuite && mastersuites)
      Object.keys(watcher?.interiors.intimateAreas.mastersuite).forEach(key => {
        bathrooms.push({
          label: mastersuites[counter]?.bathroom?.title,
          value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.mastersuite[key].bathroom)[0].label
        });
        counter++;
      });
    counter = 0;
    if (watcher?.interiors?.intimateAreas?.suites && suites)
      Object.keys(watcher?.interiors.intimateAreas.suites).forEach(key => {
        bathrooms.push({
          label: suites[counter]?.bathroom?.title,
          value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.suites[key].bathroom)[0].label
        });
        counter++;
      });
    return bathrooms;
  };

  return (
    <>
      {isToRender && (
        <div className='review'>
          <div className='top-section'>
            <span className='headline'>{questions[0].headline}</span>
            {questions[0].description && <span className='description'>{questions[0].description}</span>}
          </div>
          <TextInput
            label={questions[0].nameInput_label}
            icon='action/tag'
            placeholder={projectName}
            value={projectName}
            clearFn={() => {
              setProjectName('');
              dispatchProjectInfo({ ...getCurrentProject(), configurationName: null });
            }}
            onChangeFn={e => {
              if (isDraft || isActive) {
                handleChange(e);
              }
            }}
            lock={isInactive || isSubmitted || !isProjectOwner}
          />
          <div className='review__container'>
            <span>{questions[0].section_label}</span>
            <div className='review__step-container'>
              <div className='review__step-container-header'>
                <span className='review__step-container-title'>{questions[0].stepPlot_title}</span>
                {!checkIsDisabled('plot') && (
                  <LinkButton
                    text={t('review.edit_button')}
                    onClickFn={() => {
                      setCustomFormStep(0); // TODO dinâmico
                    }}
                  ></LinkButton>
                )}
              </div>
              <div className='review__step-container-line'>
                <span>{questions[0].stepPlot_macroplot}</span>
                <span>{macroplots.filter(macroplot => macroplot.id === watcher?.plot.macroplot)[0].label}</span>
              </div>
              <div className='review__step-container-line'>
                <span>{questions[0].stepPlot_plot}</span>
                <span>
                  {plots.filter(plot => plot.id === watcher?.plot.plot)[0].label} -{' '}
                  {getArea(units, plots.filter(plot => plot.id === watcher?.plot.plot)[0].area)}
                </span>
              </div>
              <div className='review__step-container-line'>
                <span>{questions[0].stepPlot_area}</span>
                <span>
                  {getArea(units, plots.filter(plot => plot.id === watcher?.plot.plot)[0].areaOfConstruction)}
                </span>
              </div>
            </div>
            <div className='review__step-container'>
              <div className='review__step-container-header'>
                <span className='review__step-container-title'>{questions[0].stepLayout_title}</span>
                {!checkIsDisabled('layout') && (
                  <LinkButton
                    text={t('review.edit_button')}
                    onClickFn={() => {
                      setCustomFormStep(1); // TODO dinâmico
                    }}
                  ></LinkButton>
                )}
              </div>
              <div className='review__step-container-line'>
                <span>{questions[0].stepLayout_houseType}</span>
                <span>
                  {getHouseTypes()[lang].filter(houseType => houseType.id === watcher?.layout.houseType)[0].label}
                </span>
              </div>
              <div className='review__step-container-line'>
                <span>{questions[0].stepLayout_bedrooms}</span>
                <span>{nrRooms.filter(room => room.id === watcher?.layout.nrRooms)[0].label}</span>
              </div>
              <Accordion
                label={convertIdToLabel(watcher?.layout.houseLayout)}
                description={getArea(units, house?.area)}
                content={getLayout().layout}
              ></Accordion>
            </div>
            <div className='review__step-container'>
              <div className='review__step-container-header'>
                <span className='review__step-container-title'>{questions[0].stepExteriors_title}</span>
                {!checkIsDisabled('exteriors') && (
                  <LinkButton
                    text={t('review.edit_button')}
                    onClickFn={() => {
                      setCustomFormStep(2); // TODO dinâmico
                    }}
                  ></LinkButton>
                )}
              </div>
              {watcher?.exteriors.pool.poolSize === 'none' ? (
                <div className='review__step-container-line'>
                  <span>{questions[0].stepExteriors_pool}</span>
                  <span>{t('review.pool_none')}</span>
                </div>
              ) : (
                <Accordion
                  label={questions[0].stepExteriors_pool}
                  content={[
                    {
                      label: questions[0].stepExteriors_pool_size,
                      value: getSizeLabel(
                        pools[0].items.find(item => item.id === watcher?.exteriors.pool.poolSize).width,
                        pools[0].items.find(item => item.id === watcher?.exteriors.pool.poolSize).length,
                        units,
                        t('journey.pool_none')
                      )
                    },
                    {
                      label: questions[0].stepExteriors_pool_coating,
                      value: getPacks().find(pack => pack.id === watcher?.exteriors.pool.poolCovering).label
                    },
                    {
                      label: questions[0].stepExteriors_pool_heated,
                      value: pools[2].items.find(item => item.id === watcher?.exteriors.pool.poolHeated).label
                    }
                  ]}
                ></Accordion>
              )}
              <div className='review__step-container-line'>
                <span>{questions[0].stepExteriors_parking}</span>
                <span>
                  {parking[0].items.filter(item => item.id === watcher?.exteriors.parking.parkingType)[0].label} -{' '}
                  {parking[1].items.filter(item => item.id === watcher?.exteriors.parking.parkingSpace)[0].label}
                </span>
              </div>
            </div>
            <div className='review__step-container'>
              <div className='review__step-container-header'>
                <span className='review__step-container-title'>{questions[0].stepInteriors_title}</span>
                {!checkIsDisabled('interiors') && (
                  <LinkButton
                    text={t('review.edit_button')}
                    onClickFn={() => {
                      setCustomFormStep(3); // TODO dinâmico
                    }}
                  ></LinkButton>
                )}
              </div>
              <Accordion
                label={questions[0].stepInteriors_social}
                content={[
                  {
                    label: questions[0].stepInteriors_social_theme,
                    value: packs.filter(pack => pack.id === watcher?.interiors.socialAreas.livingRoom)[0].label
                  }
                ]}
              />
              <Accordion
                label={questions[0].stepInteriors_kitchen}
                content={[
                  {
                    label: questions[0].stepInteriors_kitchen_theme,
                    value: packs.filter(pack => pack.id === watcher?.interiors.kitchen.kitchen.kitchen)?.[0]?.label
                  },
                  {
                    label: questions[0].stepInteriors_kitchen_appliances,
                    value: appliances.filter(
                      appliance => appliance.id === watcher?.interiors.kitchen.kitchen.appliance
                    )?.[0]?.label
                  }
                ]}
              />
              <Accordion label={questions[0].stepInteriors_intimate} content={getIntimateAreas()} />
              <Accordion label={questions[0].stepInteriors_bathrooms} content={getBathrooms()} />
            </div>
          </div>
          <PriceCard
            title={questions[0].card.title}
            description={questions[0].card.description?.replace('{{ EUR_TO_USD }}', taxConversion)}
          ></PriceCard>

          {isModalOpen && (
            <Portal>
              <Dialog
                type={getTracking().dialog.type}
                id={getTracking().dialog.id}
                projectName={projectName}
                onClose={() => {
                  toggleModal();
                  dispatchSetDialog({ open: false, type: '', id: '' });
                }}
                setCustomFormStep={setCustomFormStep}
                setProjectSubmittedPageOpen={setProjectSubmittedPageOpen}
              ></Dialog>
            </Portal>
          )}
        </div>
      )}
    </>
  );
};

export default Review;
