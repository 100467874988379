import { useState, useEffect } from 'react';
import validator from 'validator';
// components
import Header from '@components/ui/Header/Header';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import RadioButton from '@components/ui/RadioButton/RadioButton';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Button from '@components/ui/Buttons/Button/Button';
import InputPhone from '@components/ui/Inputs/PhoneInput/InputPhone';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './ClientOTPRequest.scss';
import { isEmpty, isValidEmail } from '@utils/functions';
//services
import { sendVerificationCode } from '@services/otp';
// translations
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const ClientOTPRequest = ({ setRegistrationStep }) => {
  const { getClient, dispatchClient, PREFERENCES, getForms } = useRedux();
  const lang = PREFERENCES.get()?.language?.code;
  const pageContent = getForms()?.otp?.[lang]?.type;
  const { t } = useTranslation();

  const [goToNextPage, setGoToNextPage] = useState(false);

  const [error, setError] = useState({});
  const [contactMethod, setContactMethod] = useState('email');
  const [clientInfo, setClientInfo] = useState({
    email: getClient().email,
    phone: getClient().phone,
    whatsapp: getClient().phone
  });
  const contactMethodsList = [
    {
      label: t('otp.requestPage.email_label'),
      value: 'email'
    },
    { label: t('otp.requestPage.phone_label'), value: 'phone' },
    { label: t('otp.requestPage.whatsapp_label'), value: 'whatsapp' }
  ];

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target;
    setClientInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleClear = e => {
    setClientInfo(prev => ({ ...prev, [e.target.name]: '' }));
  };

  useEffect(() => {
    if (goToNextPage && isEmpty(error)) {
      sendVerificationCodeFunction();
      setRegistrationStep(2);
    }
    setGoToNextPage(false);
  }, [goToNextPage, error]);

  const handleSend = () => {
    setError({});
    if (contactMethod === 'email' && isEmpty(clientInfo?.email))
      setError(prev => ({ ...prev, email: t('otp.requestPage.empty_email') }));
    if (
      (contactMethod === 'phone' || contactMethod === 'whatsapp') &&
      !isEmpty(clientInfo?.phone) &&
      !validator.isMobilePhone(clientInfo.phone)
    )
      setError(prev => ({ ...prev, phone: t('otp.requestPage.invalid_phone_number') }));
    if (contactMethod === 'email' && !isEmpty(clientInfo?.email) && !isValidEmail(clientInfo?.email))
      setError(prev => ({ ...prev, email: t('otp.requestPage.invalid_email') }));

    setGoToNextPage(true);
  };

  useEffect(() => {
    if (!isEmpty(clientInfo?.phone)) setError(prev => ({ ...prev, phone: '' }));
    if (!isEmpty(clientInfo?.email)) setError(prev => ({ ...prev, email: '' }));
  }, [clientInfo]);

  const sendVerificationCodeFunction = async () => {
    if (contactMethod === 'email') {
      try {
        const updateClient = { ...getClient(), otpContactService: { contact: clientInfo?.email, method: 'email' } };
        dispatchClient(updateClient);
        await sendVerificationCode(clientInfo?.email, 'email', Cookies.get('sessionId'));
      } catch (error) {
        console.log('Error sending verification code', error);
      }
    } else if (contactMethod === 'phone') {
      try {
        const updateClient = { ...getClient(), otpContactService: { contact: clientInfo?.phone, method: 'sms' } };
        dispatchClient(updateClient);
        await sendVerificationCode(clientInfo?.phone, 'sms', Cookies.get('sessionId'));
      } catch (error) {
        console.log('Error sending verification code', error);
      }
    } else if (contactMethod === 'whatsapp') {
      try {
        const updateClient = { ...getClient(), otpContactService: { contact: clientInfo?.phone, method: 'whatsapp' } };
        dispatchClient(updateClient);
        await sendVerificationCode(clientInfo?.phone, 'whatsapp', Cookies.get('sessionId'));
      } catch (error) {
        console.log('Error sending verification code', error);
      }
    }
  };

  return (
    pageContent && (
      <>
        <div className='client-otp-request-container'>
          <Header shouldDisplay isDark />
          <div className='client-otp-request-wrapper'>
            <img src={pageContent.image} className='client-otp-request-wrapper-background-image' />
            <div className='client-otp-request-content-wrapper'>
              <div className='client-otp-request-content'>
                <div className='back-button'>
                  <LinkButton
                    icon='navigation/arrow-left'
                    text={pageContent.buttonBack}
                    onClickFn={() => setRegistrationStep(0)}
                  />
                </div>
                <div className='client-otp-request-content-header'>
                  <span className='client-otp-request-content-header-title'>{pageContent.title}</span>
                  <span className='client-otp-request-content-header-description'>{pageContent.subtitle}</span>
                </div>
                <div className='client-otp-request-content-body'>
                  <div className='service'>
                    <span className='service-title'>{pageContent.preferredServiceLabel}</span>
                    <div className='service-list'>
                      {contactMethodsList.map((obj, index) => {
                        return (
                          <RadioButton
                            name='Contact Method'
                            id={obj.value}
                            label={obj.label}
                            onSelect={() => setContactMethod(obj.value)}
                            value={contactMethod}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {contactMethod === 'email' && (
                    <TextInput
                      label={pageContent.emailLabel}
                      name='email'
                      icon='action/email'
                      value={clientInfo.email}
                      onChangeFn={handleChange}
                      clearFn={() => handleClear({ target: { name: 'email' } })}
                      required
                      errorMessage={error.email}
                    />
                  )}
                  {contactMethod === 'phone' && (
                    <InputPhone
                      name='phone'
                      value={`${clientInfo.phone}`}
                      onChangeFn={item => {
                        setClientInfo(prev => ({ ...prev, phone: item }));
                      }}
                      clearFn={() => handleClear({ target: { name: 'phone' } })}
                      required
                      errorMessage={error.phone}
                      translatedLabels={{
                        countryCode: pageContent.countrycodeLabel,
                        phoneNumber: pageContent.phoneLabel,
                        optional: 'Optional'
                      }}
                    />
                  )}
                  {contactMethod === 'whatsapp' && (
                    <InputPhone
                      name='Whatsapp'
                      value={`${clientInfo.phone}`}
                      onChangeFn={item => {
                        setClientInfo(prev => ({ ...prev, phone: item }));
                      }}
                      clearFn={() => handleClear({ target: { name: 'phone' } })}
                      required
                      errorMessage={error.phone}
                      translatedLabels={{
                        countryCode: pageContent.countrycodeLabel,
                        phoneNumber: pageContent.phoneLabel,
                        optional: 'Optional'
                      }}
                    />
                  )}
                </div>
                <div className='client-otp-request-content-footer'>
                  <div className='client-otp-request-content-footer-button'>
                    <Button
                      primary
                      text={pageContent.actions[0].label}
                      onClickFn={() => {
                        handleSend();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ClientOTPRequest;
