import { enterpriseAction } from './enterprise-slice';
import { getEnterpriseContent } from '@services/singlePages/enterprise';

export const setEnterpriseContent = async dispatch => {
  try {
    const response = await getEnterpriseContent(true, 'small');
    dispatch(enterpriseAction.setEnterpriseContent(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
