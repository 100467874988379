import { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
//components
import Header from '@components/ui/Header/Header';
import { SVG } from '@assets/svg';
import DashboardProjectsComponent from './DashboardProjectsComponent';
import FilterButton from '@components/ui/Buttons/FilterButton/FilterButton';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import MenuItem from '@components/ui/MenuItem/MenuItem';
import { Popper } from '@mui/base/Popper';
import Button from '@components/ui/Buttons/Button/Button';
//components
import Portal from '@components/ui/Overlays/Portal/Portal';
import Dialog from '@components/ui/Overlays/Dialog/Dialog';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import Checkbox from '@components/ui/Checkbox/Checkbox';
import EnterpriseSelection from '@pages/EnterpriseSelection/EnterPriseSelection';
//styles
import './dashboard.scss';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
//hooks
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
import { useSearchParams } from 'react-router-dom';
import useModals from '@hooks/useModals';
import { useTranslation } from 'react-i18next';
//services
import { getProjectsBySalesManagerAndProject } from '@services/projects';
import { getAllClients, createRequest, getClientDetails } from '@services/client';
import { isEmpty } from '@utils/functions';
import { getSalesmanagerInfoFromBO } from '@services/sales-manager';
//utils
import { CONFIG_STATUS } from '@utils/enums';

const Dashboard = () => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useModals();
  const toggleModal = () => setModalOpen(!isModalOpen);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const [projectToUpdateStatus, setProjectToUpdateStatus] = useState(null);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedClientObj, setSelectedClientObj] = useState(null);
  const [clientSearch, setClientSearch] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [formattedClients, setFormattedClients] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [unfilteredProjects, setUnfilteredProjects] = useState([]);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [goToNewDesign, setGoToNewDesign] = useState(false);
  const [filtersHandler, setFiltersHandler] = useState({
    SMAnchorEl: null,
    SMPopperRef: useRef(null),
    SMInitialList: [],
    SMList: [],
    SMsearch: '',
    bespokeAnchorEl: null,
    bespokePopperRef: useRef(null),
    bespokeInitialList: [],
    bespokeList: [],
    bespokeSearch: '',
    plotAnchorEl: null,
    plotPopperRef: useRef(null),
    plotInitialList: [],
    plotList: [],
    plotSearch: '',
    filtersState: {
      bespoke: null,
      plot: null,
      clientFilterId: null,
      SM: null,
      status: null
    }
  });
  const openBespoke = Boolean(filtersHandler.bespokeAnchorEl);
  const openPlot = Boolean(filtersHandler.plotAnchorEl);
  const openSM = Boolean(filtersHandler.SMAnchorEl);

  //handling click outside of popper and closing the current one
  useEffect(() => {
    const handleClickOutside = event => {
      const poppers = [
        filtersHandler.bespokePopperRef?.current,
        filtersHandler.plotPopperRef?.current,
        filtersHandler.SMPopperRef?.current
      ];

      const anchors = [filtersHandler.bespokeAnchorEl, filtersHandler.plotAnchorEl, filtersHandler.SMAnchorEl];

      const isClickOutside = ![...poppers, ...anchors].some(ref => ref && ref.contains(event.target));

      if (isClickOutside) {
        setFiltersHandler(prev => ({
          ...prev,
          bespokeAnchorEl: null,
          plotAnchorEl: null,
          SMAnchorEl: null
        }));
      }
    };

    if (openBespoke || openPlot || openSM) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openBespoke, openPlot, openSM, filtersHandler]);

  //populate the information for the filters popper
  useEffect(() => {
    if (dataIsReady && !isEmpty(unfilteredProjects)) {
      const bespokeList = unfilteredProjects.map(project => project.bespokeId.value);
      const orderedBespokeList = bespokeList.sort((a, b) => {
        const numA = parseInt(a.match(/\d+/)[0], 10);
        const numB = parseInt(b.match(/\d+/)[0], 10);
        return numA - numB;
      });

      const plotList = unfilteredProjects.map(project => project.plot.value);
      const orderedPlotList = plotList
        .filter(item => item && item !== '')
        .sort((a, b) => {
          const [letterA, numA] = a?.split('.').map((part, index) => (index === 1 ? parseInt(part, 10) : part));
          const [letterB, numB] = b?.split('.').map((part, index) => (index === 1 ? parseInt(part, 10) : part));
          if (letterA === letterB) {
            return numA - numB;
          }
          return letterA.localeCompare(letterB);
        });

      setFiltersHandler(prev => ({
        ...prev,
        bespokeList: [...new Set(orderedBespokeList.filter(item => item))],
        bespokeInitialList: [...new Set(orderedBespokeList.filter(item => item))],
        plotList: [...new Set(orderedPlotList)],
        plotInitialList: [...new Set(orderedPlotList)]
      }));
    }
  }, [dataIsReady]);

  const SMPopperOpened = Boolean(filtersHandler.SMAnchorEl);
  const SMPopperId = SMPopperOpened ? 'SM-popper' : undefined;
  const bespokePopperOpened = Boolean(filtersHandler.bespokeAnchorEl);
  const bespokePopperId = bespokePopperOpened ? 'bespoke-popper' : undefined;
  const plotPopperOpened = Boolean(filtersHandler.plotAnchorEl);
  const plotPopperId = plotPopperOpened ? 'plot-popper' : undefined;

  const navigate = useNavigate();
  const {
    dispatchProjectInfo,
    dispatchNotification,
    getHouseTypes,
    dispatchClient,
    PREFERENCES,
    DASHBOARD,
    dispatchResetTracking,
    getTracking,
    dispatchSetDialog,
    getIsAuthenticated
  } = useRedux();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_id = Cookies.get('salesManagerId');
  const sales_manager_token = Cookies.get('sessionId');
  const [searchParams] = useSearchParams();
  const clientIdFromParam = searchParams.get('clientId');
  const houseTypesInfo = getHouseTypes()[PREFERENCES.get()?.language?.code];
  const lang = PREFERENCES.get()?.language?.code;
  const dashboard = DASHBOARD.get()?.[lang];
  const isAdmin = getIsAuthenticated()?.isAdmin;

  //setting the initial data
  useEffect(() => {
    const setInitialData = async () => {
      if (!houseTypesInfo || isEmpty(houseTypesInfo) || !dashboard) return;

      if (!dataIsReady || isEmpty(unfilteredProjects)) {
        const allClients = await getAllClients(sales_manager_id, sales_manager, sales_manager_token);
        const projectsOfSM =
          (await getProjectsBySalesManagerAndProject(sales_manager_id, sales_manager, sales_manager_token)) || [];
        if (isAdmin) {
          const SMInfoOnBo = await getSalesmanagerInfoFromBO(null, true, sales_manager_token);
          const formattedSMInfo = SMInfoOnBo?.map(item => ({
            name: item?.attributes?.name,
            email: item?.attributes?.email
          }));
          const SMList = formattedSMInfo.map(item => item.name);
          setFiltersHandler(prev => ({
            ...prev,
            SMInitialList: SMList,
            SMList: SMList
          }));
        }

        const formattedClientData = allClients.map(item => ({
          name: item.name,
          email: { label: dashboard?.client_email, value: item.email },
          phone: { label: dashboard?.client_phone, value: item.phone },
          nationality: { label: dashboard?.client_nationality, value: item.nationality },
          configurations: {
            label: dashboard?.client_configurations,
            value: projectsOfSM.filter(el => el?.clientId?.id === item.id).length
          },
          id: item.id
        }));

        const formatCreatedDate = inputDate => {
          const date = new Date(inputDate);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${day}/${month}/${year} h ${hours}:${minutes}`;
        };

        const formattedProjectsData = projectsOfSM
          .map(item => {
            const projectClient = formattedClientData.find(el => el.id === item?.clientId?.id);
            const rawCreatedAt = item.createdAt;
            return {
              project: item.configurationName ?? item.configurationDefaultName,
              image:
                houseTypesInfo && !isEmpty(houseTypesInfo)
                  ? houseTypesInfo?.find(el => el?.id === item?.layout)?.image
                  : null,
              client: { label: dashboard?.card_client, value: projectClient?.name },
              clientId: projectClient?.id,
              bespokeId: { label: dashboard?.card_bespokeId, value: item.id },
              createdAt: {
                label: dashboard?.card_date,
                value: formatCreatedDate(rawCreatedAt),
                raw: rawCreatedAt
              },
              plot: { label: dashboard?.card_plot, value: item.plot },
              size: { label: dashboard?.card_house, value: item.layoutSize },
              layout: {
                label: dashboard?.card_layout,
                value:
                  houseTypesInfo && !isEmpty(houseTypesInfo)
                    ? houseTypesInfo?.find(el => el?.id === item?.layout)?.label
                    : null,
                code: item.layout
              },
              status: { label: 'Status', value: item.status },
              flow: { label: 'Flow', value: item.configurationFlow },
              SMName: item?.sales_manager.name
            };
          })
          .sort((a, b) => new Date(b.createdAt.raw) - new Date(a.createdAt.raw));

        setClientsList(formattedClientData);
        setFormattedClients(formattedClientData);
        setUnfilteredProjects(formattedProjectsData);
        setProjectsList(formattedProjectsData);
        if (dashboard && houseTypesInfo && !isEmpty(houseTypesInfo)) {
          setDataIsReady(true);
        }
      } else {
        const unfilteredClientData = formattedClients.map(item => ({
          name: item.name,
          email: { label: dashboard?.client_email, value: item.email.value },
          phone: { label: dashboard?.client_phone, value: item.phone.value },
          nationality: { label: dashboard?.client_nationality, value: item.nationality.value },
          configurations: {
            label: dashboard?.client_configurations,
            value: item.configurations.value
          },
          id: item.id
        }));

        const formattedClientData = clientsList.map(item => ({
          name: item.name,
          email: { label: dashboard?.client_email, value: item.email.value },
          phone: { label: dashboard?.client_phone, value: item.phone.value },
          nationality: { label: dashboard?.client_nationality, value: item.nationality.value },
          configurations: {
            label: dashboard?.client_configurations,
            value: item.configurations.value
          },
          id: item.id
        }));

        const formatCreatedDate = inputDate => {
          const date = new Date(inputDate);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${day}/${month}/${year} h ${hours}:${minutes}`;
        };

        const formattedProjectsData = projectsList
          .map(item => {
            const projectClient = formattedClientData.find(el => el.id === item?.clientId);
            const rawCreatedAt = item?.createdAt?.raw;
            return {
              project: item?.project,
              image: item?.image,
              client: { label: dashboard?.card_client, value: projectClient?.name },
              clientId: projectClient?.id,
              bespokeId: { label: dashboard?.card_bespokeId, value: item?.bespokeId?.value },
              createdAt: {
                label: dashboard?.card_date,
                value: formatCreatedDate(rawCreatedAt),
                raw: rawCreatedAt
              },
              plot: { label: dashboard?.card_plot, value: item?.plot?.value },
              size: { label: dashboard?.card_house, value: item?.size?.value },
              layout: {
                label: dashboard?.card_layout,
                value: houseTypesInfo?.find(el => el?.id === item?.layout?.code)?.label,
                code: item?.layout?.code
              },
              status: { label: 'Status', value: item?.status?.value },
              flow: { label: 'Flow', value: item?.flow?.value },
              SMName: item?.SMName
            };
          })
          .sort((a, b) => new Date(b.createdAt.raw) - new Date(a.createdAt.raw));

        const unfilteredProjectsData = unfilteredProjects
          .map(item => {
            const projectClient = formattedClientData.find(el => el.id === item?.clientId);
            const rawCreatedAt = item?.createdAt?.raw;
            return {
              project: item?.project,
              image: item?.image,
              client: { label: dashboard?.card_client, value: projectClient?.name },
              clientId: projectClient?.id,
              bespokeId: { label: dashboard?.card_bespokeId, value: item?.bespokeId?.value },
              createdAt: {
                label: dashboard?.card_date,
                value: formatCreatedDate(rawCreatedAt),
                raw: rawCreatedAt
              },
              plot: { label: dashboard?.card_plot, value: item?.plot?.value },
              size: { label: dashboard?.card_house, value: item?.size?.value },
              layout: {
                label: dashboard?.card_layout,
                value: houseTypesInfo?.find(el => el?.id === item?.layout?.code)?.label,
                code: item?.layout?.code
              },
              status: { label: 'Status', value: item?.status?.value },
              flow: { label: 'Flow', value: item?.flow?.value },
              SMName: item?.SMName
            };
          })
          .sort((a, b) => new Date(b.createdAt.raw) - new Date(a.createdAt.raw));

        setClientsList(formattedClientData);
        setFormattedClients(unfilteredClientData);
        setUnfilteredProjects(unfilteredProjectsData);
        setProjectsList(formattedProjectsData);
      }
    };

    setInitialData();
  }, [houseTypesInfo, dashboard, lang]);

  //removing possible client info from redux when is not coming from params
  useEffect(() => {
    if (!clientIdFromParam) {
      dispatchClient(null);
    }
  }, []);

  //handling behaviour when client is selected and when none is shown
  // useEffect(() => {
  //   if (isEmpty(clientsList)) {
  //     setSelectedClientObj(null);
  //     setFiltersHandler(prev => ({
  //       ...prev,
  //       filtersState: {
  //         ...prev.filtersState,
  //         clientFilterId: null
  //       }
  //     }));
  //   }

  //   if (!clientsList.includes(selectedClientObj)) {
  //     setFiltersHandler(prev => ({
  //       ...prev,
  //       filtersState: {
  //         ...prev.filtersState,
  //         clientFilterId: null
  //       }
  //     }));
  //     setSelectedClientObj(null);
  //   }
  // }, [selectedClientObj, clientsList]);

  //reset info on render
  useEffect(() => {
    dispatchResetTracking();
    dispatchProjectInfo(null);
  }, []);

  //handle the list of bespoke ids shown on the filter
  useEffect(() => {
    if (filtersHandler.bespokeSearch.trim() === '') {
      setFiltersHandler(prev => ({
        ...prev,
        bespokeList: prev.bespokeInitialList,
        filtersState: {
          ...prev.filtersState,
          bespoke: null
        }
      }));
    } else {
      setFiltersHandler(prev => ({
        ...prev,
        bespokeList: filtersHandler.bespokeInitialList.filter(item =>
          item.toLowerCase().includes(filtersHandler.bespokeSearch.toLowerCase())
        )
      }));
    }
  }, [filtersHandler.bespokeSearch]);

  //handle the list of plot ids shown on the filter
  useEffect(() => {
    setFiltersHandler(prev => ({
      ...prev,
      plotList: filtersHandler.plotInitialList.filter(item =>
        item.toLowerCase().includes(filtersHandler.plotSearch.toLowerCase())
      )
    }));
  }, [filtersHandler.plotSearch]);

  useEffect(() => {
    if (filtersHandler.filtersState.bespoke) {
      const filteredProjects = unfilteredProjects.filter(
        projectData => projectData.bespokeId.value === filtersHandler.filtersState.bespoke
      );
      setProjectsList(filteredProjects);
    }

    if (filtersHandler.filtersState.plot) {
      if (selectedClientObj) {
        const filteredProjects = unfilteredProjects.filter(
          projectData =>
            projectData.plot.value === filtersHandler.filtersState.plot || projectData.clientId === selectedClientObj.id
        );
        setProjectsList(filteredProjects);
      } else {
        const filteredProjects = unfilteredProjects.filter(
          projectData => projectData.plot.value === filtersHandler.filtersState.plot
        );
        setProjectsList(filteredProjects);
      }
    }
  }, [filtersHandler.filtersState.bespoke, filtersHandler.filtersState.plot, selectedClientObj]);

  //checking if the client is associated to the sales manager when coming from params
  useEffect(() => {
    if (clientIdFromParam && dataIsReady) {
      const clientIsAssociatedToSM = formattedClients.some(el => `${el.id}` === clientIdFromParam);
      if (clientIsAssociatedToSM) {
        const clientSelectedOnParam = formattedClients.filter(el => `${el.id}` === clientIdFromParam);
        setClientSearch(clientSelectedOnParam[0].name);
        setClientsList(clientSelectedOnParam);
        setFiltersHandler(prev => ({
          ...prev,
          filtersState: {
            ...prev.filtersState,
            clientFilterId: clientSelectedOnParam[0].id
          }
        }));
        setSelectedClientObj(clientSelectedOnParam[0]);
        const filteredProjects = unfilteredProjects.filter(
          projectData => projectData.clientId === clientSelectedOnParam[0].id
        );
        setProjectsList(filteredProjects);
      } else {
        dispatchNotification({
          timer: true,
          type: 'banner',
          status: 'error',
          title: t('client_id.assign_another_sales_manager')
        });
        navigate('/dashboard');
      }
    }
  }, [formattedClients]);

  //resetting the client list when the search is empty
  useEffect(() => {
    if (clientSearch === '') {
      setClientsList(formattedClients);
      setFiltersHandler(prev => ({
        ...prev,
        filtersState: {
          ...prev.filtersState,
          clientFilterId: null
        }
      }));
      setSelectedClientObj(null);
      return;
    }
  }, [clientSearch]);

  //handling all the filters at once
  useEffect(() => {
    const filteredProjectsData = unfilteredProjects.filter(project => {
      const { bespoke, plot, clientFilterId, SM, status } = filtersHandler.filtersState;

      const matchesSM = SM ? project.SMName === SM : true;
      const matchesBespoke = bespoke ? project.bespokeId.value === bespoke : true;
      const matchesPlot = plot ? project.plot.value === plot : true;
      const matchesClientName = clientFilterId ? project.clientId === clientFilterId : true;
      const matchesStatus = status && !isEmpty(status) ? status.includes(project.status.value) : true;

      return matchesSM && matchesBespoke && matchesPlot && matchesClientName && matchesStatus;
    });

    setProjectsList(filteredProjectsData);
  }, [filtersHandler.filtersState]);

  const handleProjectClick = bespokeId => {
    navigate(`/journey/${bespokeId}`);
    const clickedProject = unfilteredProjects.find(project => project.bespokeId.value === bespokeId);
    const clientEmail = formattedClients.find(client => client.id === clickedProject.clientId).email;
    dispatchClient({ email: clientEmail });
  };

  const handleClientSearch = client => {
    setClientSearch(client);

    const normalizeString = str => {
      return str
        ? str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        : '';
    };

    const normalizedSearch = normalizeString(client);

    const filteredClients = formattedClients.filter(clientData => {
      const normalizedName = normalizeString(clientData?.name);
      const normalizedEmail = normalizeString(clientData?.email?.value);
      const normalizedPhone = normalizeString(clientData?.phone?.value);

      return (
        normalizedName.includes(normalizedSearch) ||
        normalizedEmail.includes(normalizedSearch) ||
        normalizedPhone.includes(normalizedSearch)
      );
    });

    setClientsList(filteredClients);
  };

  const handleClientSelection = (client, index) => {
    if (client.id === selectedClientObj?.id) {
      setFiltersHandler(prev => ({
        ...prev,
        filtersState: {
          ...prev.filtersState,
          clientFilterId: null
        }
      }));
      setSelectedClientObj(null);
      dispatchClient(null);

      setClientSearch('');
      return;
    }

    setSelectedClientObj(clientsList[index]);
    setFiltersHandler(prev => ({
      ...prev,
      filtersState: {
        ...prev.filtersState,
        clientFilterId: clientsList[index].id
      }
    }));
    setClientSearch(client.name);
    const filteredProjects = unfilteredProjects.filter(projectData => projectData.clientId === client.id);
    setProjectsList(filteredProjects);
  };

  const handleClearTag = (key, status) => {
    if (key === 'clientFilterId') {
      setFiltersHandler(prev => ({
        ...prev,
        filtersState: {
          ...prev.filtersState,
          clientFilterId: null
        }
      }));
      dispatchClient(null);
      setClientSearch('');
      setClientsList(formattedClients);
    } else if (key === 'plot') {
      setFiltersHandler(prev => ({
        ...prev,
        plotSearch: '',
        filtersState: {
          ...prev.filtersState,
          plot: null
        }
      }));
    } else if (key === 'bespoke') {
      setFiltersHandler(prev => ({
        ...prev,
        bespokeSearch: '',
        filtersState: {
          ...prev.filtersState,
          bespoke: null
        }
      }));
    } else if (key === 'SM') {
      setFiltersHandler(prev => ({
        ...prev,
        SMsearch: '',
        filtersState: {
          ...prev.filtersState,
          SM: null
        }
      }));
    } else if (key === 'status') {
      setFiltersHandler(prev => ({
        ...prev,
        filtersState: {
          ...prev.filtersState,
          status: prev.filtersState.status.filter(item => item !== status)
        }
      }));
    }
  };

  const handleStartDesign = async () => {
    const clientSFInfo = await getClientDetails(selectedClientObj.email.value, sales_manager_token, sales_manager_id);

    await createRequest(
      {
        clientEmail: selectedClientObj.email.value,
        salesManagerEmail: sales_manager,
        salesManagerId: sales_manager_id,
        clientDetailsFromSF: clientSFInfo.data,
        projectDefaultLanguage: PREFERENCES?.get()?.language?.code,
        projectPrefix: 'TCT'
      },
      sales_manager_token
    )
      .then(async response => {
        if (response) {
          dispatchProjectInfo(response.projectInfo);
          navigate(`/journey/${response.projectInfo.id}`);
        }
      })
      .catch(error => {
        console.log('Error saving project do DB', error);
      });
  };

  return (
    <>
      {dataIsReady && !goToNewDesign ? (
        <div className='dashboard-container'>
          <Header shouldDisplay isDark dashboardIsCollapsed={!isCollapsed} />
          <div className={`${'content-container'} ${isCollapsed ? 'sidePanelIsCollapsed' : ''}`}>
            <div className='projects-wrapper'>
              <div className='titleAndContentDiv'>
                <div className='title-section'>
                  <span className='title'>{dashboard.title}</span>
                  <span className='subtitle'>{dashboard.subtitle}</span>
                </div>
                <div className={'main-content-wrapper'}>
                  <div className='filter-section'>
                    <div className='filter-wrapper'>
                      <span className={'filter'}>{dashboard.filteredBy}:</span>
                      <div className='tags-wrapper'>
                        {Object.entries(filtersHandler.filtersState)
                          .filter(([key, value]) => value)
                          .flatMap(([key, value]) => {
                            if (key === 'status' && Array.isArray(value)) {
                              // Se for status e for um array, retorna uma tag para cada item
                              return value.map(statusValue => (
                                <div key={`${key}-${statusValue}`} className='filter tag'>
                                  {dashboard[`filter_status_${statusValue}`]}
                                  <div
                                    onClick={() => handleClearTag(key, statusValue)}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                  >
                                    <SVG icon={'semantic/circle-x'} />
                                  </div>
                                </div>
                              ));
                            }
                            if (key !== 'status') {
                              return (
                                <div key={key} className='filter tag'>
                                  {key !== 'clientFilterId' ? value : selectedClientObj?.name}
                                  <div
                                    onClick={() => handleClearTag(key)}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                  >
                                    {value && <SVG icon={'semantic/circle-x'} />}
                                  </div>
                                </div>
                              );
                            }

                            return [];
                          })}
                      </div>
                    </div>
                    <FilterButton
                      label={dashboard.filters_label}
                      onClickFn={() => {
                        setFiltersOpened(true);
                        if (isCollapsed) {
                          setIsCollapsed(false);
                        }
                      }}
                      filtersCount={0}
                    />
                  </div>
                  <DashboardProjectsComponent
                    {...{
                      selectedClientObj,
                      projectsList,
                      handleProjectClick,
                      houseTypesInfo,
                      unfilteredProjects,
                      setUpdatedStatus,
                      setProjectToUpdateStatus,
                      setUnfilteredProjects,
                      dashboard,
                      setGoToNewDesign,
                      handleStartDesign,
                      isAdmin
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`side-panel-wrapper ${isCollapsed ? 'collapsed' : 'expanded'}`}>
            {!filtersOpened ? (
              <>
                <div className='collapser-div'>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    <SVG icon={!isCollapsed ? 'navigation/collapse' : 'navigation/expand'} />
                  </div>
                </div>
                {!isCollapsed && (
                  <>
                    <div style={{ width: '100%', marginTop: '66px' }}>
                      <TextInput
                        value={clientSearch}
                        icon={'action/search'}
                        placeholder=''
                        type='search'
                        name='search'
                        id='search'
                        clearFn={() => {
                          handleClearTag('clientFilterId');
                        }}
                        label={dashboard.search}
                        customCSS={{ height: '44px', width: '100%' }}
                        onChangeFn={e => {
                          handleClientSearch(e.target.value);
                        }}
                      />
                    </div>
                    <div className='clients-section'>
                      <span className='clients-title'>{dashboard.clients}</span>
                      <div className='clients-cards'>
                        {clientsList.map((client, index) => (
                          <div
                            key={client.id}
                            className={`client-card ${(selectedClientObj && selectedClientObj.id) === client.id ? 'selected' : ''}`}
                            onClick={() => {
                              handleClientSelection(client, index);
                            }}
                          >
                            <span className='client-name'>{client.name}</span>
                            {Object.entries(client).map(([key, value], idx) => {
                              if (key === 'name' || key === 'id') return null;
                              return (
                                <div
                                  key={`${client.id}-${key}-${idx}`}
                                  style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                  <span className='client-info-key'>
                                    {value?.label?.charAt(0)?.toUpperCase() + value?.label?.slice(1)}
                                  </span>
                                  <span className='client-info-value'>{value.value}</span>
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className='filter-header'>
                  <IconButton
                    size={'small'}
                    onImageBg={false}
                    icon={'semantic/close'}
                    onClickFn={() => {
                      setFiltersOpened(false);
                    }}
                  />
                </div>
                <div className='filter-content-div'>
                  <div className='filter-title'>
                    <span className='headline'>{dashboard.filter_title}</span>
                    <span className='description'>{dashboard.filter_subtitle}</span>
                  </div>
                  <div className='inputs-wrapper'>
                    {isAdmin && (
                      <>
                        <div className='SMSearch'>
                          <TextInput
                            onFocus={event => {
                              setFiltersHandler(prev => ({
                                ...prev,
                                SMAnchorEl: event.target,
                                SMList: filtersHandler.SMInitialList.filter(item =>
                                  item.toLowerCase().includes(filtersHandler.SMsearch.toLowerCase())
                                )
                              }));
                            }}
                            value={filtersHandler.SMsearch}
                            icon={'action/search'}
                            placeholder=''
                            type='search'
                            name='SMSearch'
                            id='SMSearch'
                            clearFn={() => {
                              setFiltersHandler(prev => ({
                                ...prev,
                                filtersState: {
                                  ...prev.filtersState,
                                  SM: null
                                },
                                SMsearch: '',
                                SMAnchorEl: null,
                                SMList: filtersHandler.SMInitialList
                              }));
                            }}
                            label={dashboard.filter_searchSalesmanager}
                            customCSS={{ height: '44px', width: '100%' }}
                            onChangeFn={e => {
                              setFiltersHandler(prev => ({
                                ...prev,
                                SMsearch: e.target.value,
                                SMList: filtersHandler.SMInitialList.filter(item =>
                                  item.toLowerCase().includes(e.target.value.toLowerCase())
                                )
                              }));
                            }}
                          />
                        </div>
                        <Popper
                          id={SMPopperId}
                          open={SMPopperOpened && !isEmpty(filtersHandler.SMList)}
                          anchorEl={filtersHandler.SMAnchorEl}
                          placement='bottom'
                          style={{ zIndex: 9999, width: filtersHandler.SMAnchorEl?.clientWidth }}
                        >
                          <div className='options-menu' ref={filtersHandler.SMPopperRef}>
                            {filtersHandler.SMList.map((item, index) => {
                              return (
                                <MenuItem
                                  text={item}
                                  icon={'semantic/check'}
                                  selected={item === filtersHandler.filtersState.SM}
                                  onClickFn={() => {
                                    setFiltersHandler(prev => ({
                                      ...prev,
                                      filtersState: {
                                        ...prev.filtersState,
                                        SM:
                                          !filtersHandler.filtersState.SM || filtersHandler.filtersState.SM !== item
                                            ? item
                                            : null
                                      },
                                      SMsearch: item,
                                      SMAnchorEl: null
                                    }));
                                  }}
                                />
                              );
                            })}
                          </div>
                        </Popper>
                      </>
                    )}
                    <div className='bespokeSearch'>
                      <TextInput
                        onFocus={event => {
                          setFiltersHandler(prev => ({
                            ...prev,
                            bespokeAnchorEl: event.target
                          }));
                        }}
                        value={filtersHandler.bespokeSearch}
                        icon={'action/search'}
                        placeholder=''
                        type='search'
                        name='bespokeSearch'
                        id='bespokeSearch'
                        clearFn={() => {
                          setFiltersHandler(prev => ({
                            ...prev,
                            filtersState: {
                              ...prev.filtersState,
                              bespoke: null
                            },
                            bespokeSearch: '',
                            bespokeAnchorEl: null,
                            bespokeList: filtersHandler.bespokeInitialList
                          }));
                        }}
                        label={dashboard.filter_searchBespokeId}
                        customCSS={{ height: '44px', width: '100%' }}
                        onChangeFn={e => {
                          setFiltersHandler(prev => ({
                            ...prev,
                            bespokeSearch: e.target.value
                          }));
                        }}
                      />
                    </div>
                    <Popper
                      id={bespokePopperId}
                      open={bespokePopperOpened && !isEmpty(filtersHandler.bespokeList)}
                      anchorEl={filtersHandler.bespokeAnchorEl}
                      placement='bottom'
                      style={{ zIndex: 9999, width: filtersHandler.bespokeAnchorEl?.clientWidth }}
                    >
                      <div className='options-menu' ref={filtersHandler.bespokePopperRef}>
                        {filtersHandler.bespokeList.map((item, index) => {
                          return (
                            <MenuItem
                              text={item}
                              icon={'semantic/check'}
                              selected={item === filtersHandler.filtersState.bespoke}
                              onClickFn={() => {
                                setFiltersHandler(prev => ({
                                  ...prev,
                                  filtersState: {
                                    ...prev.filtersState,
                                    bespoke:
                                      !filtersHandler.filtersState.bespoke ||
                                      filtersHandler.filtersState.bespoke !== item
                                        ? item
                                        : null
                                  },
                                  bespokeSearch: item,
                                  bespokeAnchorEl: null
                                }));
                              }}
                            />
                          );
                        })}
                      </div>
                    </Popper>
                    <div className='plotSearch'>
                      <TextInput
                        onFocus={event => {
                          setFiltersHandler(prev => ({
                            ...prev,
                            plotAnchorEl: event.target
                          }));
                        }}
                        value={filtersHandler.plotSearch}
                        icon={'action/search'}
                        placeholder=''
                        type='search'
                        name='plotSearch'
                        id='plotSearch'
                        clearFn={() => {
                          setFiltersHandler(prev => ({
                            ...prev,
                            filtersState: {
                              ...prev.filtersState,
                              plot: null
                            },
                            plotSearch: '',
                            plotAnchorEl: null,
                            plotList: filtersHandler.plotInitialList
                          }));
                        }}
                        label={dashboard.filter_searchPlot}
                        customCSS={{ height: '44px', width: '100%' }}
                        onChangeFn={e => {
                          setFiltersHandler(prev => ({
                            ...prev,
                            plotSearch: e.target.value,
                            plotList: filtersHandler.plotInitialList.filter(item =>
                              item.toLowerCase().includes(e.target.value.toLowerCase())
                            )
                          }));
                        }}
                      />
                    </div>
                    <Popper
                      id={plotPopperId}
                      open={plotPopperOpened && !isEmpty(filtersHandler.plotList)}
                      anchorEl={filtersHandler.plotAnchorEl}
                      placement='bottom'
                      style={{ zIndex: 9999, width: filtersHandler.plotAnchorEl?.clientWidth }}
                    >
                      <div className='options-menu' ref={filtersHandler.plotPopperRef}>
                        {filtersHandler.plotList.map((item, index) => {
                          return (
                            <MenuItem
                              text={item}
                              icon={'semantic/check'}
                              selected={item === filtersHandler.filtersState.plot}
                              onClickFn={() => {
                                setFiltersHandler(prev => ({
                                  ...prev,
                                  filtersState: {
                                    ...prev.filtersState,
                                    plot:
                                      !filtersHandler.filtersState.plot || filtersHandler.filtersState.plot !== item
                                        ? item
                                        : null
                                  },
                                  plotSearch: item,
                                  plotAnchorEl: null
                                }));
                              }}
                            />
                          );
                        })}
                      </div>
                    </Popper>
                  </div>
                  <div className='status-filter-div'>
                    <span className='status-title'>{dashboard.filter_status}</span>
                    {Object.entries(CONFIG_STATUS).map(([key, value]) => (
                      <Checkbox
                        label={dashboard[`filter_status_${value}`]}
                        disabled={
                          selectedClientObj &&
                          !unfilteredProjects.some(
                            el => el.status.value === value && el.clientId === selectedClientObj.id
                          )
                        }
                        onSelect={() => {
                          setFiltersHandler(prev => {
                            const currentStatus = prev.filtersState.status || [];
                            return {
                              ...prev,
                              filtersState: {
                                ...prev.filtersState,
                                status: currentStatus.includes(value) ? currentStatus : [...currentStatus, value]
                              }
                            };
                          });
                        }}
                        onDeselect={() => {
                          setFiltersHandler(prev => {
                            const currentStatus = prev.filtersState.status || [];
                            return {
                              ...prev,
                              filtersState: {
                                ...prev.filtersState,
                                status: !isEmpty(currentStatus) ? currentStatus.filter(item => item !== value) : null
                              }
                            };
                          });
                        }}
                        value={filtersHandler.filtersState.status?.includes(value)}
                      />
                    ))}
                    {/* <LinkButton
                      icon='action/rotate-ccw'
                      text={dashboard.filter_status_clear}
                      onClickFn={() => {
                        setFiltersHandler(prev => ({
                          ...prev,
                          filtersState: {
                            ...prev.filtersState,
                            status: []
                          }
                        }));
                      }}
                    /> */}
                  </div>
                </div>
                <div className='footer-div'>
                  {dashboard.actions.map(el => {
                    return (
                      <Button
                        primary={el.type.toLowerCase() === 'primary'}
                        secondary={el.type.toLowerCase() === 'secondary'}
                        text={el.label}
                        number={el.action === 'CONFIRM' ? projectsList.length : undefined}
                        onClickFn={() => {
                          if (el.action === 'CONFIRM') {
                            setFiltersOpened(false);
                            setIsCollapsed(true);
                          } else {
                            setFiltersHandler(prev => ({
                              ...prev,
                              filtersState: {
                                bespoke: null,
                                plot: null,
                                clientFilterId: null
                              },
                              bespokeSearch: '',
                              plotSearch: ''
                            }));
                            setSelectedClientObj(null);
                            setClientSearch('');
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
          {isModalOpen && (
            <Portal>
              <Dialog
                updateStatusProps={{
                  newStatus: updatedStatus,
                  projectId: projectToUpdateStatus,
                  setUnfilteredProjects
                }}
                type={getTracking().dialog.type}
                id={getTracking().dialog.id}
                onClose={() => {
                  toggleModal();
                  dispatchSetDialog({ open: false, type: '', id: '' });
                }}
              />
            </Portal>
          )}
        </div>
      ) : !dataIsReady && !goToNewDesign ? (
        <></>
      ) : (
        <EnterpriseSelection />
      )}
    </>
  );
};
export default Dashboard;
