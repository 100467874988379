import { getSubmissionRemoteContent } from '@services/singlePages/submissionRemote';
import { submissionRemoteAction } from './submissionRemote-slice';

export const setSubmissionRemoteContent = async (dispatch, SMToken) => {
  try {
    const response = await getSubmissionRemoteContent(SMToken);
    dispatch(submissionRemoteAction.setSubmissionRemoteContent(response));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
