import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const getClientDetails = async (email, SMToken, SMId) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'client', service: 'getDetails' })}/${email}?salesManagerId=${SMId}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);
  return response;
};

export const registerClient = async (data, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'client', service: 'registerClient' })}`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);
  return response.data;
};

export const getClientOnDb = async (id, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'client', service: 'getClientOnDb' })}/${id}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);
  return response.data;
};

export const getAllClients = async (salesManagerId, salesManagerEmail, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'client', service: 'getAllClients' })}?salesManagerId=${salesManagerId}&&username=${salesManagerEmail}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);
  return response.data;
};

export const createRequest = async (data, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'client', service: 'createRequest' })}`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);
  return response.data;
};

export const updateRequest = async (data, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'client', service: 'updateRequest' })}`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);
  return response.data;
};
