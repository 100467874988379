import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SVG } from '@assets/svg';
import Button from '@components/ui/Buttons/Button/Button';
import logoImage from '../../assets/img/logo_bespoke_gray.png';
import useRedux from '@hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import Tag from '@components/ui/Tag/Tag';
import State from '@components/ui/State/State';
//utils
import { DIALOG_TYPE } from '@utils/enums';

const DashboardProjectsComponent = ({
  selectedClientObj,
  projectsList,
  handleProjectClick,
  houseTypesInfo,
  unfilteredProjects,
  setUpdatedStatus,
  setProjectToUpdateStatus,
  setUnfilteredProjects,
  dashboard,
  setGoToNewDesign,
  handleStartDesign,
  isAdmin
}) => {
  const { t } = useTranslation();
  const [loadedImages, setLoadedImages] = useState({});
  const { dispatchClient, dispatchSetDialog, getEnterpriseContent, PREFERENCES } = useRedux();
  const language = PREFERENCES.get()?.language?.code;
  const enterpriseContent = getEnterpriseContent()?.[language];

  const handleImageLoad = index => {
    setLoadedImages(prevState => ({
      ...prevState,
      [index]: true
    }));
  };
  const navigate = useNavigate();

  const handleTagClick = async (value, projectId) => {
    if (value === 'inactive' || value === 'active' || (value === 'submitted' && isAdmin)) {
      setProjectToUpdateStatus(projectId);
      dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.StatusChange });
      if (value === 'active') {
        setUpdatedStatus('inactive');
      } else {
        setUpdatedStatus('active');
      }
    }
  };

  return (
    <div key={unfilteredProjects} className={'projects-section'}>
      {selectedClientObj && (
        <div className='new-design-card'>
          <div className='new-design-content'>
            <SVG icon='logo/bespokeliving_grey' />
            <div className='new-design-button'>
              <Button
                onClickFn={async () => {
                  if (enterpriseContent?.showPage) {
                    setGoToNewDesign(true);
                    dispatchClient({ email: selectedClientObj.email.value });
                  } else {
                    await handleStartDesign();
                  }
                }}
                primary
                text={dashboard.startNewDesign}
              />
            </div>
          </div>
        </div>
      )}
      {unfilteredProjects.map((project, index) => {
        const hasLayoutImage = houseTypesInfo?.find(el => el?.label === project?.layout?.value)?.image;
        const imageSrc = project.image ?? logoImage;
        const isImageLoaded = loadedImages[index];

        return (
          <div
            key={index}
            style={{
              display: projectsList.some(p => p.bespokeId?.value === project.bespokeId.value) ? 'block' : 'none'
            }}
            className='project-card'
          >
            <div className='image-wrapper'>
              <img
                style={{
                  objectFit: isImageLoaded && hasLayoutImage ? 'cover' : 'none'
                }}
                src={imageSrc}
                alt='layout-image'
                className='project-image'
                onLoad={() => {
                  if (hasLayoutImage) handleImageLoad(index);
                }}
              />
              <div
                onClick={() => {
                  handleTagClick(project.status?.value, project.bespokeId?.value);
                }}
                className={`tag-wrapper ${project.status?.value === 'active' || project.status?.value === 'inactive' || (project.status?.value === 'submitted' && isAdmin) ? 'pointer' : ''}`}
              >
                <Tag status={project.status?.value} label={dashboard[`filter_status_${project.status?.value}`]} />
              </div>
            </div>
            <div className='project-info' onClick={() => handleProjectClick(project.bespokeId?.value)}>
              <div className='project-title-wrapper'>
                <span className='project-name'>{project.project}</span>
                <State type={'info'} text={dashboard[`card_${project.flow?.value}`]} />
              </div>
              {Object.entries(project).map(([key, value]) => {
                if (
                  key === 'image' ||
                  key === 'project' ||
                  key === 'clientId' ||
                  key === 'status' ||
                  key === 'flow' ||
                  key === 'SMName'
                )
                  return null;
                return (
                  <div key={key} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className='project-info-key'>
                      {value?.label?.charAt(0)?.toUpperCase() + value?.label?.slice(1)}
                    </span>
                    <span className='project-info-value'>{value?.value ?? '-'}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DashboardProjectsComponent;
