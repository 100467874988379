import useRedux from './useRedux';
import SHA1 from 'crypto-js/sha1';
import { getUrl } from '@utils/images';
import { getImageUrl } from '@services/images';
import { isEmpty } from '@utils/functions';

const useImage = () => {
  // IMPROVE
  const {
    getCurrentStepId,
    getImages,
    dispatchImage,
    getCurrentQuestionId,
    dispatchCurrentImage,
    getHouses,
    getTracking
  } = useRedux();

  const getObjectIdAndValue = obj => {
    return Object.entries(obj)
      .map(([key, value]) => `${key}_${value}`)
      .join('_');
  };

  const flattenObject = obj => {
    let result = [];

    Object.values(obj).forEach(value => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        // If the value is an object, recurse deeper
        result = result.concat(flattenObject(value));
      } else {
        return;
      }
    });

    // If the object contains "suite" and "bathroom", it’s a valid room object
    if (obj.suite && obj.bathroom) {
      result.push(obj);
    }

    return result;
  };

  const hasNestedObjects = obj => {
    return Object.values(obj).some(value => typeof value === 'object' && value !== null);
  };

  const newObject = (keysToExtract, originalObject) => {
    return keysToExtract.reduce((newObj, key) => {
      if (originalObject[key] !== undefined) {
        newObj[key] = originalObject[key];
      }
      return newObj;
    }, {});
  };

  const setImageOnRedux = async obj => {
    const hash = SHA1(JSON.stringify(obj)).toString();
    if (!getImages().hasOwnProperty(hash)) {
      // if image is not stored on redux, get Url for the proper combination, store on redux and return
      const queryURL = getUrl(obj);
      const imageUrlResponse = await getImageUrl({ queryURL });
      // update redux if imageUrlResponse exists
      if (imageUrlResponse && imageUrlResponse.url)
        dispatchImage({ hash, imageUrl: imageUrlResponse.url, vimeoID: imageUrlResponse?.vimeoID });
    } else {
      dispatchImage({ hash });
    }
  };

  const get = async obj => {
    const key = Object.keys(obj);
    const value = obj[key];
    const id = `${getCurrentStepId()}_${getCurrentQuestionId()}_${getObjectIdAndValue(value)}`;
    const queryURL = getUrl(value);
    const imageUrlResponse = await getImageUrl({ queryURL });
    return { id, imagesurl: imageUrlResponse?.url };
  };

  const setImageOnReduxNew = async obj => {
    if (!hasNestedObjects(obj)) {
      const id = `${getCurrentStepId()}_${getCurrentQuestionId()}_${getObjectIdAndValue(obj)}`;
      if (!getImages().hasOwnProperty(id)) {
        const queryURL = getUrl(obj);
        const imageUrlResponse = await getImageUrl({ queryURL });
        if (imageUrlResponse && imageUrlResponse.url) {
          dispatchImage({ hash: id, imageUrl: imageUrlResponse.url, vimeoID: imageUrlResponse?.vimeoID });
        }
      } else {
        dispatchImage({ hash: id });
      }
    } else {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      const id = `${getCurrentStepId()}_${getCurrentQuestionId()}_${getObjectIdAndValue(value)}`;
      if (!getImages().hasOwnProperty(id)) {
        const queryURL = getUrl(value);
        const imageUrlResponse = await getImageUrl({ queryURL });
        if (imageUrlResponse && imageUrlResponse.url) {
          dispatchImage({ hash: id, imageUrl: imageUrlResponse.url, vimeoID: imageUrlResponse?.vimeoID });
        }
      } else {
        dispatchImage({ hash: id });
      }
    }
  };

  const getId = obj => {
    const values = Object.entries(obj);
    let id = `${getCurrentStepId()}_${getCurrentQuestionId()}`;
    values.forEach(([key, value]) => {
      id += `_${key}_${value}`;
    });
    return id;
  };

  const setImageContainerImage = async watcher => {
    const questionId = getCurrentQuestionId();
    if (questionId != null) {
      // dispatchUpdateCurrentQuestionId(questionId);
      if (getCurrentStepId() === 'layout' && !isEmpty(watcher['layout'])) {
        const layoutObj = {
          nrRooms: watcher?.['layout']?.['nrRooms'],
          houseType: watcher?.['layout']?.['houseType'],
          houseLayout: watcher?.['layout']?.['houseLayout']
        };
        // if (questionId === 'nrRooms') {
        //   const houseType = newObject(['nrRooms'], layoutObj);
        //   await setImageOnRedux(houseType);
        // }

        if (questionId === 'nrRooms' || questionId === 'houseType') {
          const houseType = newObject(['houseType'], layoutObj);
          await setImageOnReduxNew(houseType);
        }
        if (questionId === 'houseLayout') {
          const houseLayout = newObject(['houseLayout'], layoutObj);
          // const id = SHA1(JSON.stringify(houseLayout)).toString();
          const id = `${getCurrentStepId()}_${getCurrentQuestionId()}_${getObjectIdAndValue(houseLayout)}`;
          const questionImage = getHouses().find(house => house.id === houseLayout.houseLayout).floorPlan;
          if (questionImage !== getImages().current) {
            dispatchImage({
              hash: id,
              imageUrl: questionImage
            });
          }
        }
      }
      if (getCurrentStepId() === 'exteriors') {
        const exteriorsObj = {
          pool: watcher?.exteriors?.pool?.poolSize,
          parking: watcher?.exteriors?.parking?.parkingType
        };
        if (questionId === 'pool') {
          const pool = newObject(['pool'], exteriorsObj);
          await setImageOnReduxNew(pool);
        }
        if (questionId === 'parking') {
          const houseType = watcher?.layout?.houseType;
          const parking = newObject(['houseType', 'parking'], { ...exteriorsObj, houseType });
          await setImageOnReduxNew(parking);
        }
      }
      if (getCurrentStepId() === 'interiors' && !isEmpty(watcher['interiors'])) {
        if (questionId === 'socialAreas') {
          const socialAreas = newObject(['socialAreas'], watcher['interiors']).socialAreas;
          const socialAreasCruzadas = { ...socialAreas, houseType: watcher['layout'].houseType };
          const id = getId(socialAreasCruzadas);
          if (!getImages().hasOwnProperty(id)) {
            setImageOnReduxNew(socialAreasCruzadas);
          } else {
            dispatchCurrentImage({ imageUrl: getImages()[id].image });
          }
        } else if (questionId === 'kitchen') {
          const houseType = watcher['layout'].houseType;
          const kitchenObj = newObject(['kitchen'], {...watcher['interiors'], houseType});
          // await setImageOnReduxNew(kitchen.kitchen.kitchen);
          const id = getId(kitchenObj.kitchen.kitchen);
          if (!getImages().hasOwnProperty(id)) {
            const queryURL = getUrl(kitchenObj.kitchen);
            const imageUrlResponse = await getImageUrl({ queryURL });
            if (imageUrlResponse && imageUrlResponse.url) {
              dispatchImage({ hash: id, imageUrl: imageUrlResponse.url, vimeoID: imageUrlResponse?.vimeoID });
            }
          } else {
            dispatchCurrentImage({ imageUrl: getImages()[id].image });
          }
        } else if (questionId === 'intimateAreas') {
          const currentIndex = getTracking().intimateIndex;
          const intimateAreas = newObject(['intimateAreas'], watcher['interiors']).intimateAreas;

          const obj = flattenObject(intimateAreas)[currentIndex];
          const id = getId(obj);
          if (!getImages().hasOwnProperty(id)) {
            const queryURL = getUrl(obj);
            const imageUrlResponse = await getImageUrl({ queryURL });
            if (imageUrlResponse && imageUrlResponse.url) {
              dispatchImage({ hash: id, imageUrl: imageUrlResponse.url, vimeoID: imageUrlResponse?.vimeoID });
            }
          } else {
            dispatchCurrentImage({ imageUrl: getImages()[id].image });
          }
        }
        // const questionImage =
        //   'https://bucketeer-464e2ef3-3fff-47e6-95be-b19199ee8512.s3.eu-west-1.amazonaws.com/Sala_73269ae0f4.png';
      }
    }
    return;
  };

  const setImageContainerImageOld = async watcher => {
    if (getCurrentStepId() === 'plot' || getCurrentStepId() === 'layout') {
      const myWatcher = {
        ...watcher['plot'],
        houseType: watcher?.layout?.houseType,
        houseLayout: watcher?.layout?.houseLayout
      };

      if (!isEmpty(myWatcher)) {
        const hash = SHA1(JSON.stringify(myWatcher)).toString();

        if (watcher?.layout?.houseLayout && !isEmpty(watcher?.layout?.houseLayout)) {
          dispatchImage({
            hash,
            imageUrl:
              'https://bucketeer-464e2ef3-3fff-47e6-95be-b19199ee8512.s3.eu-west-1.amazonaws.com/Layout1_4040cab6c4.jpg'
          });
          return;
        }
        if (!getImages().hasOwnProperty(hash)) {
          // if image is not stored on redux, get Url for the proper combination, store on redux and return
          const queryURL = getUrl(myWatcher);
          const imageUrlResponse = await getImageUrl({ queryURL });
          // update redux if imageUrlResponse exists
          if (imageUrlResponse && imageUrlResponse.url) dispatchImage({ hash, imageUrl: imageUrlResponse.url });
        } else {
          dispatchImage({ hash });
        }
      }
    } else if (getCurrentStepId() === 'exteriors') {
      const myWatcher = {
        ...watcher['plot'],
        ...watcher['layout'],
        ...watcher['exteriors']
      };
      const hash = SHA1(JSON.stringify(myWatcher)).toString();
      dispatchImage({
        hash,
        imageUrl:
          'https://bucketeer-464e2ef3-3fff-47e6-95be-b19199ee8512.s3.eu-west-1.amazonaws.com/Parking_67efd3f011.jpg'
      });
    }
  };

  return { setImageContainerImage };
};

export default useImage;
