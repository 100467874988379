import { createSlice } from '@reduxjs/toolkit';

const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState: {},
  reducers: {
    setEnterpriseContent: (state, action) => {
      return { ...action.payload };
    }
  }
});

const enterpriseAction = enterpriseSlice.actions;
export { enterpriseSlice, enterpriseAction };
