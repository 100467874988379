import { createSlice } from '@reduxjs/toolkit';

const houseTypesSlice = createSlice({
  name: 'houseTypes',
  initialState: {},
  reducers: {
    setHouseTypes: (state, action) => {
      return { ...action.payload };
    }
  }
});

const houseTypesAction = houseTypesSlice.actions;
export { houseTypesSlice, houseTypesAction };
