import { useEffect, useState } from 'react';
// hooks
import { useNavigate, useLocation, Link } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
// components
import Button from '@components/ui/Buttons/Button/Button';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import PasswordInput from '@components/ui/Inputs/PasswordInput/PasswordInput';
// services
import { login } from '@services/sales-manager';
// utils
import { setSession, setSalesManager, setSalesManagerId } from '@utils/auth';
import { SVG } from '@assets/svg';
import { isEmpty, isValidEmail } from '@utils/functions';
// styles
import './login.scss';
//translations
import { useTranslation } from 'react-i18next';
import Header from '@components/ui/Header/Header';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';

const SalesMangerLogin = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [securitiyToken, setSecurityToken] = useState('');
  const [credentialsComplete, setCredentialsComplete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ username: '', password: '', securitiyToken: '' });
  const [invalidCredentialsError, setInvalidCredentialsError] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const [content, setContent] = useState(null);
  const [loginStep, setLoginStep] = useState(0);

  const {
    dispatchNotification,
    dispatchResetTracking,
    dispatchResetFilters,
    FORMSTATE,
    dispatchIsAuthenticated,
    PREFERENCES,
    getForms
  } = useRedux();

  const navigate = useNavigate();
  const preferences = PREFERENCES.get();

  let smLogin = getForms()['sm-login'];

  useEffect(() => {
    if (preferences.languageOptions && !preferences.language)
      PREFERENCES.updateLanguage(preferences.languageOptions.find(option => option.isDefault));
    if (preferences.language !== null && smLogin && smLogin[preferences.language.code])
      setContent(smLogin[preferences.language.code]);
  }, [preferences.languageOptions, preferences.language, smLogin]);

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target;
    setCredentials(prev => ({ ...prev, [name]: value }));
    if (invalidCredentialsError) {
      setError(prev => ({ ...prev, username: '', password: '' }));
      setInvalidCredentialsError(false);
      return;
    }
    !isEmpty(value) && setError(prev => ({ ...prev, [name]: '' }));
  };

  const handleClearUsername = () => {
    setCredentials(prev => ({ ...prev, username: '' }));
  };

  const handleClearSecurityToken = () => {
    setSecurityToken('');
  };

  useEffect(() => {
    dispatchResetTracking();
    FORMSTATE.reset();
    dispatchResetFilters();
  }, []);

  const handleNext = async e => {
    e.preventDefault();
    setSecurityToken('');
    if (isEmpty(credentials.username))
      setError(prev => ({ ...prev, username: t('sales_manager_login.empty_username') }));
    if (!isEmpty(credentials.username) && !isValidEmail(credentials.username))
      setError(prev => ({ ...prev, username: t('sales_manager_login.invalid_email') }));
    if (isEmpty(credentials.password))
      setError(prev => ({ ...prev, password: t('sales_manager_login.empty_password') }));
    if (!isEmpty(credentials.username) && !isEmpty(credentials.password) && isValidEmail(credentials.username)) {
      const prevCredentials = structuredClone(credentialsComplete);
      const currentCredentials = structuredClone({ ...credentials, securitiyToken });
      const areEquals =
        prevCredentials?.username === currentCredentials?.username &&
        prevCredentials?.password === currentCredentials?.password &&
        prevCredentials?.securitiyToken === currentCredentials?.securitiyToken;
      if (!areEquals) {
        setError({ username: '', password: '', securtiyToken: '' });
      }
      setLoginStep(loginStep + 1);
    }
  };

  const handleLogin = async e => {
    e.preventDefault();
    setError({ username: '', password: '', securtiyToken: '' });
    if (isEmpty(securitiyToken)) setError(prev => ({ ...prev, securitiyToken: t('sales_manager_login.empty_token') }));

    if (!isEmpty(securitiyToken)) setCredentialsComplete({ ...credentials, securitiyToken });
    try {
      setLoading(true);
      setInvalidCredentialsError(false);
      const updatedCredentials = { ...credentials, password: `${credentials.password}${securitiyToken}` };
      const loginResponse = await login(updatedCredentials);

      if (loginResponse.data.sessionId && loginResponse.data.expiresIn) {
        setSession({ sessionId: loginResponse.data.sessionId, expiresIn: loginResponse.data.expiresIn });
        setSalesManager({ username: credentials.username, expiresIn: loginResponse.data.expiresIn });
        setSalesManagerId({ id: loginResponse.data.salesManagerId, expiresIn: loginResponse.data.expiresIn });
        dispatchNotification({
          timer: true,
          status: 'success',
          title: t('sales_manager_login.success_login')
        });
        dispatchIsAuthenticated({ type: 'SM', isAdmin: loginResponse.data.role === 'ADMIN' });
        const savedState = sessionStorage.getItem('redirectState');
        const redirectTo = location?.state?.from?.pathname
          ? `${location?.state?.from?.pathname}${location?.state?.from?.search ?? ''}`
          : (savedState ?? '/');
        navigate(redirectTo, { replace: true });
        sessionStorage.removeItem('redirectState');
      }
    } catch (error) {
      setInvalidCredentialsError(true);
      setError({
        username: t('sales_manager_login.error_login'),
        password: t('sales_manager_login.error_login'),
        securitiyToken: t('sales_manager_login.error_login')
      });
      setLoginStep(0);
    } finally {
      setLoading(false);
    }
  };

  const disclaimer = disclaimer => {
    const regex = /\[(.*?)\]\((.*?)\)/g;
    // index % 3 === 0 → Regular text (should remain unchanged)
    // index % 3 === 1 → Link text (should be wrapped in <Link>)
    // index % 3 === 2 → URL (used as to in <Link> but not displayed directly)
    return disclaimer.split(regex).map((part, index, array) => {
      if (index % 3 === 1) {
        return (
          <Link
            key={index}
            to={array[index + 1]}
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {part}
          </Link>
        );
      } else if (index % 3 !== 2) {
        return part;
      }
      return null;
    });
  };

  return (
    <>
      <div className='salesManagerLogin-header'>
        <Header shouldDisplay isDark />
      </div>
      <div className='salesManagerLogin'>
        <div className='authentication'>
          <div className='back-button'>
            <LinkButton
              icon='navigation/arrow-left'
              text={content?.type?.buttonBack}
              onClickFn={() => {
                if (loginStep === 1) {
                  setLoginStep(0);
                } else {
                  navigate('/');
                }
              }}
            />
          </div>
          <span className='authentication-title'>{content?.type?.title}</span>
          <form className='authentication-form'>
            {loginStep === 0 && (
              <>
                <TextInput
                  label={content?.type?.usernameLabel}
                  name='username'
                  icon='action/user'
                  value={credentials.username}
                  onChangeFn={handleChange}
                  clearFn={handleClearUsername}
                  required
                  errorMessage={error.username}
                />
                <PasswordInput
                  label={content?.type?.passwordLabel}
                  name='password'
                  icon='action/key'
                  value={credentials.password}
                  onChangeFn={handleChange}
                  errorMessage={error.password}
                />
                <div className='button-container'>
                  <Button primary={true} text={content?.type?.buttonNextToken} onClickFn={handleNext} />
                </div>
              </>
            )}
            {loginStep === 1 && (
              <>
                <TextInput
                  label={content?.type?.tokenLabel}
                  name='securityToken'
                  icon='action/shield'
                  value={securitiyToken}
                  onChangeFn={event => {
                    setSecurityToken(event.target.value);
                  }}
                  clearFn={handleClearSecurityToken}
                  required
                  errorMessage={error.securitiyToken}
                />
                <div className='password-message'>
                  <div className='icon'>
                    <SVG icon='semantic/info-outline' />
                  </div>
                  <span>{disclaimer(content?.type?.disclaimer)}</span>
                </div>
                <div className='button-container'>
                  <Button primary={true} text={content?.type?.buttonAuthenticate} onClickFn={handleLogin} />
                </div>
              </>
            )}
          </form>
        </div>
        <div className='image-wrapper'>
          <img src={content?.type?.image} />
        </div>
      </div>
    </>
  );
};

export default SalesMangerLogin;
