import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const fetchAudios = async SMToken => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'audios' })}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);
  return response.data;
};
