import useRedux from './useRedux';
import useRules from './useRules';
import { useFormContext } from 'react-hook-form';
import { CONFIG_FLOW, CONFIG_STATUS } from '@utils/enums';
import Cookies from 'js-cookie';

const useRender = () => {
  const { getIsAuthenticated, getCurrentProject, getCurrentStepId } = useRedux();
  const { RULES, RULES_QUESTIONS } = useRules();
  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();

  // disable inputs variables
  const currentStepId = getCurrentStepId();
  const authenticatedUser = getIsAuthenticated();
  const configurationFlow = getCurrentProject()?.configurationFlow;
  const status = getCurrentProject()?.status;
  const projectOwner = getCurrentProject()?.sales_manager_id;
  const sales_manager_id = Cookies.get('salesManagerId');

  const isProjectOwner = authenticatedUser?.type === 'Client' || projectOwner === sales_manager_id;

  // status variables
  const isDraft = status === CONFIG_STATUS.Draft;
  const isActive = status === CONFIG_STATUS.Active;
  const isInactive = status === CONFIG_STATUS.Inactive;
  const isSubmitted = status === CONFIG_STATUS.Submitted;
  // flow variables
  const isGuided = configurationFlow === CONFIG_FLOW.Guided;
  const isRemote = configurationFlow === CONFIG_FLOW.Remote;

  const itemShouldRender = (renderCondition, item) => {
    if (renderCondition && RULES[renderCondition]) {
      return RULES[renderCondition]({ item });
    } else {
      return true;
    }
  };

  const innerQuestionShouldRender = ({ currentStepId, questionId, answerIndex }) => {
    if (currentStepId === 'exteriors' && questionId === 'pool' && answerIndex !== 0) {
      return RULES['poolDetails']();
    } else {
      return true;
    }
  };

  const questionShouldRender = renderCondition => {
    if (renderCondition && RULES_QUESTIONS[renderCondition]) {
      return RULES_QUESTIONS[renderCondition]();
    } else {
      return true;
    }
  };

  const getWatcherName = controllerId => {
    /**
     * Return the value of a given key, even if nested, passing the path separeted by dots
     * Example: root property -> "name"
     * Example: nested property -> "obj.name"
     **/

    return controllerId?.split('.').reduce((acc, part) => acc && acc[part], watcher);
  };

  const isElementSelected = ({ controllerId, item }) => {
    return getWatcherName(controllerId) === item?.id;
  };

  const checkIsDisabled = stepId => {
    const stepRules = {
      plot: () => authenticatedUser?.type === 'SM' && isGuided && (isDraft || isActive) && isProjectOwner,
      layout: () => authenticatedUser?.type === 'SM' && isGuided && (isDraft || isActive) && isProjectOwner,
      exteriors: () =>
        ((authenticatedUser?.type === 'SM' && isGuided && isDraft) ||
          (authenticatedUser?.type === 'SM' && isGuided && isActive) ||
          (authenticatedUser?.type === 'SM' && isRemote && isActive) ||
          (authenticatedUser?.type === 'Client' && isGuided && isActive)) &&
        isProjectOwner,
      interiors: () =>
        ((authenticatedUser?.type === 'SM' && isGuided && isDraft) ||
          (authenticatedUser?.type === 'SM' && isGuided && isActive) ||
          (authenticatedUser?.type === 'SM' && isRemote && isActive) ||
          (authenticatedUser?.type === 'Client' && isGuided && isActive)) &&
        isProjectOwner
    };

    const rule = stepRules[stepId ?? currentStepId];
    // condition will return true for the scenarios that are possible to be edited
    // so return must negate, since we are evaluating if the input should be disabled
    return rule ? !rule() : true;
  };

  const reviewButtonsToRender = () => {
    if (currentStepId !== 'review') return;
    if (authenticatedUser?.type === 'SM') {
      if (isGuided && isDraft) {
        return { save: true, submit: true, exit: false };
      } else if (isActive) {
        return { save: true, submit: true, exit: false };
      } else if (isInactive) {
        return { save: false, submit: true, exit: true };
      } else if (isSubmitted || !isProjectOwner) {
        return { save: false, submit: false, exit: true };
      }
    } else if (authenticatedUser?.type === 'Client') {
      if (isGuided && isActive) {
        return { save: true, submit: true, exit: false };
      } else if (isRemote && isActive) {
        return { save: false, submit: true, exit: false };
      } else if (isInactive) {
        return { save: false, submit: true, exit: false };
      } else if (isSubmitted) {
        return { save: false, submit: false, exit: false };
      }
    }
  };

  return {
    itemShouldRender,
    innerQuestionShouldRender,
    questionShouldRender,
    getWatcherName,
    isElementSelected,
    checkIsDisabled,
    reviewButtonsToRender
  };
};

export default useRender;
