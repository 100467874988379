import { SVG } from '@assets/svg';
import './MenuItem.scss';
import { useState, useEffect } from 'react';

const MenuItem = ({ destructive, text, icon, selected, onClickFn, style, ...props }) => {
  const menuItemClass = `menu-item${destructive ? ' destructive' : ''}${selected ? ' selected' : ''}${icon ? ' icon' : ''}`;
  const [selection, setSelection] = useState(selected);

  return (
    <button
      class={menuItemClass}
      onClick={() => {
        setSelection(!selection);
        onClickFn();
      }}
      style={style}
    >
      {text}
      {icon && selected && <SVG icon={icon} />}
    </button>
  );
};

export default MenuItem;
