// components
import { SVG } from '@assets/svg';
// styles
import './VideoToggle.scss';

const VideoToggle = ({ icon }) => {
  return <div className='video-toggle'>{<SVG icon={icon} />}</div>;
};

export default VideoToggle;
