import { useState, useEffect, useRef } from 'react';
// Import the Vimeo Player library
import Player from '@vimeo/player';
import '../index.scss';
import VideoControl from '@components/ui/VideoControl/VideoControl';
import VideoToggle from '@components/ui/VideoControl/VideoToggle';

const VideoPlayer = ({ vimeoID }) => {
  const iframeRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayToggle, setDisplayToggle] = useState(false);
  const [icon, setIcon] = useState('audio/play');

  useEffect(() => {
    // Initialize the Vimeo Player instance
    const vimeoPlayer = new Player(iframeRef.current, {
      id: vimeoID,
      autoplay: true, // Autoplay when the video is rendered
      muted: true, // Mute the video
      background: true,
      loop: true
    });

    setPlayer(vimeoPlayer);

    // Event listener to detect if the video is playing
    vimeoPlayer.on('play', () => {
      setIsPlaying(true);
      setIcon('audio/pause');
    });

    vimeoPlayer.on('pause', () => {
      setIsPlaying(false);
      setIcon('audio/play');
    });

    return () => {
      vimeoPlayer.destroy();
    };
  }, [vimeoID]);

  const handlePlayPause = () => {
    if (player) {
      if (isPlaying) {
        player.pause();
      } else {
        player.play();
      }
    }
  };

  useEffect(() => {
    if (displayToggle) {
      setTimeout(() => {
        setDisplayToggle(false);
      }, 800);
    }
  }, [displayToggle]);

  return (
    <>
      <script src='https://player.vimeo.com/api/player.js'></script>

      <div className='full-image'>
        <iframe
          ref={iframeRef}
          title={vimeoID}
          id='vimeo-player'
          src={`https://player.vimeo.com/video/${vimeoID}?api=1&player_id=vimeo-player&title=0&byline=0&portrait=0&background=1`}
          allow='autoplay; fullscreen; '
          className='full-image'
        ></iframe>

        <div
          className='video-overlay'
          onClick={() => {
            setDisplayToggle(true);
            handlePlayPause();
          }}
        ></div>
      </div>

      {displayToggle && (
        <div className='display-toggle'>
          <VideoToggle icon={icon} />
        </div>
      )}

      <div className='play-pause-button'>
        <VideoControl onClickFn={handlePlayPause} icon={icon} />
      </div>
    </>
  );
};

export default VideoPlayer;
