import { formStateActions } from './formState-slice';

export const updateFormState = ({ dispatch, stepId, obj }) => {
  dispatch(formStateActions.updateFormState({ stepId, obj }));
};

export const updateAllFormState = ({ dispatch, form }) => {
  dispatch(formStateActions.updateAllFormState({ form }));
};

export const updateStepFinished = (dispatch, stepFinished) => {
  dispatch(formStateActions.updateStepFinished({ stepFinished }));
};

export const updateStepsFinished = (dispatch, stepsFinished) => {
  dispatch(formStateActions.updateStepsFinished({ stepsFinished }));
};

export const updateStepChanged = (dispatch, stepChanged) => {
  dispatch(formStateActions.updateStepChanged({ stepChanged }));
};

export const resetFormState = dispatch => {
  dispatch(formStateActions.reset());
};
