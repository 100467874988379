import { createSlice } from '@reduxjs/toolkit';

const partnersSlice = createSlice({
  name: 'partners',
  initialState: {},
  reducers: {
    setPartners: (state, action) => {
      return { ...action.payload };
    }
  }
});

const partnersAction = partnersSlice.actions;
export { partnersSlice, partnersAction };
