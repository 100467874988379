import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useInView } from 'react-intersection-observer';
import '../../shared.scss';
import { MaterialController } from '@components/answers/controllers/MaterialController';
import SectionMaterial from '@components/answers/SectionMaterial';
import useRedux from '@hooks/useRedux';

const MaterialContainer = ({
  suite,
  question,
  onClickFn,
  control,
  disabled,
  index,
  onVisibilityChange,
  getCurrentStepId
}) => {
  const { ref, entry } = useInView({
    threshold: Array.from({ length: 101 }, (_, i) => i / 100)
  });

  const prevRatio = useRef(0);

  useEffect(() => {
    if (entry) {
      const visibilityRatio = entry.intersectionRatio || 0;
      if (Math.abs(visibilityRatio - prevRatio.current) > 0.1) {
        prevRatio.current = visibilityRatio;
        onVisibilityChange(index, visibilityRatio); // Notify parent
      }
    }
  }, [entry, index, onVisibilityChange]);

  return (
    <div ref={ref} className='materialContainer'>
      <div className='materialWrapper'>
        <span className='title'>{suite.suite.title}</span>
        <MaterialController
          control={control}
          currentStepId={getCurrentStepId()}
          division={suite.suite}
          question={question}
          onClickFn={onClickFn}
          Component={SectionMaterial}
          disabled={disabled}
        />
      </div>
      <div className='materialWrapper'>
        <span className='title'>{suite.bathroom.title}</span>
        <MaterialController
          control={control}
          currentStepId={getCurrentStepId()}
          division={suite.bathroom}
          question={question}
          onClickFn={onClickFn}
          Component={SectionMaterial}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const IntimateArea = ({ control, suites, question, onClickFn, disabled }) => {
  const { getCurrentStepId, dispatchSetIntimateIndex, getTracking, dispatchSetCurrentQuestionIdAndSlideshowIndex } =
    useRedux();
  const [visibilityMap, setVisibilityMap] = useState({});

  // Update visibility state when a container changes visibility
  const handleVisibilityChange = useCallback((index, visibilityRatio) => {
    setVisibilityMap(prev => {
      const prevRatio = prev[index] || 0;
      if (Math.abs(prevRatio - visibilityRatio) > 0.1) {
        return { ...prev, [index]: visibilityRatio };
      }
      return prev;
    });
  }, []);

  // Debounce function to reduce frequent updates
  const debouncedUpdateMostVisibleContainer = useMemo(
    () =>
      debounce(() => {
        const mostVisibleEntry = Object.entries(visibilityMap)
          .filter(([_, ratio]) => ratio > 0)
          .sort((a, b) => b[1] - a[1])[0]; // Sort by visibility

        if (mostVisibleEntry) {
          const [mostVisibleIndex] = mostVisibleEntry;
          console.log(`Most visible container: ${mostVisibleIndex}`);

          if (getTracking().intimateIndex !== +mostVisibleIndex) {
            // dispatchUpdateCurrentQuestionId('intimateAreas');
            // dispatchSetSlideshowIndex(0);
            dispatchSetCurrentQuestionIdAndSlideshowIndex('intimateAreas', 0);
            dispatchSetIntimateIndex(+mostVisibleIndex);
          }
        }
      }, 200), // Debounce delay: 200ms
    [visibilityMap]
  );

  useEffect(() => {
    debouncedUpdateMostVisibleContainer();
  }, [visibilityMap, debouncedUpdateMostVisibleContainer]);

  return suites.map((suite, index) => (
    <MaterialContainer
      key={index}
      index={index}
      suite={suite}
      question={question}
      onClickFn={onClickFn}
      control={control}
      disabled={disabled}
      onVisibilityChange={handleVisibilityChange}
      getCurrentStepId={getCurrentStepId}
    />
  ));
};
