import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// hooks
import useRedux from '@hooks/useRedux';
import useModals from '@hooks/useModals';
// components
import AdditionalServicesCard from '@components/ui/Cards/AdditionalServicesCard/AdditionalServicesCard';
import Button from '@components/ui/Buttons/Button/Button';
import MultiChoiceModal from '@components/ui/Overlays/Modals/MultiChoiceModal/MultiChoiceModal';
import Portal from '@components/ui/Overlays/Portal/Portal';
import Slideshow from '@components/image-container/slideshow/Slideshow';
import ServicesPartnersModal from '@components/ui/Overlays/Modals/ServicesPartners/ServicesPartnersModal';
// styles
import './ProjectSubmitted.scss';
// services
import { createRequest } from '@services/client';
// utils
import { ACTIONS } from '@utils/enums';
import Header from '@components/ui/Header/Header';
import review1 from '@assets/img/review1.png';
import review2 from '@assets/img/review2.png';
import review3 from '@assets/img/review3.png';
import review4 from '@assets/img/review4.png';
import review5 from '@assets/img/review5.png';

const ProjectSubmitted = ({ setCustomFormStep, setProjectSubmittedPageOpen }) => {
  const {
    getProjectSubmittedContent,
    dispatchResetForm,
    dispatchResetTracking,
    dispatchProjectInfo,
    getCurrentProject,
    getTracking,
    FORMSTATE,
    dispatchResetFilters,
    PREFERENCES
  } = useRedux();
  const [isModalOpen, setModalOpen] = useModals();
  const [openServicePartner, setOpenServicePartner] = useState(null);
  const navigate = useNavigate();
  const [content, setContent] = useState(getProjectSubmittedContent()?.[PREFERENCES?.get()?.language?.code]);
  const projectInfo =
    getTracking()?.remoteInformationPage?.status === 'submissionSM'
      ? getTracking()?.remoteInformationPage?.pageInformation?.projectInfo
      : getCurrentProject();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_token = Cookies.get('sessionId');
  const sales_manager_id = Cookies.get('salesManagerId');
  const imagesReview = [review1, review2, review3, review4, review5];

  useEffect(() => {
    if (PREFERENCES.get().language !== null)
      setContent(getProjectSubmittedContent()?.[PREFERENCES?.get()?.language?.code]);
  }, [PREFERENCES]);

  // open and close modal
  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleClick = async action => {
    // if (action === ACTIONS.NewDesign) {
    //   dispatchResetForm({ reset: true, trigger: 'RESET_STEP_PLOT' });
    //   setProjectSubmittedPageOpen(false);
    //   setCustomFormStep(0);
    //   FORMSTATE.reset();
    //   dispatchResetFilters();

    //   await createRequest(
    //     {
    //       clientEmail: getCurrentProject().client,
    //       salesManagerEmail: sales_manager,
    //       salesManagerId: sales_manager_id,
    //       projectDefaultLanguage: PREFERENCES?.get()?.language?.code,
    //       projectPrefix: getCurrentProject().project ?? 'TCT'
    //     },
    //     sales_manager_token
    //   )
    //     .then(async response => {
    //       if (response) {
    //         console.log('Project saved to DB', response);
    //         dispatchProjectInfo(response.projectInfo);
    //         navigate(`/journey/${response.projectInfo.id}`);
    //       }
    //     })
    //     .catch(error => {
    //       console.log('Error saving project do DB', error);
    //     });
    // }
    if (action === ACTIONS.Exit) {
      dispatchResetTracking();
      FORMSTATE.reset();
      PREFERENCES.reset();
      dispatchResetFilters();
      navigate(`/dashboard?clientId=${getCurrentProject().clientId.id}`);
      dispatchProjectInfo(null);
    }
    return;
  };

  return (
    <div className='confirm-interest-container'>
      <Header shouldDisplay isDark />
      <div className='confirm-interest-wrapper'>
        <div className='confirm-interest-content'>
          <div className='confirm-interest-content-text'>
            <div className='confirm-interest-content-text-header'>
              <span className='id'>{projectInfo.id}</span>
              <div className='confirm-interest-content-text-header-content'>
                <span className='title'>
                  {content.title.replace(
                    '{{projectName}}',
                    projectInfo?.configurationName ?? projectInfo?.configurationDefaultName
                  )}
                </span>
                <span className='subtitle'>{content.description}</span>
              </div>
              <AdditionalServicesCard
                card={content.card}
                onClickFn={() => {
                  toggleModal();
                }}
              ></AdditionalServicesCard>
            </div>
            <div className='confirm-interest-content-text-footer'>
              {content.actions.map(action => {
                const type = action.type.toLowerCase();
                if (type === 'primary') {
                  return (
                    <Button
                      primary
                      text={action.label}
                      onClickFn={() => {
                        handleClick(action.action);
                      }}
                    ></Button>
                  );
                } else if (type === 'secondary') {
                  return (
                    <Button
                      secondary
                      text={action.label}
                      onClickFn={() => {
                        handleClick(action.action);
                      }}
                    ></Button>
                  );
                } else if (type === 'ghost') {
                  return (
                    <Button
                      ghost
                      text={action.label}
                      onClickFn={() => {
                        handleClick(action.action);
                      }}
                    ></Button>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className='confirm-interest-wrapper-image'>
          {/* <Slideshow images={imagesReview} autoSlide></Slideshow> */}
          <img src={content?.background} />
        </div>
      </div>
      {isModalOpen && (
        <Portal>
          {!openServicePartner ? (
            <MultiChoiceModal
              onClose={() => {
                toggleModal();
              }}
              openServicePartner={partnerId => {
                setOpenServicePartner(partnerId);
              }}
            ></MultiChoiceModal>
          ) : (
            <ServicesPartnersModal
              partnerId={openServicePartner}
              onClose={() => setOpenServicePartner(null)}
            ></ServicesPartnersModal>
          )}
        </Portal>
      )}
    </div>
  );
};

export default ProjectSubmitted;
