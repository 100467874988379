import { useEffect } from 'react';
// hooks
import useAudio from '@hooks/useAudio.js';
// styles
import './AudioPlayer.scss';

const Player = ({ audio, audioType, audioPlaying, setAudioPlaying }) => {
  const [playing, play, pause] = useAudio(audio.url);

  useEffect(() => {
    if (!audioPlaying || audioType !== audio.id) {
      pause();
    } else {
      play();
    }
  }, [audioType, audioPlaying]);

  useEffect(() => {
    if (!playing) {
      setAudioPlaying(false);
    }
  }, [playing]);

  return <></>;
};

export default Player;
