import React, { useCallback, useEffect, useState, useRef } from 'react';
import useRedux from '@hooks/useRedux';
import PropTypes from 'prop-types';
import './RangeSlider.scss';

const valuetext = (value, currency) => {
  if (typeof value === 'number') {
    const formattedValue = (value / 1000000).toFixed(value >= 1000000 ? 1 : 0);
    return currency?.toLowerCase() === 'euro' ? `${parseFloat(formattedValue)}M €` : `${parseFloat(formattedValue)}M $`;
  }
};

const RangeSlider = ({ min, max, step, onSelect, value }) => {
  const { PREFERENCES } = useRedux();
  const currency = PREFERENCES.get().currency;

  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(value => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    if (value) {
      setMinVal(value[0]);
      minValRef.current = value[0];
      setMaxVal(value[1]);
      maxValRef.current = value[1];
    }
  }, [value]);

  return (
    <div>
      <div className='slider'>
        <div className='slider_values'>
          <div className='slider__left-value'>{valuetext(minVal, currency)}</div>
          <div className='slider__right-value'>{valuetext(maxVal, currency)}</div>
        </div>
        <div className='slider__track' />
        <div ref={range} className='slider__range' />
        <input
          type='range'
          min={min}
          max={max}
          value={minVal}
          step={step}
          onChange={event => {
            const value = Math.min(Number(event.target.value), maxVal - step); // checks minVal is inferior by one step to maxVal
            setMinVal(value);
            onSelect([value, maxVal]);
            minValRef.current = value;
          }}
          className='thumb thumb--left'
          style={{ zIndex: minVal > max - 100 && '5' }}
        />
        <input
          type='range'
          min={min}
          max={max}
          value={maxVal}
          step={step}
          onChange={event => {
            const value = Math.max(Number(event.target.value), minVal + step); // checks maxVal is superior by one to minVal
            setMaxVal(value);
            onSelect([minVal, value]);
            maxValRef.current = value;
          }}
          className='thumb thumb--right'
        />
      </div>
    </div>
  );
};

// RangeSlider.propTypes = {
//   min: PropTypes.number.isRequired,
//   max: PropTypes.number.isRequired,
//   onChange: PropTypes.func.isRequired
// };

export default RangeSlider;
