import { pricingAction } from './pricing-slice';
import { getPricing, getFiltersValuesMinAndMax } from '@services/pricing';
import Cookies from 'js-cookie';

export const setPrice = async (dispatch, formState, currency) => {
  try {
    const response = await getPricing(formState, currency, Cookies.get('sessionId'));
    dispatch(pricingAction.setPrice(response.price));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};

export const resetPrice = async (dispatch, queryParams) => {
  try {
    dispatch(pricingAction.resetPrice());
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};

export const setFilterValues = async (dispatch, SMToken) => {
  try {
    const response = await getFiltersValuesMinAndMax(SMToken);
    dispatch(pricingAction.setFilterValues(response));
  } catch (error) {
    console.error('Error fetching filter pricing:', error);
  }
};
