// Listener to track if cookies are set or not expired

import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useRedux from './useRedux';
import Cookies from 'js-cookie';

const getCookie = () => {
  return !!Cookies.get('sessionId') && !!Cookies.get('salesManager');
};

export const useAuthCookieWatcher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getIsAuthenticated } = useRedux();
  const pathsNotToRedirect = ['/', '/sales-manager/login', '/verifyEmailRemote'];

  useEffect(() => {
    const checkCookie = () => {
      if (!getCookie() && !pathsNotToRedirect.includes(location?.pathname) && getIsAuthenticated()?.type !== 'Client') {
        navigate('/sales-manager/login');
      }
    };

    document.addEventListener('click', checkCookie);
    return () => document.removeEventListener('click', checkCookie);
  }, [navigate]);
};
