import { useState, useEffect } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import Information from '@components/ui/Information';
import Dropdown from '@components/ui/Dropdown/Dropdown';
import Button from '@components/ui/Buttons/Button/Button';
import RangeSlider from '@components/ui/Slider/RangeSlider';
import RadioButton from '@components/ui/RadioButton/RadioButton';
import Portal from '@components/ui/Overlays/Portal/Portal';
import BasicModal from '@components/ui/Overlays/Modals/BasicModal/BasicModal';
import Cell from '@components/ui/Cells/Cell';
import DetailedCell from '@components/ui/Cells/DetailedCell';
// hooks
import useRedux from '@hooks/useRedux';
import useFilters from '@hooks/useFilters';
import useModals from '@hooks/useModals';
// styling
import './FiltersSorting.scss';
// utils
import { isEmpty } from '@utils/functions';
import {
  getNrRoomsFilteredByArea,
  getHouseTypesFilteredByArea,
  getNrRoomsFilteredByHouseType,
  getHouseTypesFilteredByNrRooms,
  getAreasFilteredByNrRoomsAndHouseType
} from '@utils/filters';
import { getArea } from '@utils/areas';
import { isObjectsEquals } from '@utils/utils';
import { MODAL_TYPE, PLOT_STATUS } from '@utils/enums';
// translations
import { useTranslation } from 'react-i18next';

const FiltersSorting = ({ onView }) => {
  const { t } = useTranslation();
  const plotAreaOptions = ['Min', 2000, 4000, 6000, 8000, 10000, 'Max'];
  const sortLabels = [
    { label: t('filters.sort_by_name'), value: 'Name' },
    { label: t('filters.sort_by_ascending'), value: 'Size (Ascending Order)' },
    { label: t('filters.sort_by_descending'), value: 'Size (Descending Order)' }
  ];
  const {
    getForms,
    dispatchFilters,
    getFilters,
    getHouses,
    getModals,
    PREFERENCES,
    getHouseTypes,
    dispatchNotification,
    PRICING
  } = useRedux();
  const { getPlotsFiltered, getPlotsByPlotArea } = useFilters();
  const lang = PREFERENCES.get().language?.code;
  const houseTypes = getHouseTypes()[lang];
  const nrRooms = getForms().journey[lang].steps[1].questions[0].answers[0].items;
  const plots = getForms().journey[lang].steps[0].questions[1].answers[0].items;
  const houses = getHouses();
  const priceMin = PRICING.get().filterValues.minValue;
  const priceMax = PRICING.get().filterValues.maxValue;
  const units = PREFERENCES.get().units;

  let plotsFiltered = plots;
  let plotsByArea = plots;
  let nrRoomsFiltered = nrRooms.filter(room => plots.some(plot => plot.houses.some(house => house.includes(room.id))));
  let houseTypesFiltered = houseTypes.filter(houseType =>
    plots.some(plot => plot.houses.some(house => house.includes(houseType.id)))
  );
  let plotMinAreaOptionsFiltered = plotAreaOptions;
  let plotMaxAreaOptionsFiltered = plotAreaOptions;

  const [filterState, setFilterState] = useState(
    !isEmpty(getFilters())
      ? getFilters()
      : {
          sortBy: 'Name',
          plotMinArea: 'Min',
          plotMaxArea: 'Max',
          priceMin,
          priceMax
        }
  );

  const [isModalOpen, setModalOpen] = useModals();

  // open and close modal
  const toggleModal = () => setModalOpen(!isModalOpen);

  // filter bedrooms options by area && filter houseType options by area
  if (filterState?.plotMinArea !== 'Min' || filterState?.plotMaxArea !== 'Max') {
    plotsByArea = getPlotsByPlotArea(filterState, plots);
    nrRoomsFiltered = getNrRoomsFilteredByArea(plotsByArea, nrRoomsFiltered);
    houseTypesFiltered = getHouseTypesFilteredByArea(plotsByArea, houseTypesFiltered);
  }

  // filter bedrooms options by houseType
  const housesFilteredByHouseType = houses.filter(house =>
    filterState?.houseType?.some(houseType => house.houseType === houseType)
  );
  if (filterState?.houseType?.length > 0)
    nrRoomsFiltered = getNrRoomsFilteredByHouseType(housesFilteredByHouseType, nrRoomsFiltered, plotsByArea);

  // filter houseType options by bedrooms
  const housesFilteredByTypology = houses.filter(house =>
    filterState?.bedrooms?.some(bedroom => house.typology === bedroom)
  );
  if (filterState?.bedrooms?.length > 0)
    houseTypesFiltered = getHouseTypesFilteredByNrRooms(housesFilteredByTypology, houseTypesFiltered, plotsByArea);

  // filter area options by bedrooms && filter area options by houseType
  plotsFiltered = getAreasFilteredByNrRoomsAndHouseType(
    filterState,
    plotsFiltered,
    housesFilteredByTypology,
    housesFilteredByHouseType
  );
  plotMinAreaOptionsFiltered = plotMinAreaOptionsFiltered.filter(area =>
    plotsFiltered.some(
      plot => (area <= plot.area && plotsFiltered.some(otherPlot => area > otherPlot.area)) || area === 'Min'
    )
  );
  plotMaxAreaOptionsFiltered = plotMaxAreaOptionsFiltered.filter(area =>
    plotsFiltered.some(
      plot => (area >= plot.area && plotsFiltered.some(otherPlot => area < otherPlot.area)) || area === 'Max'
    )
  );

  const numberLabel = () => {
    const plotCounter = getPlotsFiltered(
      filterState,
      plots.filter(el => el.status !== PLOT_STATUS.NotAvailable && el.status !== PLOT_STATUS.Sold)
    ).length;
    if (plotCounter === 0) {
      return '0';
    } else {
      return ` ${plotCounter}`;
    }
  };

  useEffect(() => {
    if (!isObjectsEquals(filterState, getFilters())) dispatchFilters({ filters: filterState });
  }, [filterState]);

  useEffect(() => {
    if (!isObjectsEquals(filterState, getFilters()) && !isEmpty(getFilters())) setFilterState(getFilters());
  }, [getFilters()]);

  const getMaxAndMinArea = houseType => {
    const units = PREFERENCES.get().units;
    let allOptionsFromHouseType = houses.filter(house => house.houseType === houseType);

    if (filterState.bedrooms && !isEmpty(filterState.bedrooms)) {
      allOptionsFromHouseType = allOptionsFromHouseType.filter(house =>
        filterState?.bedrooms?.includes(house.typology)
      );
    }
    const allAreasFromHouseType = allOptionsFromHouseType.map(house => house.area).filter(area => area);

    if (allAreasFromHouseType.length === 0) return [];

    const maxArea = getArea(units, Math.max(...allAreasFromHouseType));
    const minArea = getArea(units, Math.min(...allAreasFromHouseType));

    return [minArea, maxArea];
  };

  return (
    <>
      <div className='filter-header'>
        {numberLabel() !== '0' && (
          <IconButton
            size={'small'}
            onImageBg={false}
            icon={'semantic/close'}
            onClickFn={() => {
              dispatchFilters({ filters: filterState });
              onView();
            }}
          />
        )}
      </div>
      <div className='filter-content'>
        <div className='filter-title'>
          <span className='headline'>{t('filters.filters_title')}</span>
          <span className='description'>{t('filters.filters_subtitle')}</span>
        </div>
        <div className='question-title'>
          <span>{t('filters.filter_area')}</span>
          <div className='dropdown'>
            <Dropdown
              label={t('filters.filter_area_min')}
              options={plotMinAreaOptionsFiltered}
              optionsToDisplay={plotMinAreaOptionsFiltered.map(option => getArea(units, option))}
              onChange={item => {
                if (filterState?.plotMaxArea && item >= filterState.plotMaxArea) {
                  const { plotMaxArea, ...newFilterState } = filterState;
                  setFilterState({
                    ...newFilterState,
                    plotMinArea: item,
                    plotMaxArea: 'Max'
                  });
                } else {
                  setFilterState({ ...filterState, plotMinArea: item });
                }
              }}
              value={filterState?.plotMinArea ? getArea(units, filterState.plotMinArea) : 'Min'}
            ></Dropdown>
            <Dropdown
              label={t('filters.filter_area_max')}
              options={plotMaxAreaOptionsFiltered}
              optionsToDisplay={plotMaxAreaOptionsFiltered.map(option => getArea(units, option))}
              onChange={item => {
                if (filterState?.plotMinArea && item <= filterState.plotMinArea) {
                  const { plotMinArea, ...newFilterState } = filterState;
                  setFilterState({
                    ...newFilterState,
                    plotMinArea: 'Min',
                    plotMaxArea: item
                  });
                } else {
                  setFilterState({ ...filterState, plotMaxArea: item });
                }
              }}
              value={filterState?.plotMaxArea ? getArea(units, filterState.plotMaxArea) : 'Max'}
            ></Dropdown>
          </div>
        </div>

        <div className='question-title'>
          <span>{t('filters.filter_typology')}</span>
          <div className='bedroom-options'>
            {nrRooms.map(room => (
              <Cell.Root
                item={room}
                isSelected={filterState?.bedrooms?.includes(room.id) || false}
                disabled={
                  nrRoomsFiltered !== undefined &&
                  !nrRoomsFiltered?.includes(room) &&
                  !filterState?.bedrooms?.includes(room.id)
                }
                onClickFn={() => {
                  if (!filterState?.bedrooms?.includes(room.id)) {
                    setFilterState(prevState => ({
                      ...prevState,
                      bedrooms: [...(prevState?.bedrooms || []), room.id]
                    }));
                  } else {
                    filterState.bedrooms.filter(bedroom => bedroom !== room.id).length > 0
                      ? setFilterState(prevState => {
                          return {
                            ...prevState,
                            bedrooms: prevState.bedrooms.filter(bedroom => bedroom !== room.id)
                          };
                        })
                      : (() => {
                          const { bedrooms, ...newState } = filterState;
                          setFilterState(newState);
                        })();
                  }
                }}
              >
                <Cell.Text isSelected={filterState?.bedrooms?.includes(room.id) || false}>{room.label}</Cell.Text>
              </Cell.Root>
            ))}
          </div>
        </div>
        <div className='question-title'>
          <span>{t('filters.filter_house_type')}</span>
          <div className='question-information'>
            <Information.Root onClickFn={toggleModal}>
              <Information.Title>{t('filters.card_title')}</Information.Title>
              <Information.Description>{t('filters.card_description')}</Information.Description>
            </Information.Root>
          </div>
          <div className='house-type-options'>
            {houseTypes.map(house => {
              const isDisabled = houseTypesFiltered !== undefined && !houseTypesFiltered.includes(house);
              return (
                <DetailedCell.Root
                  item={house}
                  isSelected={filterState?.houseType?.includes(house.id) || false}
                  disabled={isDisabled}
                  onClickFn={() => {
                    if (!filterState?.houseType?.includes(house.id)) {
                      setFilterState(prevState => ({
                        ...prevState,
                        houseType: [...(prevState?.houseType || []), house.id]
                      }));
                    } else {
                      filterState.houseType.filter(type => type !== house.id).length > 0
                        ? setFilterState(prevState => {
                            return {
                              ...prevState,
                              houseType: prevState.houseType.filter(type => type !== house.id)
                            };
                          })
                        : (() => {
                            const { houseType, ...newState } = filterState;
                            setFilterState(newState);
                          })();
                    }
                  }}
                >
                  <div className={`image-wrap${isDisabled ? ' disabled' : ''}`}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                      <DetailedCell.Title>{house.label}</DetailedCell.Title>

                      <DetailedCell.Row>
                        <DetailedCell.Description regular>
                          {`${t('filters.house_types_description_max')}: ${!isEmpty(getMaxAndMinArea(house.id)) ? getMaxAndMinArea(house.id)[1] : t('filters.house_types_description_none')}`}
                        </DetailedCell.Description>
                      </DetailedCell.Row>
                      <DetailedCell.Row>
                        <DetailedCell.Description regular>
                          {`${t('filters.house_types_description_min')}: ${!isEmpty(getMaxAndMinArea(house.id)) ? getMaxAndMinArea(house.id)[0] : t('filters.house_types_description_none')}`}
                        </DetailedCell.Description>
                      </DetailedCell.Row>
                    </div>

                    <DetailedCell.Image
                      url={house.icon}
                      altText={house.label}
                      disabled={houseTypesFiltered !== undefined && !houseTypesFiltered.includes(house)}
                    />
                  </div>
                </DetailedCell.Root>
              );
            })}
          </div>
        </div>
        {isModalOpen && (
          <Portal>
            <BasicModal
              currentStepNr={1}
              modals={getModals()[MODAL_TYPE.HouseTypes]?.[PREFERENCES.get().language.code].content}
              onClose={toggleModal}
            ></BasicModal>
          </Portal>
        )}
        <div className='question-title'>
          <span>{t('filters.filter_price')}</span>
          <RangeSlider
            min={priceMin}
            max={priceMax}
            step={100000}
            onSelect={([min, max]) => {
              setFilterState({ ...filterState, priceMin: min, priceMax: max });
            }}
            value={filterState?.priceMin ? [filterState.priceMin, filterState.priceMax] : [priceMin, priceMax]}
          />
        </div>
      </div>
      <div className='sorting-content'>
        <div className='sorting-title'>
          <span className='headline'>{t('filters.sort_title')}</span>
        </div>
        <div className='question-title'>
          <span>{t('filters.sort_by')}</span>
          {sortLabels.map(sort => {
            return (
              <RadioButton
                name='sorting'
                id={sort.value}
                label={sort.label}
                onSelect={() => setFilterState({ ...filterState, sortBy: sort.value })}
                value={filterState?.sortBy == sort.value ? filterState?.sortBy : false}
              />
            );
          })}
        </div>
      </div>
      <div className='filter-footer'>
        <Button
          secondary
          text={t('filters.button_clear')}
          onClickFn={() => {
            setFilterState({ sortBy: 'Name', plotMinArea: 'Min', plotMaxArea: 'Max' });
            dispatchFilters({ filters: {} });
          }}
        />
        {numberLabel() === '0' ? (
          <Button
            primary
            text={t('filters.no_results')}
            onClickFn={() => {
              dispatchNotification({
                timer: true,
                type: 'toast',
                status: 'error',
                title: t('filters.no_results_warning')
              });
            }}
          />
        ) : (
          <Button
            primary
            text={t('filters.button_view')}
            number={numberLabel()}
            onClickFn={() => {
              dispatchFilters({ filters: filterState });
              onView();
            }}
          />
        )}
      </div>
    </>
  );
};

export default FiltersSorting;
