import { authAction } from './auth-slice';

export const setIsAuthenticated = async (dispatch, state) => {
  try {
    dispatch(authAction.setIsAuthenticated(state));
  } catch (error) {
    console.log('ERROR setting auth on redux', error);
  } finally {
  }
};
