import { SignJWT, importPKCS8 } from 'jose';
import { PRIVATE_KEY } from '../../config';

const PRIVATE_KEY_FORMATTED = PRIVATE_KEY?.replace(/\\n/g, '\n');

let cachedToken = null;
let tokenExpiration = null;

const generateNewToken = async () => {
  try {
    const privateKey = await importPKCS8(PRIVATE_KEY_FORMATTED, 'RS256');

    const expirationTime = Math.floor(Date.now() / 1000) + 7200; // Expira em 2 horas

    const jwt = await new SignJWT({ service: 'frontend' })
      .setProtectedHeader({ alg: 'RS256', typ: 'JWT' })
      .setIssuedAt()
      .setExpirationTime('2h')
      .sign(privateKey);

    cachedToken = jwt;
    tokenExpiration = expirationTime;

    return jwt;
  } catch (error) {
    console.error('Error generating JWT:', error);
    throw error;
  }
};

export const generateToken = async () => {
  // Verifica se já existe um token válido em cache
  if (cachedToken && tokenExpiration && Date.now() / 1000 < tokenExpiration) {
    return cachedToken;
  }

  // Se não existir ou expirou, gera um novo
  return await generateNewToken();
};
