import { useState, useRef, useEffect } from 'react';
import { SVG } from '@assets/svg';
//hooks
import { useNavigate, useLocation } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
//styling
import './header.scss';
import { Popper } from '@mui/base/Popper';
import MenuItem from '@components/ui/MenuItem/MenuItem';
//frameworks
import { useTranslation } from 'react-i18next';
//utils
import { clearSession } from '@utils/auth';

const Header = ({
  shouldDisplay,
  isDark,
  logoOnly,
  noLogin,
  audioBtn,
  disableClickableLogo,
  dashboardIsCollapsed,
  hideLanguage
}) => {
  const { PREFERENCES, dispatchNotification, dispatchIsAuthenticated, getIsAuthenticated } = useRedux();
  const navigate = useNavigate();
  const [audioMuted, toggleAudio] = audioBtn || [];
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const [anchorLanguage, setAnchorLanguage] = useState(null);
  const openLanguage = Boolean(anchorLanguage);
  const idLanguage = openLanguage ? 'simple-popper' : undefined;
  const popperRef = useRef(null); // Reference to the popper content
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const { t } = useTranslation();
  const authenticatedUser = getIsAuthenticated();
  const isAdmin = getIsAuthenticated()?.isAdmin;
  const location = useLocation();

  useEffect(() => {
    if (PREFERENCES?.get()?.language?.code) {
      setSelectedLanguage(PREFERENCES?.get()?.language?.code);
    }
  }, [PREFERENCES?.get()?.language?.code]);

  const handleClickUserIcon = event => {
    if (!open && !openLanguage) {
      if (authenticatedUser?.type === 'SM') {
        setAnchorEl(event.currentTarget); // Open popper if it's closed
      } else {
        navigate('/sales-manager/login');
      }
    } else if (!open && openLanguage) {
      setAnchorLanguage(null);
      if (authenticatedUser?.type === 'SM') {
        setAnchorEl(event.currentTarget); // Open popper if it's closed
      } else {
        navigate('/sales-manager/login');
      }
    } else {
      setAnchorEl(null);
    }
  };

  const handleLanguageClick = async event => {
    if (!openLanguage && !open) {
      setAnchorLanguage(event.currentTarget); // Open popper if it's closed
    } else if (!openLanguage && open) {
      setAnchorEl(null);
      setAnchorLanguage(event.currentTarget); // Open popper if it's closed
    } else {
      setAnchorLanguage(null);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        ((anchorEl && !anchorEl.contains(event.target)) || (anchorLanguage && !anchorLanguage.contains(event.target)))
      ) {
        setAnchorEl(null);
        setAnchorLanguage(null);
      }
    };

    if (open || openLanguage) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, anchorEl, openLanguage, anchorLanguage]);

  return (
    <>
      {shouldDisplay && (
        <div className={`header-container ${dashboardIsCollapsed ? 'isCollapsed' : ''}`}>
          <div
            onClick={() => {
              if (!disableClickableLogo) navigate('/');
            }}
            style={{ cursor: disableClickableLogo ? 'default' : 'pointer' }}
          >
            {isDark ? <SVG icon='logo/bespokeliving' /> : <SVG icon='logo/bespokeliving_white' />}
          </div>
          {!logoOnly && (
            <div className={`login-section${isDark ? '-dark' : ''}`}>
              {audioBtn && (
                <button onClick={() => toggleAudio(!audioMuted)} className='audio-button'>
                  <SVG customCSS='audio-icon' icon={audioMuted ? 'audio/off' : 'audio/on'} />
                </button>
              )}
              {!hideLanguage && (
                <>
                  <div className='language-wrapper' onClick={handleLanguageClick}>
                    <SVG icon='action/globe' customCSS={'globe-icon'} />
                    <span className='language-label'>{PREFERENCES?.get()?.language?.code?.toUpperCase()}</span>
                  </div>
                  <Popper
                    id={idLanguage}
                    open={openLanguage}
                    anchorEl={anchorLanguage}
                    placement='bottom-end'
                    style={{ zIndex: 9999 }}
                  >
                    <div className='language-menu-wrapper' ref={popperRef}>
                      {PREFERENCES?.get()?.languageOptions?.map(option => (
                        <MenuItem
                          key={option.code}
                          text={option.name.split(' ')[0]}
                          icon={'semantic/check'}
                          selected={selectedLanguage === option.code}
                          onClickFn={async () => {
                            try {
                              localStorage.setItem('lang', JSON.stringify(option)); // persist last language on page refresh
                              PREFERENCES.updateLanguage(option);
                              setSelectedLanguage(option.code);
                              i18n.changeLanguage(option.code);
                              setAnchorLanguage(null);
                            } catch (error) {
                              console.error('Error updating language', error);
                              dispatchNotification({
                                timer: true,
                                type: 'banner',
                                status: 'error',
                                title: t('preferences.error_changing_language')
                              });
                            }
                          }}
                        />
                      ))}
                    </div>
                  </Popper>
                </>
              )}
              {!noLogin && (
                <div className='user-wrapper' onClick={handleClickUserIcon}>
                  <SVG icon='action/user' customCSS={'user-icon'} />
                </div>
              )}
              <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-end' style={{ zIndex: 9999 }}>
                <div className='menu-wrapper' ref={popperRef}>
                  <MenuItem
                    selected={location?.pathname === '/dashboard'}
                    text={t('header.button_dashboard')}
                    onClickFn={() => {
                      navigate('/dashboard');
                      setAnchorEl(null);
                    }}
                  />
                  {isAdmin && (
                    <MenuItem
                      text='Price Management'
                      selected={location?.pathname === '/admin'}
                      onClickFn={() => {
                        navigate('/admin');
                        setAnchorEl(null);
                      }}
                    />
                  )}
                  <MenuItem
                    style={{ color: '#9F1C1C', width: '100%' }}
                    text={t('header.button_logout')}
                    onClickFn={() => {
                      clearSession();
                      dispatchIsAuthenticated(null);
                      navigate('/');
                      dispatchNotification({
                        status: 'success',
                        title: t('header.success_logout'),
                        timer: true
                      });
                      setAnchorEl(null);
                    }}
                  />
                </div>
              </Popper>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
