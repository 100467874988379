import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
//components
import Header from '@components/ui/Header/Header';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Button from '@components/ui/Buttons/Button/Button';
import EnterpriseSelection from '@pages/EnterpriseSelection/EnterPriseSelection';
//styles
import './client.scss';
//hooks
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
//services
import { getProjectsBySalesManagerAndProject } from '@services/projects';
import { getClientDetails, createRequest } from '@services/client';
import { verifyRemoteEmail } from '@services/projects';
import Cookies from 'js-cookie';
//translations
import { useTranslation } from 'react-i18next';
//utils
import { isEmpty, isValidEmail } from '@utils/functions';
import { setPrevent } from '@utils/preventions';
import { CONFIG_STATUS } from '@utils/enums';

const ClientIdPage = ({ isRemoteFlow }) => {
  const navigate = useNavigate();
  const {
    dispatchProjectInfo,
    dispatchNotification,
    dispatchResetNotification,
    dispatchClient,
    dispatchIsAuthenticated,
    dispatchUpdateTracking,
    PREFERENCES,
    getForms,
    getEnterpriseContent
  } = useRedux();
  const [clientEmail, setClientEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [goToEnterprisePage, setGoToEnterprisePage] = useState(false);
  const { t } = useTranslation();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_token = Cookies.get('sessionId');
  const sales_manager_id = Cookies.get('salesManagerId');
  const [searchParams] = useSearchParams();
  const projectHashFromParam = searchParams.get('h');
  const [content, setContent] = useState(null);
  const clientAuthText = getForms()['client-auth'];
  const preferences = PREFERENCES.get();
  const language = PREFERENCES.get()?.language?.code;
  const enterpriseContent = getEnterpriseContent()?.[language];

  useEffect(() => {
    if (preferences.languageOptions && !preferences.language)
      PREFERENCES.updateLanguage(preferences.languageOptions.find(option => option.isDefault));
    if (preferences.language && clientAuthText && clientAuthText[preferences.language.code])
      setContent(clientAuthText[preferences.language.code]);
  }, [preferences.languageOptions, preferences.language, clientAuthText]);

  const validateEmail = async () => {
    if (!clientEmail) {
      setErrorMessage(t('client_id.empty_email'));
      return;
    }

    if (!isValidEmail(clientEmail)) {
      setErrorMessage(t('client_id.invalid_email'));
      return;
    }

    try {
      setErrorMessage(null);
      const clientSFInfo = await getClientDetails(clientEmail, sales_manager_token, sales_manager_id);

      if (clientSFInfo?.data?.code === 'noPreviousRequests') {
        dispatchNotification({
          timer: false,
          status: 'error',
          title: t('client_id.assign_another_sales_manager')
        });
        return;
      }

      if (clientSFInfo?.data?.code === 'registerClient') {
        console.log('Client does not exist');
        navigate('/client/register', { state: clientEmail });
        return;
      }

      const projectsOfSM =
        (await getProjectsBySalesManagerAndProject(sales_manager_id, sales_manager, sales_manager_token)) || [];
      const requestOnPlatform = projectsOfSM?.find(item => item?.clientId?.email === clientEmail);

      if (!requestOnPlatform) {
        if (enterpriseContent.showPage) {
          dispatchClient({ email: clientEmail });
          setGoToEnterprisePage(true);
        } else {
          await createRequest(
            {
              clientEmail,
              salesManagerEmail: sales_manager,
              salesManagerId: sales_manager_id,
              clientDetailsFromSF: clientSFInfo.data,
              projectDefaultLanguage: PREFERENCES?.get()?.language?.code,
              projectPrefix: 'TCT'
            },
            sales_manager_token
          )
            .then(async response => {
              if (response) {
                dispatchProjectInfo(response.projectInfo);
                navigate(`/journey/${response.projectInfo.id}`);
                dispatchClient({ email: clientEmail });
              }
            })
            .catch(error => {
              console.log('Error saving project do DB', error);
              setErrorMessage(t('client_id.errorCreatingRequest'));
            });
        }
      } else {
        navigate(`/dashboard?clientId=${requestOnPlatform.clientId?.id}`);
        dispatchClient({ email: clientEmail });
      }
    } catch (error) {
      console.error('Error getting client details', error);
      setErrorMessage(t('client_id.errorGettingDetails'));
    }
  };

  const verifyRemoteEmailHandler = async () => {
    if (!clientEmail) {
      setErrorMessage(t('client_id.empty_email'));
      return;
    }

    if (!isValidEmail(clientEmail)) {
      setErrorMessage(t('client_id.invalid_email'));
      return;
    }

    try {
      setErrorMessage(null);
      const response = await verifyRemoteEmail(projectHashFromParam, clientEmail);

      if (response.id) {
        dispatchIsAuthenticated({ type: 'Client', isAdmin: false });
        dispatchProjectInfo(response);
        setPrevent('BdInfo');
        if (response.status === CONFIG_STATUS.Active || response.status === CONFIG_STATUS.Inactive) {
          dispatchUpdateTracking({
            idToUpdate: 'startReviewStep',
            updatedObject: true
          });
        }
        PREFERENCES.updateLanguage(response?.projectLanguage);
        PREFERENCES.updateCurrency(response?.projectCurrency);
        PREFERENCES.updateUnits(response?.projectUnits);
        dispatchClient(response.clientId);
        navigate(`/journey/${response.id}`);
      } else {
        dispatchNotification({
          timer: false,
          status: 'error',
          type: 'banner',
          title: t('client_id.remote_wrong_email')
        });
        setErrorMessage(' ');
      }
    } catch (error) {
      console.error('Error getting client details', error);
      setErrorMessage(t('client_id.errorGettingDetails'));
    }
  };

  return (
    <>
      {!goToEnterprisePage ? (
        <div className='client-container'>
          <Header shouldDisplay noLogin={isRemoteFlow} />

          <div className='client-wrapper'>
            <div className='background-image-container'>
              <div className='overlay'></div>
              <img src={content?.type?.image} className='background-image' />
            </div>

            <div className='content-section'>
              <span className='main-title'>{content?.type?.title}</span>
              <TextInput
                icon={'action/email'}
                placeholder=''
                type='email'
                name='email'
                id='email'
                label={content?.type?.emailLabel}
                customCSS={{ height: '44px' }}
                onChangeFn={e => {
                  setClientEmail(e.target.value);
                  setErrorMessage(null);
                }}
                errorMessage={errorMessage}
                onEnterPress={() => {
                  isRemoteFlow ? verifyRemoteEmailHandler() : validateEmail();
                  dispatchResetNotification();
                }}
              />
              <div className='button-container'>
                {!isRemoteFlow && (
                  <Button secondary text={t('client_id.back_button')} onClickFn={() => navigate('/')} />
                )}
                <Button
                  primary
                  text={content?.type?.actions[1]?.label}
                  onClickFn={() => {
                    isRemoteFlow ? verifyRemoteEmailHandler() : validateEmail();
                    dispatchResetNotification();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EnterpriseSelection />
      )}
    </>
  );
};

export default ClientIdPage;
