import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const createProject = async (body, clientEmail, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'project' })}/create?clientEmail=${clientEmail}`,
    headers: HEADERS,
    data: body
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const getProjectById = async (projectId, salesManagerId, salesManagerEmail, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'project' })}/getProject?projectId=${projectId}&salesManagerId=${salesManagerId}&salesManagerEmail=${salesManagerEmail}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const getProjectsBySalesManagerAndProject = async (salesManagerId, salesManagerEmail, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'project' })}/listAll?salesManagerId=${salesManagerId}&salesManagerEmail=${salesManagerEmail}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const updateProject = async (data, beSpokeId, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'put',
    url: `${getFullEndpoint({ microservice: 'project' })}/update/${beSpokeId}`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const updatePreferences = async (data, SMToken) => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'put',
    url: `${getFullEndpoint({ microservice: 'project' })}/updatePreferences`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);
  return response.data;
};

export const getProjectByHash = async hash => {
  const HEADERS = {
    accept: '*/*'
  };

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'project' })}/findProjectByHash?projectHash=${hash}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const verifyRemoteEmail = async (hash, email) => {
  const HEADERS = {
    accept: '*/*'
  };

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'project' })}/verifyRemoteEmail?email=${email}&projectHash=${hash}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);

  return response?.data;
};
