import { useState, useEffect } from 'react';
import './RadioButton.scss';

const RadioButton = ({ name, id, label, disabled, onSelect, value, error }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(value === id);
  }, [value]);

  return (
    <div className='radio-item'>
      <input
        type='radio'
        name={name}
        className={error ? 'error' : ''}
        id={id}
        value={value}
        disabled={disabled}
        checked={isChecked}
        onChange={() => {
          onSelect({ id });
        }}
      />
      <label className={`radio-item-label ${isChecked ? ' checked' : ' '}`}>{label}</label>
    </div>
  );
};

// Define the default prop values
// RadioButton.defaultProps = {
//   name: ' ',
//   label: ' ',
//   checked: false,
//   disabled: false
// };

export default RadioButton;
