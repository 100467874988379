import { useRef, useState, useEffect } from 'react';
// hooks
import useRedux from '@hooks/useRedux';
import useRender from '@hooks/useRender';
// components
import Button from '../../ui/Buttons/Button/Button';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import { Popper } from '@mui/base/Popper';
import MenuItem from '@components/ui/MenuItem/MenuItem';
// utils
import { CONFIG_STATUS, DIALOG_TYPE } from '@utils/enums';
import { getLayoutDetails } from '@utils/areas';
import { getArea } from '@utils/areas';
// styles
import './PanelFooter.scss';
//services
import { updateProject } from '@services/projects';
import Cookies from 'js-cookie';
//hooks
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
//translations
import { useTranslation } from 'react-i18next';

// Custom hook to detect click outside of a given element
const useClickOutside = (ref, handler, exceptionRef) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !(exceptionRef?.current && exceptionRef.current.contains(event.target))
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, exceptionRef]);
};

const PanelFooter = ({ formState, currentStepNr, currentStepId, setNextFormStep, showPreferencesPanel }) => {
  const {
    dispatchSetDialog,
    PRICING,
    dispatchNotification,
    getCurrentProject,
    dispatchUpdateTracking,
    getTracking,
    getHouseTypes,
    PREFERENCES,
    getPlots,
    getHouses,
    getIsAuthenticated
  } = useRedux();
  const { reviewButtonsToRender } = useRender();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const popperRef = useRef(null); // Reference to the popper content
  const buttonRef = useRef(null); // Reference to the toggle button
  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currency = PREFERENCES.get().currency;
  const units = PREFERENCES.get().units;
  const language = PREFERENCES.get().language.code;
  const buttons = currentStepId === 'review' ? reviewButtonsToRender() : null;
  const authenticatedUser = getIsAuthenticated();
  const house = getHouses().find(house => house.id === watcher?.layout?.houseLayout);

  const status = getCurrentProject()?.status;
  const projectOwner = getCurrentProject()?.sales_manager_id;

  const sales_manager_id = Cookies.get('salesManagerId');

  const isProjectOwner = authenticatedUser?.type === 'Client' || projectOwner === sales_manager_id;

  // status variables
  const isDraft = status === CONFIG_STATUS.Draft;
  const isActive = status === CONFIG_STATUS.Active;
  const isInactive = status === CONFIG_STATUS.Inactive;
  const isSubmitted = status === CONFIG_STATUS.Submitted;

  const handleClick = event => {
    if (anchorEl) {
      setAnchorEl(null); // Close popper if it's open
    } else {
      setAnchorEl(event.currentTarget); // Open popper if it's closed
    }
  };

  const handleNextButton = async () => {
    if (getTracking().wasSelectedOnImageContainer) {
      dispatchUpdateTracking({
        idToUpdate: 'plotSelectedOnImageContainer',
        updatedObject: { wasSelectedOnImageContainer: false }
      });
    }

    if (currentStepId === 'interiors') PRICING.update({ formState, currency });
    setNextFormStep(currentStepNr++);
    try {
      if (isDraft) {
        const layoutArea = house?.area && house?.area > 0 ? getArea(units, house?.area) : null;
        const totalArea = getArea(units, getPlots()?.find(plot => plot.id === watcher?.plot?.plot)?.area) ?? null;
        const constructionArea =
          getArea(units, getPlots()?.find(plot => plot.id === watcher?.plot?.plot)?.areaOfConstruction) ?? null;

        await updateProject(
          {
            form: watcher,
            plot: watcher?.plot?.plot ?? null,
            layoutSize: layoutArea,
            totalSize: totalArea,
            grossArea: constructionArea,
            layout: getHouseTypes()[language]?.find(el => el.id === watcher?.layout?.houseType)?.id
          },
          getCurrentProject().id,
          Cookies.get('sessionId')
        );
      }
    } catch (error) {
      console.log('Error saving project', error);
      dispatchNotification({
        timer: false,
        type: 'banner',
        status: 'error',
        title: t('projects.error_saving_project_title'),
        description: t('projects.error_saving_project_description')
      });
      navigate('/');
    }
  };

  // Use the custom hook to close the popper when clicking outside, except for the toggle button
  useClickOutside(popperRef, () => setAnchorEl(null), buttonRef);

  // Update pricing when currency changes
  useEffect(() => {
    if (currentStepNr >= 2) {
      PRICING.update({ formState, currency });
    }
  }, [PREFERENCES.get().currency]);

  const journeyFooter = (
    <>
      <IconButton ref={buttonRef} icon={'action/grid'} onClickFn={handleClick} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement='top-start' style={{ zIndex: 9999 }}>
        <div className='more-menu' ref={popperRef}>
          {/* consult price only available on step Exteriors and following */}
          {!['plot', 'layout'].includes(currentStepId) && (
            <MenuItem
              text={t('panel_footer.consult_price')}
              onClickFn={() => {
                PRICING.update({ formState, currency });
                dispatchSetDialog({ open: true, type: 'image', id: DIALOG_TYPE.ConsultPrice });
              }}
            />
          )}
          {authenticatedUser?.type === 'SM' && isDraft && isProjectOwner && (
            <MenuItem
              text={t('panel_footer.save_exit')}
              onClickFn={() => {
                dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveExit });
                handleClick();
              }}
            />
          )}
          {authenticatedUser?.type === 'SM' && !isDraft && (
            <MenuItem
              text={t('panel_footer.exit')}
              onClickFn={() => {
                dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.Exit });
                handleClick();
              }}
            />
          )}
          <MenuItem text={t('panel_footer.preferences')} onClickFn={showPreferencesPanel} />
        </div>
      </Popper>
      <Button primary text={t('panel_footer.next')} onClickFn={handleNextButton} />
    </>
  );

  const reviewFooter = (
    <>
      <IconButton ref={buttonRef} icon={'action/grid'} onClickFn={handleClick} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement='top-start' style={{ zIndex: 9999 }}>
        <div className='more-menu' ref={popperRef}>
          <MenuItem text={t('panel_footer.preferences')} onClickFn={showPreferencesPanel} />
        </div>
      </Popper>
      <div className='review-buttons'>
        {((buttons?.save &&
          JSON.stringify(watcher) !== JSON.stringify(getCurrentProject().form) &&
          (getCurrentProject().remoteVersions < 3 || authenticatedUser?.type === 'SM') &&
          isProjectOwner) ||
          (isProjectOwner && isDraft)) && (
          <Button
            secondary
            text={t('panel_footer.save')}
            onClickFn={() => {
              if (authenticatedUser?.type === 'SM')
                dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveProject });
              if (authenticatedUser?.type === 'Client')
                dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveProjectClient });
            }}
          />
        )}

        {buttons?.exit && buttons?.submit && isProjectOwner && (
          <Button
            secondary
            text={t('panel_footer.exit')}
            onClickFn={() => {
              dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.Exit });
            }}
          />
        )}
        {buttons?.exit && !buttons?.submit && (
          <Button
            primary
            text={t('panel_footer.exit')}
            onClickFn={() => {
              dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.Exit });
            }}
          />
        )}
        {buttons?.submit && isProjectOwner && (
          <Button
            primary
            text={t('panel_footer.confirm_interest')}
            onClickFn={() => {
              dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SubmitProject });
            }}
          />
        )}
      </div>
    </>
  );

  return <div className='form-footer'>{currentStepId === 'review' ? reviewFooter : journeyFooter}</div>;
};

export default PanelFooter;
