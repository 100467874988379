import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
//components
import Header from '@components/ui/Header/Header';
// hooks
import useRedux from '@hooks/useRedux';
//styles
import './infoPages.scss';

const InfoPageComponent = ({ data }) => {
  const [imageIsReady, setImageIsReady] = useState(false);
  const [content, setContent] = useState(null);
  const { PREFERENCES } = useRedux();

  useEffect(() => {
    if (PREFERENCES.get().languageOptions && PREFERENCES.get().language === null)
      PREFERENCES.updateLanguage(PREFERENCES.get().languageOptions.find(option => option.isDefault));
    if (PREFERENCES.get().language !== null) setContent(data?.[PREFERENCES?.get()?.language?.code]);
  }, [PREFERENCES]);

  const paragraphs = content?.bodycopy.split(/\n\n/);

  return (
    <>
      <div className='info-container'>
        <Header shouldDisplay noLogin disableClickableLogo />
        <div className='image-wrapper'>
          <img
            onLoad={() => {
              setImageIsReady(true);
            }}
            className='background-image'
            src={content?.pageImage}
            alt={content?.pageTitle || 'Background image'}
          />
          <span className='page-title'>{content?.pageTitle}</span>
        </div>

        {imageIsReady && (
          <div className='text-wrapper'>
            {paragraphs.map((paragraph, index) => {
              return (
                <div key={index}>
                  <ReactMarkdown>{paragraph.replace(/\n/g, '  \n')}</ReactMarkdown>
                  {index < paragraphs.length - 1 && <br />}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default InfoPageComponent;
