import { getDashboardContent } from '@services/singlePages/dashboard';
import { dashboardAction } from './dashboard-slice';

export const setDashboardContent = async (dispatch, SMToken) => {
  try {
    const response = await getDashboardContent(SMToken);
    dispatch(dashboardAction.setDashboardContent(response));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
