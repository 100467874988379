import { createSlice } from '@reduxjs/toolkit';

const servicesSlice = createSlice({
  name: 'servicesFromBO',
  initialState: {},
  reducers: {
    setServicesContent: (state, action) => {
      return { ...action.payload };
    }
  }
});

const servicesAction = servicesSlice.actions;
export { servicesSlice, servicesAction };
