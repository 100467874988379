import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useRedux from '@hooks/useRedux';

const ProtectedRoute = ({ element: Component }) => {
  const { getIsAuthenticated } = useRedux();
  const authenticatedUser = getIsAuthenticated();
  const location = useLocation();

  return authenticatedUser?.type === 'SM' ? (
    Component
  ) : (
    <Navigate
      to='/sales-manager/login'
      state={{ from: location !== '/sales-manager/login' ? location : null }}
      replace={false}
    />
  );
};

export default ProtectedRoute;
