import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';
import { getHeadersToRequest } from '@utils/auth/functions';

export const getModules = async SMToken => {
  const HEADERS = await getHeadersToRequest({ SMToken });

  const requestObj = {
    method: 'get',
    url: getFullEndpoint({ microservice: 'modules' }),
    headers: HEADERS
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));
  // const response = await axios.request(requestObj).catch(error => handleError(error));
  return response.data;
};
