import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
import Cookies from 'js-cookie';
//styles
import './enterpriseSelection.scss';
//components
import Header from '@components/ui/Header/Header';
import { createRequest, getClientDetails } from '@services/client';

const EnterpriseSelection = ({ isRegisterFlow }) => {
  const {
    dispatchResetTracking,
    dispatchProjectInfo,
    dispatchClient,
    PREFERENCES,
    getClient,
    dispatchNotification,
    getEnterpriseContent
  } = useRedux();
  const navigate = useNavigate();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_id = Cookies.get('salesManagerId');
  const sales_manager_token = Cookies.get('sessionId');

  const content = getEnterpriseContent()[PREFERENCES.get()?.language?.code];

  useEffect(() => {
    dispatchResetTracking();
    PREFERENCES.reset();
  }, [dispatchResetTracking, PREFERENCES]);

  const createRequestFunction = async projectPrefix => {
    const clientSFInfo = await getClientDetails(getClient().email, sales_manager_token, sales_manager_id);

    await createRequest(
      {
        clientEmail: getClient().email,
        salesManagerEmail: sales_manager,
        salesManagerId: sales_manager_id,
        clientDetailsFromSF: clientSFInfo.data,
        projectDefaultLanguage: PREFERENCES?.get()?.language?.code,
        projectPrefix
      },
      sales_manager_token
    )
      .then(async response => {
        if (response) {
          dispatchProjectInfo(response.projectInfo);
          navigate(`/journey/${response.projectInfo.id}`);
        }
      })
      .catch(error => {
        console.log('Error saving project to DB', error);
      });
  };

  return (
    <div className='enterprise-container'>
      <Header shouldDisplay />
      <div className='split-screen'>
        <div
          className='left-section'
          onClick={() => {
            createRequestFunction('TCT');
          }}
        >
          {content?.backgroundTorre && (
            <img src={content?.backgroundTorre} alt='Torre Background' className='split-image' />
          )}
          <div className='overlay'>
            {content?.logoTorre && <img src={content?.logoTorre} alt='Torre Logo' style={{ width: '400px' }} />}
            <div className='overlay-text'>
              <span>{content?.descriptionTorre}</span>
            </div>
          </div>
        </div>

        <div
          className='right-section'
          onClick={() => {
            createRequestFunction('TCD');
          }}
        >
          {content?.backgroundDunas && (
            <img src={content?.backgroundDunas} alt='Dunas Background' className='split-image' />
          )}
          <div className='overlay'>
            {content?.logoDunas && <img src={content?.logoDunas} alt='Dunas Logo' style={{ width: '400px' }} />}
            <div className='overlay-text'>
              <span>{content?.descriptionDunas}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseSelection;
