import { useEffect, useState } from 'react';
// utils
import { PLOT_STATUS } from '@utils/enums';
// styles
import './Tooltip.scss';

const Tooltip = ({ label, status, premium }) => {
  const [statusLabel, setStatusLabel] = useState(status);

  const getClassName = () => {
    if (status === PLOT_STATUS.Available) {
      return premium ? 'tooltip-available tooltip-available-premium' : 'tooltip-available';
    } else if (status === PLOT_STATUS.InNegotiation) return 'tooltip-negotiation';
    else if (status === PLOT_STATUS.NotAvailable) return 'tooltip-not-available';
    else if (status === PLOT_STATUS.Reserved) return 'tooltip-reserved';
    else if (status === PLOT_STATUS.Sold || status === PLOT_STATUS.NotMatchFilters) return 'tooltip-sold';
  };

  useEffect(() => {
    if (status === PLOT_STATUS.InNegotiation) setStatusLabel('In negotiation');
    else if (status === PLOT_STATUS.NotAvailable) setStatusLabel('Coming Soon');
    else if (status === PLOT_STATUS.Sold) setStatusLabel('Sold');
    else setStatusLabel(status);
  }, [status]);

  return (
    <div className={getClassName()}>
      <span className='label'>{label} ・</span>
      {premium && <span className='premium'>Premium ・</span>}
      <span className='status'>{statusLabel}</span>
    </div>
  );
};

export default Tooltip;
