import './Stepper.scss';
import useRedux from '@hooks/useRedux';
import { SVG } from '../../../assets/svg';
import { useEffect } from 'react';
import { CONFIG_STATUS } from '@utils/enums';
//utils
import { getArea } from '@utils/areas';
import { updateProject } from '@services/projects';
import { isEmpty } from '@utils/functions';
//hooks
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
//translations
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const Stepper = ({ formState, stepsNames, stepsIds, currentStepId, currentStepNr, onStepClick }) => {
  const {
    FORMSTATE,
    getCurrentProject,
    PRICING,
    dispatchNotification,
    getHouseTypes,
    PREFERENCES,
    getPlots,
    getHouses
  } = useRedux();
  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currency = PREFERENCES.get().currency;
  const units = PREFERENCES.get().units;
  const language = PREFERENCES.get().language.code;
  const isDraft = getCurrentProject()?.status === CONFIG_STATUS.Draft;
  const existingWatcher = structuredClone(getCurrentProject()?.form);
  let stepsWereSaved = !isEmpty(existingWatcher?.plot?.plot) && !isEmpty(existingWatcher?.plot?.macroplot);

  const draftStepsFinished = () => {
    if (!stepsWereSaved || !existingWatcher || isEmpty(existingWatcher)) return;

    const steps = ['plot', 'layout', 'interiors', 'exteriors'];
    const completedSteps = steps.filter(step => !!existingWatcher[step]);

    if (completedSteps.length === steps.length) {
      completedSteps.push('review');
    }
    return completedSteps;
  };

  let stepsFinished = !isDraft ? stepsIds : !stepsWereSaved ? FORMSTATE.get('stepsFinished') : draftStepsFinished();
  const stepChanged = FORMSTATE.get('stepChanged');

  useEffect(() => {
    const completedSteps = draftStepsFinished();
    if(!getCurrentProject() || isEmpty(getCurrentProject())) return;
    if (
      structuredClone(FORMSTATE.get('stepsFinished')) !== structuredClone(completedSteps) &&
      stepsWereSaved &&
      getCurrentProject()?.status === 'draft'
    ) {
      FORMSTATE.dispatchUpdateStepsFinished(completedSteps);
    } else {
      if (getCurrentProject()?.status !== 'draft') {
        FORMSTATE.dispatchUpdateStepsFinished(stepsIds);
      }
    }
  }, [stepsWereSaved, getCurrentProject()]);

  const getStepperClassName = (currentStepNr, index) => {
    if (currentStepNr === index) return 'active';
  };

  const saveOnStepClick = async () => {
    if (currentStepId === 'exteriors' || currentStepId === 'interiors') PRICING.update({ formState, currency });
    try {
      if (isDraft) {
        const house = getHouses().find(house => house?.id === watcher?.layout?.houseLayout);
        const layoutArea = house?.area && house?.area > 0 ? getArea(units, house?.area) : null;
        const totalArea = getArea(units, getPlots()?.find(plot => plot.id === watcher?.plot?.plot)?.area) ?? null;
        const constructionArea =
          getArea(units, getPlots()?.find(plot => plot.id === watcher?.plot?.plot)?.areaOfConstruction) ?? null;

        await updateProject(
          {
            form: watcher,
            plot: watcher?.plot?.plot ?? null,
            layoutSize: layoutArea,
            totalSize: totalArea,
            grossArea: constructionArea,
            layout: getHouseTypes()[language]?.find(el => el.id === watcher?.layout?.houseType)?.id
          },
          getCurrentProject().id,
          Cookies.get('sessionId')
        );
      }
    } catch (error) {
      console.log('Error saving project', error);
      dispatchNotification({
        timer: false,
        type: 'banner',
        status: 'error',
        title: t('projects.error_saving_project_title'),
        description: t('projects.error_saving_project_description')
      });
      navigate('/');
    }
  };

  useEffect(() => {
    if (stepChanged) {
      if (stepsIds[currentStepNr] === 'plot') {
        // stepsFinished = [];
        // FORMSTATE.dispatchUpdateStepsFinished(stepsFinished);
      } else if (stepsIds[currentStepNr] === 'layout') {
        // stepsFinished = ['plot'];
        // FORMSTATE.dispatchUpdateStepsFinished([]);
        // FORMSTATE.dispatchUpdateStepsFinished(stepsFinished);
      }
    }
  }, [stepChanged]);

  return (
    <div className='stepper'>
      {stepsNames?.map((step, index) => (
        <div
          className={getStepperClassName(currentStepNr, index)}
          key={`stepper-${index}`}
          onClick={() => {
            // Only allows to navigate by stepper if index of stepper is lower than currentStep
            if (index < currentStepNr || stepsFinished.includes(stepsIds[index])) {
              onStepClick(index, stepsFinished);
              saveOnStepClick();
            }
          }}
        >
          {FORMSTATE.get('stepsFinished').includes(stepsIds[index]) && <SVG icon='semantic/check-circle' />}
          {step}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
