import { isEmpty } from '@utils/functions';
import useRedux from './useRedux';
import { useTranslation } from 'react-i18next';
//utils
import { MODAL_TYPE } from '@utils/enums';
import { extractNumber } from '@utils/filters';
import { getArea } from '@utils/areas';

const useFilters = () => {
  const { getPlots, getHouses, getMacroplots, getHouseTypes, getModals, PREFERENCES, PRICING } = useRedux();
  const { t } = useTranslation();

  const getHouseLayoutsByPlotTypologyHouseType = (selectedPlot, selectedNrRooms, selectedHouseType) => {
    const plotHouses = getPlots().find(p => p.id === selectedPlot).houses;
    const filteredHouses = getHouses().filter(house => plotHouses.includes(house.id));
    const houseLayouts = filteredHouses.filter(
      h => h.typology === selectedNrRooms && h.houseType === selectedHouseType
    );
    return houseLayouts;
  };

  const getHouseTypeByPlotAndTypology = (selectedPlot, typology) => {
    // Get matchingHouses from selectedPlot
    // HERE
    const matchingHouses = getPlots().find(plot => plot.id === selectedPlot).houses;

    // Filter matchingHouses according selectedPlot ID
    const filteredHouses = getHouses().filter(house => matchingHouses.includes(house.id));

    // Filter filteredHouses based on selectedTypology, and Get House Types (excluding duplicates)
    const houseTypes = [
      ...new Set(filteredHouses.filter(house => house.typology === typology).map(house => house.houseType))
    ];
    return houseTypes;
  };

  const getHousesByTypology = typology => {
    const filteredHouses = getHouses().filter(house => house.typology === typology);
    return filteredHouses;
  };

  const getHousesById = houseId => {
    const filteredHouses = getHouses().find(house => house.id === houseId);
    return filteredHouses;
  };

  const getMacroplotByPlotId = plotId => {
    for (let macroplot of getMacroplots()) {
      if (macroplot.plots.includes(plotId)) {
        return macroplot;
      }
    }
    return null;
  };

  const getPlotsByMacroplotId = macroplotId => {
    const plotsIds = getMacroplots().find(macroplot => macroplot.id === macroplotId).plots;
    return getPlots().filter(plot => plotsIds.includes(plot.id));
  };

  const getMacroplotsFiltered = (filters, macroplots, plots) => {
    if (isEmpty(filters)) return macroplots;

    const plotsFilteredIds = getPlotsFiltered(filters, plots)?.map(p => p.id);
    const macroplotsFiltered = macroplots.filter(macroplot =>
      macroplot.plots.some(plot => plotsFilteredIds.includes(plot))
    );
    return macroplotsFiltered;
  };

  const getPlotsFiltered = (filters, plots) => {
    if (isEmpty(filters)) return plots;

    // filter plots based on filters (Journey + FiltersSorting)
    const houses = getHouses();
    const housesByTypology = houses.filter(house => filters?.bedrooms?.includes(house.typology));
    const housesByHouseType = houses.filter(house => filters?.houseType?.includes(house.houseType));
    const housesByTypologyAndHouseType = housesByTypology.filter(houseByTypology =>
      housesByHouseType.some(houseByHouseType => houseByHouseType.id === houseByTypology.id)
    );

    // filter by area
    if (filters?.plotMinArea !== 'Min') {
      plots = plots.filter(plot => plot.area >= filters.plotMinArea);
    }
    if (filters?.plotMaxArea !== 'Max') {
      plots = plots.filter(plot => plot.area <= filters.plotMaxArea);
    }

    // filter by price
    if (filters?.priceMin && filters?.priceMax) {
      const plotIdsThatMatch = PRICING.get().filterValues.plotMinMax?.filter(
        ({ min, max }) =>
          (min >= filters?.priceMin && min <= filters?.priceMax) ||
          (max >= filters?.priceMin && max <= filters?.priceMax)
      );
      plots = plots.filter(plot => plotIdsThatMatch.some(p => plot.id === p.id));
    }

    // filter by typology
    if (filters?.bedrooms?.length > 0 && !filters?.houseType) {
      plots = plots.filter(plot => housesByTypology.some(house => plot.houses.includes(house.id)));
    }

    // filter by house type
    else if (filters?.houseType?.length > 0 && !filters?.bedrooms) {
      plots = plots.filter(plot => housesByHouseType.some(house => plot.houses.includes(house.id)));
    }

    // filter by typology and house type
    else if (filters?.bedrooms?.length > 0 && filters?.houseType?.length > 0) {
      plots = plots.filter(plot => housesByTypologyAndHouseType.some(house => plot.houses.includes(house.id)));
    }

    if (filters?.sortBy === 'Size (Ascending Order)') plots = [...plots].sort((a, b) => a.area - b.area);
    else if (filters?.sortBy === 'Size (Descending Order)') plots = [...plots].sort((a, b) => b.area - a.area);

    return plots;
  };

  const getPlotsByPlotArea = (filters, plots) => {
    let plotsByArea = [];
    if (filters?.plotMinArea !== 'Min' && filters?.plotMaxArea === 'Max') {
      plotsByArea = plots.filter(plot => plot.area >= filters?.plotMinArea);
    } else if (filters?.plotMaxArea !== 'Max' && filters?.plotMinArea === 'Min') {
      plotsByArea = plots.filter(plot => plot.area <= filters?.plotMaxArea);
    } else if (filters?.plotMinArea !== 'Min' && filters?.plotMaxArea !== 'Max') {
      plotsByArea = plots.filter(plot => plot.area >= filters?.plotMinArea);
      plotsByArea = plotsByArea.filter(plot => plot.area <= filters?.plotMaxArea);
    }
    return plotsByArea;
  };

  const getModalsByHouseType = (houseTypes, houseTypeSelected) => {
    let modals = getModals();
    const filteredHouseTypes = getHouseTypes()[PREFERENCES.get().language.code].filter(houseType =>
      houseTypes.includes(houseType.id)
    );

    // find modals that match the available house types
    modals = modals[MODAL_TYPE.HouseTypes]?.[PREFERENCES.get().language.code].content;
    modals = modals.filter(modal => filteredHouseTypes.some(houseType => houseType.label === modal.title));

    // sort modals based on the selected house type
    const selected = getHouseTypes()[PREFERENCES.get().language.code].filter(
      houseType => houseType.id === houseTypeSelected
    )[0].label;
    const itemIndex = modals.findIndex(modal => modal.title === selected);
    modals = [modals[itemIndex], ...modals.filter((_, index) => index !== itemIndex)];

    return modals;
  };

  const getModalsByDivision = (packsDetails, division) => {
    let modals = getModals();
    modals = modals[MODAL_TYPE.ThemeDetails]?.[PREFERENCES.get().language.code].content;
    modals = modals.filter(modal => packsDetails.some(pack => pack.label === modal.title));
    const itemIndex = modals.findIndex(modal => modal.title === division);
    modals = [modals[itemIndex], ...modals.filter((_, index) => index !== itemIndex)];
    return modals;
  };

  const getPillLabels = (houseTypes, units, filters) => {
    const sortLabels = [
      { label: t('filters.sort_by_name'), value: 'Name' },
      { label: t('filters.sort_by_ascending'), value: 'Size (Ascending Order)' },
      { label: t('filters.sort_by_descending'), value: 'Size (Descending Order)' }
    ];
    const labels = {};
    if (filters.plotMinArea && filters.plotMinArea !== 'Min')
      labels.plotMinArea = `${t('filters.pill_min_size_label')} ${getArea(units, filters.plotMinArea)}`;
    if (filters.plotMaxArea && filters.plotMaxArea !== 'Max')
      labels.plotMaxArea = `${t('filters.pill_max_size_label')} ${getArea(units, filters.plotMaxArea)}`;
    if (filters.bedrooms)
      labels.bedrooms = filters.bedrooms.map(
        bedroom => `${extractNumber(bedroom)} ${t('filters.pill_bedrooms_label')}`
      );
    if (filters.houseType)
      labels.houseType = filters.houseType.map(type => `${houseTypes.filter(house => house.id === type)[0].label}`);
    // if (filters.minPrice && filters.maxPrice) labels.priceRange = `${t('filters.pill_price_label')} ${filters.minPrice}-${filters.maxPrice}M€`;
    if (filters.sortBy && filters.sortBy !== 'Name')
      labels.sortBy = `${t('filters.pill_sortby_label')} ${sortLabels.find(sort => sort.value === filters.sortBy).label}`;
    return labels;
  };

  return {
    getHouseLayoutsByPlotTypologyHouseType,
    getHouseTypeByPlotAndTypology,
    getHousesByTypology,
    getMacroplotByPlotId,
    getPlotsByMacroplotId,
    getMacroplotsFiltered,
    getPlotsFiltered,
    getPlotsByPlotArea,
    getHousesById,
    getModalsByHouseType,
    getModalsByDivision,
    getPillLabels
  };
};

export default useFilters;
