import { macroplotsAction } from './macroplots-slice';
import { getMacroplots } from '../../services/macroplots';

export const setMacroplots = async (dispatch, SMToken) => {
  try {
    const response = await getMacroplots(SMToken);
    dispatch(macroplotsAction.setMacroplots(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
