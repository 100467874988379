import { useEffect, useState } from 'react';
// components
import Map from '@components/image-container/map/Map';
import Slideshow from '@components/image-container/slideshow/Slideshow';
import ZoomControls from '@components/ui/ZoomControls/ZoomControls';
import Pill from '@components/ui/Pill/Pill';
import ButtonSwitcher from '@components/ui/Buttons/ButtonSwitcher/ButtonSwitcher';
// hooks
import useRedux from '@hooks/useRedux';
import useRender from '@hooks/useRender';
import { useNavigate } from 'react-router-dom';
import useFilters from '@hooks/useFilters';
// assets
import review1 from '@assets/img/review1.png';
import review2 from '@assets/img/review2.png';
import review3 from '@assets/img/review3.png';
import review4 from '@assets/img/review4.png';
import review5 from '@assets/img/review5.png';
import Photo from './photo/Photo';
// utils
import { isArray } from '@utils/utils';
import { isEmpty } from '@utils/functions';
// styles
import './index.scss';
// utils
import { clearFilter } from '@utils/filters';
import { SVG } from '@assets/svg';
import AudioPlayer from '@components/ui/AudioPlayer/AudioPlayer';
import { CONFIG_STATUS, DIALOG_TYPE } from '@utils/enums';
import Cookies from 'js-cookie';
import VideoPlayer from './video/VideoPlayer';

/**
 * ImageContainer component that renders different types of content based on the current step ID.
 *
 * This component uses the `useRedux` hook to get the current step ID and images.
 * It conditionally renders a Map, Slideshow, or Photo component based on the current step ID.
 *
 * @component
 * @returns {JSX.Element} The rendered component based on the current step ID.
 */
const ImageContainer = ({ imageContainerExpanded }) => {
  const navigate = useNavigate();
  const {
    getImages,
    getCurrentStepId,
    getFilters,
    dispatchFilters,
    getHouseTypes,
    getTracking,
    getHouses,
    dispatchSetDialog,
    getCurrentProject,
    getIsAuthenticated,
    PREFERENCES
  } = useRedux();
  const { getPillLabels } = useFilters();
  const { checkIsDisabled } = useRender();
  const currentStepId = getCurrentStepId();
  const plots = getImages().svg.plots;
  const macroplots = getImages().svg.macroplots;
  const [hoveredPlot, setHoveredPlot] = useState(null);
  const [hoveredMacroplot, setHoveredMacroplot] = useState(null);
  const currentImage = getImages().current;
  const video = getImages()?.vimeoID ? getImages()?.vimeoID : null;

  const [zoom, setZoom] = useState(null);
  const [view2D, setView2D] = useState(false);
  const [view3D, setView3D] = useState(true);
  const filters = getFilters();
  const labels = getPillLabels(getHouseTypes()[PREFERENCES.get().language.code], PREFERENCES.get().units, filters);
  const currentQuestionId = getTracking().currentQuestionId;
  const floorplan = getHouses()?.filter(house => house.id === getTracking()?.houseName)[0]?.floorPlan;
  const disabled = getCurrentProject() ? checkIsDisabled() : false;

  const imagesReview = [review1, review2, review3, review4, review5];
  const fallbackImagePath = 'img/macroplotA.png';

  const authenticatedUser = getIsAuthenticated();
  const isDraft = getCurrentProject()?.status === CONFIG_STATUS.Draft;

  const projectOwner = getCurrentProject()?.sales_manager_id;
  const sales_manager_id = Cookies.get('salesManagerId');
  const isProjectOwner = authenticatedUser?.type === 'Client' || projectOwner === sales_manager_id;

  const slideshowIndex = getTracking().slideshowIndex;

  const toRender = () => {
    // render the map if the current step is 'plot'
    if (currentStepId === 'plot' && getCurrentProject())
      return (
        <Map
          plots={plots}
          macroplots={macroplots}
          zoom={zoom}
          hoveredPlot={hoveredPlot}
          hoveredMacroplot={hoveredMacroplot}
          disabled={disabled}
        />
      );
    // render the slideshow with the review photos if the current step is 'review'
    if (currentStepId === 'review' && isArray(imagesReview) && !isEmpty(imagesReview))
      return <Slideshow className='slideshow' images={imagesReview} autoSlide />;
    // render the floorplans if the view is 2D
    if (view2D && currentStepId !== 'plot' && currentStepId !== 'review' && currentQuestionId !== 'houseLayout') {
      if (isArray(floorplan) && !isEmpty(floorplan)) return <Slideshow className='slideshow' images={floorplan} />;
      else return <Photo image={floorplan} fallbackImagePath={fallbackImagePath} />;
    }
    // render the images and videos if the view is 3D
    else if (view3D && currentStepId !== 'plot' && currentStepId !== 'review') {
      // render slideshow with images if the current image is an array and no video
      if (
        currentStepId !== 'plot' &&
        currentStepId !== 'review' &&
        isArray(currentImage) &&
        !isEmpty(currentImage) &&
        !video
      )
        return <Slideshow className='slideshow' images={currentImage} slideshowIndex={slideshowIndex} />;
      // render slideshow with images and video
      if (currentStepId !== 'plot' && currentStepId !== 'review' && !isEmpty(currentImage) && video)
        return <Slideshow className='slideshow' images={currentImage} video={video} />;
      // render video if current image is empty
      if (currentStepId !== 'plot' && currentStepId !== 'review' && isEmpty(currentImage) && video)
        return <VideoPlayer vimeoID={video} />;
      // render photo if current image is not an array and no video
      if (currentStepId !== 'plot' && currentStepId !== 'review' && !isEmpty(currentImage) && !video)
        return <Photo image={currentImage} fallbackImagePath={fallbackImagePath} />;
    }
  };

  useEffect(() => {
    setHoveredPlot(getTracking().plotHover || null);
  }, [getTracking().plotHover]);

  useEffect(() => {
    setHoveredMacroplot(getTracking().macroplotHover || null);
  }, [getTracking().macroplotHover]);

  useEffect(() => {
    setView2D(false);
    setView3D(true);
  }, [currentStepId, currentQuestionId]);

  // zoom in when image container is expanded
  // zoom out when image container is collapsed
  useEffect(() => {
    if (imageContainerExpanded) setZoom('zoomIn');
    if (!imageContainerExpanded) setZoom('zoomOut');
  }, [imageContainerExpanded]);

  // clean zoom const to allow zooming in and out
  useEffect(() => {
    if (zoom) setZoom(null);
  }, [zoom]);

  return (
    <>
      <div className='image-container-header'>
        <div className='image-container-header-left'>
          <button
            className={`logo-button${authenticatedUser?.type === 'SM' ? '-clickable' : ''}`}
            onClick={() => {
              if (authenticatedUser?.type === 'SM' && isDraft && isProjectOwner)
                dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveExit });
              else if (authenticatedUser?.type === 'SM' && (!isDraft || !isProjectOwner))
                dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.Exit });
            }}
          >
            <SVG icon='logo/bespokesmall_white' />
          </button>
          {currentStepId === 'plot' && (
            <div className='filters-pills'>
              {Object.keys(labels).map(key => {
                return isArray(labels[key]) ? (
                  labels[key].map((filter, index) => {
                    return (
                      <Pill
                        label={filter}
                        onClickFn={() => dispatchFilters({ filters: clearFilter(filters, key, filters[key][index]) })}
                      />
                    );
                  })
                ) : (
                  <Pill
                    label={labels[key]}
                    onClickFn={() => dispatchFilters({ filters: clearFilter(filters, key, filters[key]) })}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className='image-container-header-right'>
          <AudioPlayer />
          <div className='image-container-header--map-tools'>
            {currentStepId === 'plot' && <SVG icon='logo/compass' />}
            {currentStepId !== 'review' && view2D && <SVG icon='logo/compass' />}
          </div>
        </div>
      </div>
      {toRender()}
      <div className='image-container-footer'>
        {currentStepId !== 'plot' && currentStepId !== 'review' && currentQuestionId !== 'houseLayout' && (
          <ButtonSwitcher
            view2D={view2D}
            view3D={view3D}
            setView2D={() => {
              setView2D(true);
              setView3D(false);
            }}
            setView3D={() => {
              setView2D(false);
              setView3D(true);
            }}
          />
        )}
        {currentStepId === 'plot' && <ZoomControls zoom={zoom} setZoom={setZoom} />}
      </div>
    </>
  );
};

export default ImageContainer;
