import { modalsAction } from './modals-slice';
import { getModals } from '@services/modals';

export const setModals = async (dispatch, SMToken) => {
  try {
    const response = await getModals(SMToken);
    dispatch(modalsAction.setModals(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
