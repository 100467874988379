import { getUnsupportedDeviceContent } from '@services/singlePages/unsupportedDevice';
import { unsupportedDeviceAction } from './unsupportedDevice-slice';

export const setUnsupportedDeviceContent = async dispatch => {
  try {
    const response = await getUnsupportedDeviceContent();
    dispatch(unsupportedDeviceAction.setUnsupportedDeviceContent(response));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
