import { createSlice } from '@reduxjs/toolkit';

const unsupportedDeviceSlice = createSlice({
  name: 'unsupportedDevice',
  initialState: {},
  reducers: {
    setUnsupportedDeviceContent: (state, action) => {
      return { ...action.payload };
    }
  }
});

const unsupportedDeviceAction = unsupportedDeviceSlice.actions;
export { unsupportedDeviceSlice, unsupportedDeviceAction };
