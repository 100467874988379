// hooks
import useRender from '@hooks/useRender';
import useRedux from '@hooks/useRedux';
// components
import DetailedCell from '@components/ui/Cells/DetailedCell';
import State from '@components/ui/State/State';
// utils
import { PLOT_STATUS } from '@utils/enums';
import { getArea, getLayoutDetails, getMaxTypology } from '@utils/areas';
//translations
import { useTranslation } from 'react-i18next';

const Integration = ({ type, field, item, renderCondition, shouldTriggerRenderCondition, controllerId, disabled }) => {
  const isSelected = useRender().isElementSelected({ controllerId, item });
  const { dispatchInteriorsAreas, dispatchSetRecommendation, PREFERENCES, getHouses, getTracking } = useRedux();
  const { t } = useTranslation();

  const LayoutDetails = ({ title, data }) => {
    const getItem = item => {
      const area = getArea(PREFERENCES.get().units, item.area);
      return (
        <DetailedCell.Row key={item.label}>
          <DetailedCell.Description medium>{item.label}</DetailedCell.Description>
          <DetailedCell.Description regular>{area}</DetailedCell.Description>
        </DetailedCell.Row>
      );
    };

    return (
      <div className='layout-area'>
        <DetailedCell.Row>
          <DetailedCell.Description title>{title}</DetailedCell.Description>
        </DetailedCell.Row>

        <div className='idented-area'>{data.map(item => getItem(item))}</div>
      </div>
    );
  };

  const getById = {
    'show-macroplots': () => {
      return (
        <DetailedCell.Root
          field={field}
          item={item}
          renderCondition={renderCondition}
          isSelected={isSelected}
          isMacroplot
          disabled={disabled}
        >
          <DetailedCell.Title>{item.label}</DetailedCell.Title>
        </DetailedCell.Root>
      );
    },
    'show-plots': () => {
      const { status } = item;
      const area = getArea(PREFERENCES.get().units, item.area);
      const areaOfConstruction = getArea(PREFERENCES.get().units, item.areaOfConstruction);
      const maxTypology = getMaxTypology(item.houses, getHouses());
      if (status !== PLOT_STATUS.Sold && status !== PLOT_STATUS.NotAvailable) {
        return (
          <DetailedCell.Root
            field={field}
            item={item}
            renderCondition={renderCondition}
            shouldTriggerRenderCondition={shouldTriggerRenderCondition}
            isSelected={isSelected}
            customFn={() => dispatchSetRecommendation(item.recommendation)}
            isPlot
            disabled={disabled}
          >
            {status === PLOT_STATUS.InNegotiation ? (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <DetailedCell.Title>{item.label}</DetailedCell.Title>
                {!disabled && <State type='warning' text='IN NEGOTIATION' />}
              </div>
            ) : item.isPremium ? (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <DetailedCell.Title>{item.label}</DetailedCell.Title>
                {!disabled && <State type='warning' text='PREMIUM' icon='semantic/crown' />}
              </div>
            ) : (
              <DetailedCell.Title>{item.label}</DetailedCell.Title>
            )}
            <DetailedCell.Row>
              <DetailedCell.Description medium>{t('journey.total_plot_area')}</DetailedCell.Description>
              <DetailedCell.Description regular>{area}</DetailedCell.Description>
            </DetailedCell.Row>
            <DetailedCell.Row>
              <DetailedCell.Description medium>{t('journey.gross_construction_area')}</DetailedCell.Description>
              <DetailedCell.Description regular>
                {maxTypology ? `${maxTypology} max` : ''} {areaOfConstruction}
              </DetailedCell.Description>
            </DetailedCell.Row>
          </DetailedCell.Root>
        );
      }
    },
    'show-houseLayout': () => {
      const details = getLayoutDetails(item?.modules, PREFERENCES.get().units);
      const houseArea = getHouses().find(house => house.id === item.id)?.area;
      const units = PREFERENCES.get().units;
      const expandedInformation = (
        <>
          <LayoutDetails title={t('journey.layout_social_areas')} data={details.social} />
          <LayoutDetails title={t('journey.layout_intimate_areas')} data={details.intimate} />
        </>
      );

      const onLayoutClick = () => {
        dispatchInteriorsAreas(item.id);
      };

      return (
        <DetailedCell.Root
          field={field}
          item={item}
          isSelected={isSelected}
          customFn={() => onLayoutClick()}
          disabled={disabled}
        >
          <div className='layout-details'>
            <div className='layout'>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <DetailedCell.Title>Layout {item.index}</DetailedCell.Title>
                {!disabled && getTracking()?.recommendation?.id === item?.id && (
                  <State type='info' text='recommended' />
                )}
              </div>

              <DetailedCell.Row>
                <DetailedCell.Description regular>{getArea(units, houseArea)}</DetailedCell.Description>
              </DetailedCell.Row>
            </div>
            {isSelected && expandedInformation}
          </div>
        </DetailedCell.Root>
      );
    }
  };

  return getById[type]();
};

export default Integration;
