import axios from 'axios';
import { trackingActions } from './tracking-slice';
import { BACKEND_URL } from 'config';
import Cookies from 'js-cookie';
import { getHeadersToRequest } from '@utils/auth/functions';

export const updateTracking = (dispatch, idToUpdate, updatedObject) => {
  switch (idToUpdate) {
    case 'nrRooms':
      dispatch(trackingActions.updateNrRooms({ idToUpdate, updatedObject }));
      break;
    case 'houseType':
      dispatch(trackingActions.updateHouseType({ idToUpdate, updatedObject }));
      break;
    case 'houseLayout':
      dispatch(trackingActions.updateHouseLayout({ idToUpdate, updatedObject }));
      break;
    case 'houseName':
      dispatch(trackingActions.updateHouseName({ idToUpdate, updatedObject }));
      break;
    case 'plotSelectedOnImageContainer':
      dispatch(trackingActions.updateWasSelectedOnImageContainer({ updatedObject }));
      break;
    default:
      dispatch(trackingActions.updateTracking({ idToUpdate, updatedObject }));
      break;
  }
};

export const setRecommendation = (dispatch, recommendation) => {
  dispatch(trackingActions.setRecommendation({ recommendation }));
};

export const setPlotHover = (dispatch, plotId) => {
  dispatch(trackingActions.setPlotHover({ plotId }));
};

export const setMacroplotHover = (dispatch, macroplotId) => {
  dispatch(trackingActions.setMacroplotHover({ macroplotId }));
};

export const setResetForm = ({ dispatch, reset, trigger, after }) => {
  dispatch(trackingActions.setResetForm({ reset, trigger, after }));
};

export const updateCurrentStepId = (dispatch, nextStepId) => {
  dispatch(trackingActions.updateCurrentStepId({ nextStepId }));
};

export const updateCurrentQuestionId = (dispatch, currentQuestionId) => {
  dispatch(trackingActions.updateCurrentQuestionId({ currentQuestionId }));
};

export const setInteriorsAreas = async (dispatch, houseTypeId) => {
  const HEADERS = await getHeadersToRequest({ SMToken: Cookies.get('sessionId') });

  const requestObj = {
    method: 'get',
    url: `${BACKEND_URL}/houses?id=${houseTypeId}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));
  dispatch(trackingActions.updateInteriorsAreas({ interiorsAreas: response.data }));
};

export const setDialog = (dispatch, open, type, id, isPremium) => {
  dispatch(trackingActions.setDialog({ open, type, id, isPremium }));
};

export const setRemoteInformationPage = (dispatch, status, pageInformation) => {
  dispatch(trackingActions.setRemoteInformationPage({ status, pageInformation }));
};

export const setIntimateIndex = (dispatch, index) => {
  dispatch(trackingActions.setIntimateIndex({ index }));
};

export const setSlideshowIndex = (dispatch, index) => {
  dispatch(trackingActions.setSlideshowIndex({ index }));
};

export const setCurrentQuestionIdAndSlideshowIndex = (dispatch, currentQuestionId, index) => {
  dispatch(trackingActions.setCurrentQuestionIdAndSlideshowIndex({ currentQuestionId, index }));
};
export const resetTracking = dispatch => {
  dispatch(trackingActions.reset());
};
