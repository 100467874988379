/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector, useDispatch } from 'react-redux';
import {
  updateTracking,
  updateCurrentStepId,
  updateCurrentQuestionId,
  resetTracking,
  setInteriorsAreas,
  setDialog,
  setRecommendation,
  setPlotHover,
  setMacroplotHover,
  setResetForm,
  setRemoteInformationPage,
  setIntimateIndex,
  setSlideshowIndex,
  setCurrentQuestionIdAndSlideshowIndex
} from '../store/tracking/tracking-actions';
import { setForm } from '../store/forms/forms-actions';
import { setMacroplots } from '../store/macroplots/macroplots-actions';
import { setPlots } from '../store/plots/plots-actions';
import { setHouses } from '../store/houses/houses-actions';
import { setImage, setCurrentImage, setSVG } from 'store/image/image-actions';
import { setModules } from 'store/modules/modules-actions';
import { setHouseTypes } from 'store/houseTypes/houseTypes-actions';
import { setPacks } from 'store/packs/packs-actions';
import { setFilters, resetFilters } from 'store/filters/filters-actions';
import { setHomepageContent } from 'store/homepage/homepage-actions';
import { setIsAuthenticated } from 'store/auth/auth-actions';
import { setProjectSubmittedContent } from 'store/projectSubmitted/projectSubmitted-actions';
import { setSubmissionRemoteContent } from 'store/submissionRemote/submissionRemote-actions';
import { setRegisterRemoteContent } from 'store/registerRemote/registerRemote-actions';
import { setCurrentProject } from 'store/project/project-actions';
import {
  updateFormState,
  updateAllFormState,
  updateStepFinished,
  updateStepsFinished,
  updateStepChanged,
  resetFormState
} from 'store/formState/formState-actions';
import { setModals } from 'store/modals/modals-actions';
import { setPartners } from 'store/partners/partners-actions';
import { resetNotification, setNotification } from 'store/notification/notification-actions';
import { setClient } from 'store/client/client-actions';
import { setPrice, resetPrice, setFilterValues } from 'store/pricing/pricing-actions';
import {
  updateLanguage,
  updateCurrency,
  updateUnits,
  resetPreferences,
  setPreferencesContent
} from 'store/preferences/preferences-actions';
import { setDashboardContent } from 'store/dashboard/dashboard-actions';
import { setEnterpriseContent } from 'store/enterprise/enterprise-actions';
import { setUnsupportedDeviceContent } from 'store/unsupportedDevice/unsupportedDevice-actions';
import { setServicesContent } from 'store/servicesFromBo/services-actions';

// Custom hook for counter
const useRedux = () => {
  const dispatch = useDispatch();

  // Selector to get the current counter value from the state
  const tracking = useSelector(state => state.tracking);
  const forms = useSelector(state => state.forms);
  const macroplots = useSelector(state => state.macroplots);
  const plots = useSelector(state => state.plots);
  const houses = useSelector(state => state.houses);
  const images = useSelector(state => state.images);
  const modules = useSelector(state => state.modules);
  const houseTypes = useSelector(state => state.houseTypes);
  const homepage = useSelector(state => state.homepage);
  const projectSubmitted = useSelector(state => state.projectSubmitted);
  const submissionRemote = useSelector(state => state.submissionRemote);
  const registerRemote = useSelector(state => state.registerRemote);
  const packs = useSelector(state => state.packs);
  const filters = useSelector(state => state.filters);
  const modals = useSelector(state => state.modals);
  const notification = useSelector(state => state.notification);
  const formState = useSelector(state => state.formState);
  const authState = useSelector(state => state.auth);
  const partners = useSelector(state => state.partners);
  const currentProject = useSelector(state => state.currentProject);
  const client = useSelector(state => state.client);
  const pricing = useSelector(state => state.pricing);
  const preferences = useSelector(state => state.preferences);
  const dashboard = useSelector(state => state.dashboard);
  const enterprise = useSelector(state => state.enterprise);
  const unsupportedDevice = useSelector(state => state.unsupportedDevice);
  const servicesFromBO = useSelector(state => state.servicesFromBO);

  const AUTH = {
    dispatchIsAuthenticated: state => {
      setIsAuthenticated(dispatch, state);
    },
    getIsAuthenticated: () => {
      return authState;
    }
  };

  const CURRENTPROJECT = {
    dispatchProjectInfo: projectInfo => {
      setCurrentProject(dispatch, projectInfo);
    },
    getCurrentProject: () => {
      return currentProject;
    }
  };

  const FORMS = {
    dispatchForms: ({ additionalData, renderWithoutJourney }) => {
      setForm({ dispatch, additionalData, renderWithoutJourney });
    },
    getForms: () => {
      return forms;
    }
  };

  const MACROPLOTS = {
    dispatchMacroplots: SMToken => {
      setMacroplots(dispatch, SMToken);
    },
    getMacroplots: () => {
      return macroplots;
    }
  };

  const PLOTS = {
    dispatchPlots: token => {
      setPlots(dispatch, token);
    },
    getPlots: () => {
      return plots;
    }
  };

  const HOUSES = {
    dispatchHouses: SMToken => {
      setHouses(dispatch, SMToken);
    },
    getHouses: () => {
      return houses;
    }
  };

  const HOMEPAGE = {
    set: () => {
      setHomepageContent(dispatch);
    },
    get: () => {
      return homepage;
    }
  };

  const DASHBOARD = {
    set: SMToken => {
      setDashboardContent(dispatch, SMToken);
    },
    get: () => {
      return dashboard;
    }
  };

  const PROJECTSUBMITTEDCONTENT = {
    dispatchProjectSubmittedContent: SMToken => {
      setProjectSubmittedContent(dispatch, SMToken);
    },
    getProjectSubmittedContent: () => {
      return projectSubmitted;
    }
  };

  const SUBMISSIONREMOTECONTENT = {
    dispatchSubmissionRemoteContent: SMToken => {
      setSubmissionRemoteContent(dispatch, SMToken);
    },
    getSubmissionRemoteContent: () => {
      return submissionRemote;
    }
  };

  const REGISTERREMOTECONTENT = {
    dispatchRegisterRemoteContent: SMToken => {
      setRegisterRemoteContent(dispatch, SMToken);
    },
    getRegisterRemoteContent: () => {
      return registerRemote;
    }
  };

  const UNSUPPORTEDDEVICE = {
    set: () => {
      setUnsupportedDeviceContent(dispatch);
    },
    get: () => {
      return unsupportedDevice;
    }
  };

  const SERVICESFROMBO = {
    dispatchServicesContent: async SMToken => {
      setServicesContent(dispatch, SMToken);
    },
    getServicesContent: () => {
      return servicesFromBO;
    }
  };

  const IMAGES = {
    dispatchImage: ({ hash, imageUrl, vimeoID }) => {
      setImage({ dispatch, hash, imageUrl, vimeoID });
    },
    dispatchCurrentImage: imageUrl => {
      setCurrentImage({ dispatch, imageUrl });
    },
    dispatchSVG() {
      setSVG({ dispatch });
    },

    getImages: () => {
      return images;
    }
  };

  const TRACKING = {
    dispatchUpdateCurrentStepId: nextStepId => {
      updateCurrentStepId(dispatch, nextStepId);
    },

    dispatchUpdateCurrentQuestionId: currentQuestion => {
      updateCurrentQuestionId(dispatch, currentQuestion);
    },

    dispatchInteriorsAreas: houseTypeId => {
      if (houseTypeId) setInteriorsAreas(dispatch, houseTypeId);
    },

    getCurrentStepId: () => {
      return tracking.currentStepId;
    },

    getCurrentQuestionId: () => {
      return tracking.currentQuestionId;
    },

    getTracking: () => {
      return tracking;
    },

    dispatchUpdateTracking: ({ idToUpdate, updatedObject }) => {
      updateTracking(dispatch, idToUpdate, updatedObject);
    },

    dispatchSetDialog: ({ open, type, id, isPremium }) => {
      setDialog(dispatch, open, type, id, isPremium);
    },

    dispatchSetRecommendation: recommendation => {
      setRecommendation(dispatch, recommendation);
    },

    dispatchSetPlotHover: plotId => {
      setPlotHover(dispatch, plotId);
    },

    dispatchSetMacroplotHover: macroplotId => {
      setMacroplotHover(dispatch, macroplotId);
    },

    dispatchResetForm: ({ reset, trigger, after }) => {
      setResetForm({ dispatch, reset, trigger, after });
    },
    dispatchRemoteInformationPage: ({ status, pageInformation }) => {
      setRemoteInformationPage(dispatch, status, pageInformation);
    },
    dispatchSetIntimateIndex: index => {
      setIntimateIndex(dispatch, index);
    },
    dispatchSetSlideshowIndex: index => {
      setSlideshowIndex(dispatch, index);
    },
    dispatchSetCurrentQuestionIdAndSlideshowIndex: (stepId, slideshowIndex) => {
      setCurrentQuestionIdAndSlideshowIndex(dispatch, stepId, slideshowIndex);
    },
    dispatchResetTracking: () => {
      resetTracking(dispatch);
    }
  };

  const MODULES = {
    dispatchModules: SMToken => {
      setModules(dispatch, SMToken);
    },
    getModules: () => {
      return modules;
    }
  };

  const HOUSETYPES = {
    dispatchHouseTypes: SMToken => {
      setHouseTypes(dispatch, SMToken);
    },
    getHouseTypes: () => {
      return houseTypes;
    }
  };

  const PACKS = {
    dispatchPacks: SMToken => {
      setPacks(dispatch, SMToken);
    },
    getPacks: () => {
      return packs;
    }
  };

  const FILTERS = {
    dispatchFilters: ({ filters }) => {
      setFilters(dispatch, filters);
    },
    dispatchResetFilters: () => {
      resetFilters(dispatch);
    },
    getFilters: () => {
      return filters;
    }
  };

  const FORMSTATE = {
    update: ({ stepId, obj }) => {
      updateFormState({ dispatch, stepId, obj });
    },
    updateAll: form => {
      updateAllFormState({ dispatch, form });
    },
    get: id => {
      if (!id) return formState;
      return formState[id];
    },
    dispatchUpdateStepFinished: stepFinished => {
      updateStepFinished(dispatch, stepFinished);
    },
    dispatchUpdateStepsFinished: stepsFinished => {
      updateStepsFinished(dispatch, stepsFinished);
    },
    dispatchUpdateStepChanged: stepChanged => {
      updateStepChanged(dispatch, stepChanged);
    },
    reset: () => {
      resetFormState(dispatch);
    }
  };

  const MODALS = {
    dispatchModals: SMToken => {
      setModals(dispatch, SMToken);
    },
    getModals: () => {
      return modals;
    }
  };

  const NOTIFICATION = {
    dispatchNotification: notification => {
      setNotification({ dispatch, notification });
    },
    dispatchResetNotification: () => {
      resetNotification(dispatch);
    },
    getNotification: () => {
      return notification;
    }
  };

  const PARTNERS = {
    dispatchPartners: SMToken => {
      setPartners(dispatch, SMToken);
    },
    getPartners: () => {
      return partners;
    }
  };

  const CLIENT = {
    dispatchClient: client => {
      setClient(dispatch, client);
    },
    getClient: () => {
      return client;
    }
  };

  const PRICING = {
    update: ({ formState, currency }) => {
      setPrice(dispatch, formState, currency);
    },
    reset: () => {
      resetPrice(dispatch);
    },
    get: () => {
      return pricing;
    },
    setFilterValues: SMToken => {
      setFilterValues(dispatch, SMToken);
    }
  };

  const PREFERENCES = {
    set: () => {
      setPreferencesContent(dispatch);
    },
    updateLanguage: language => {
      updateLanguage({ dispatch, language });
    },
    updateCurrency: currency => {
      updateCurrency(dispatch, currency);
    },
    updateUnits: units => {
      updateUnits(dispatch, units);
    },
    reset: () => {
      resetPreferences(dispatch);
    },
    get: () => {
      return preferences;
    }
  };

  const ENTERPRISE = {
    dispatchEnterpriseContent: async () => {
      setEnterpriseContent(dispatch);
    },
    getEnterpriseContent: () => {
      return enterprise;
    }
  };

  return {
    ...FORMS,
    ...MACROPLOTS,
    ...PLOTS,
    ...HOUSES,
    ...IMAGES,
    ...TRACKING,
    ...MODULES,
    ...HOUSETYPES,
    ...PACKS,
    ...FILTERS,
    ...MODALS,
    ...NOTIFICATION,
    ...FORMSTATE,
    ...PARTNERS,
    HOMEPAGE,
    ...AUTH,
    ...PROJECTSUBMITTEDCONTENT,
    ...CURRENTPROJECT,
    ...CLIENT,
    DASHBOARD,
    FORMSTATE,
    PRICING,
    PREFERENCES,
    UNSUPPORTEDDEVICE,
    ...SERVICESFROMBO,
    ...SUBMISSIONREMOTECONTENT,
    ...REGISTERREMOTECONTENT,
    ...ENTERPRISE
  };
};

export default useRedux;
